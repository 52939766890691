import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import chest_icon from "../../../images/chest.svg";
import LevelProgress from "./LevelProgress";
import info_icon from "../../../images/info_new.svg";
import RewardsList from "./RewardsList";
import RewardsTooltip from "./RewardsTooltip";
import levelupIcon from "../../../images/levelUp.svg";
function QuizResult({ quizScore, quizTotalMarks, isQuizSubmitted, mastery, oldXp }) {
  const navigate = useNavigate();
  const location = useLocation();
  const totalXp = quizTotalMarks === quizScore ? quizScore * 10 + mastery * 200 + 50 + 100 : quizScore * 10 + mastery * 200 + 50;
  const [showTooltip, setShowTooltip] = useState(false);
  const [level, setLevel] = useState(1); // Current level, starting at 1
  const [levelUp, setLevelUp] = useState(false); // State to track if a level-up occurred

  // Calculate level and check for level-up
  useEffect(() => {
    const combinedXp = oldXp + totalXp;
    const newLevel = Math.min(100, Math.floor(combinedXp / 3000) + 1); // Calculate level, capped at 100

    // Check if the level has increased
    if (newLevel > level) {
      setLevelUp(true);
      setLevel(newLevel);
    } else {
      setLevelUp(false);
    }
  }, [oldXp, totalXp, level]);
  const navigateBackToClass = () => {
    navigate(-1);
  };

  return (
    <div className="text-center sm:mt-8 md:mt-16 md:px-4 sm:px-0">
      {/* Quiz Completion Chest Icon and Title */}
      <div className="flex flex-col items-center mb-6">
        <div className="sm:w-24 sm:h-24 md:w-32 md:h-32 flex items-center justify-center mb-4">
          <img src={levelUp ? levelupIcon : chest_icon} alt="Chest icon" className="w-full h-full" />
        </div>
        <p className="sm:text-[16px] md:text-3xl font-semibold">{levelUp ? "Level Up!" : "Quiz Complete!"}</p>
      </div>

      {/* XP Progress Message */}
      <div className="mb-4">
        <p className="sm:text-[16px] md:text-xl text-gray-700" style={{ fontFamily: "Roboto" }}>
          Every quiz takes you closer to mastery. Keep going!
        </p>
      </div>
      {/* {JSON.stringify(location.state.streakInfo.three_days_bonus_xp_status)} */}
      {/* XP Level Progress */}
      <div className="mb-6">
        <LevelProgress oldXp={oldXp} TotalScore={totalXp} />
      </div>

      {/* Continue Button */}
      {!isQuizSubmitted?.current && (
        <button onClick={navigateBackToClass} className="enabled-submit-button mt-4">
          Continue
        </button>
      )}

      {/* Earned XP Info with Tooltip */}
      <div className="flex items-center relative sm:w-full md:w-3/4 md:mx-auto sm:mx-0 sm:px-2 md:px-4 sm:mb-2 md:mb-2">
        <p className="text-lg md:text-xl font-medium text-yellow-700 flex items-center">
          Earned
          <div className="relative ml-1" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
            <img src={info_icon} alt="Info icon" className="cursor-pointer sm:w-4 sm:h-4 md:w-5 md:h-5" />
            {showTooltip && (
              <div className="absolute top-full mt-2 left-1/2 transform -translate-x-1/2 z-10">
                <RewardsTooltip quizScore={quizScore} recallStatusCount={mastery} quizTotalMarks={quizTotalMarks} />
              </div>
            )}
          </div>
        </p>
        <p className="text-display-h4 ml-auto">{totalXp} XP</p>
      </div>

      {/* Rewards List */}
      <div>
        <RewardsList quizScore={quizScore} recallStatusCount={mastery} quizTotalMarks={quizTotalMarks} />
      </div>
    </div>
  );
}

export default QuizResult;
