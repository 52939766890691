import React from "react";

const SubscriptionDetailTable = ({ columns, data }) => {
  return (
    <div>
      <table className="w-full text-sm text-left text-white dark:text-white">
        <thead className="text-xs text-black dark:bg-white dark:text-white">
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className="px-6 py-3 bg-[#F7F2F9] tracking_table spth"
                style={{ width: column.width }}
              >
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            >
              {columns.map((column, colIndex) => (
                <td
                  key={colIndex}
                  className={`px-6 py-4 tracking_table_td ${
                    colIndex < 2 ? "bg-[#F7F2F9]" : ""
                  }`}
                  style={{ border: "1px solid rgba(73, 69, 79, 0.4)" }}
                >
                  {column.key === "id"
                    ? rowIndex + 1
                    : column.render
                    ? column.render(row)
                    : row[column.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubscriptionDetailTable;
