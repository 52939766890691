import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import misconception_icon from "../../../images/misconception.svg";

// Styled Tooltip for MUI
const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} placement="top" />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    border: "1px solid #dadde9",
    borderRadius: "8px",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(12),
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    maxWidth: 250,
    opacity: "0.8",
    padding: "12px 16px",
  },
}));

const CommonMisconceptionsTable = (props) => {
  const misconceptions = props.data?.class_analytics_data?.misconception_questions;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        maxHeight: 350,
        overflowY: "auto",
        scrollbarWidth: "none", // For Firefox
        msOverflowStyle: "none", // For IE and Edge
      }}
      className="hide-scrollbar" // Using a class for WebKit-based browsers
    >
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ backgroundColor: "#403151", color: "white" }}>
              ID
            </TableCell>
            <TableCell style={{ backgroundColor: "#403151", color: "white" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <HtmlTooltip
                  title="The questions that a significant portion of class answers incorrectly."
                  PopperProps={{
                    disablePortal: true,
                  }}
                  enterTouchDelay={0}
                  leaveTouchDelay={10}
                  open={open}
                  onOpen={handleOpen}
                  onClose={handleClose}
                >
                  <button
                    className="ml-[16px] flex"
                    onClick={handleOpen}
                    style={{ color: "white", background: "none", border: "none", cursor: "pointer" }}
                  >
                    Common Misconceptions <img className="ml-2 h-[24px] w-[24px]" src={misconception_icon} alt="Misconceptions Icon" />
                  </button>
                </HtmlTooltip>
              </ClickAwayListener>
            </TableCell>
            <TableCell align="center" style={{ backgroundColor: "#403151", color: "white" }}>
              Question Inaccuracy
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="hide-scrollbar">
          {misconceptions && misconceptions.length > 0 ? (
            misconceptions.map((misconception, index) => (
              <MisconceptionRow
                key={index}
                id={index + 1}
                misconception={misconception.quiz_question}
                percentage={misconception.incorrect_percentage}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan="3" align="center" style={{ color: "gray" }}>
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MisconceptionRow = ({ id, misconception, percentage }) => (
  <TableRow>
    <TableCell align="center" style={{ backgroundColor: "#E8E8E8" }}>
      {id}
    </TableCell>
    <TableCell>
      <b>
        <span dangerouslySetInnerHTML={{ __html: misconception }} />
      </b>
    </TableCell>
    <TableCell align="center" style={{ backgroundColor: "#DBD2DD" }}>
      {percentage !== undefined ? `${parseInt(percentage)}%` : "0%"}
    </TableCell>
  </TableRow>
);

export default CommonMisconceptionsTable;
