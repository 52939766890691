import React, { useRef, useState, useEffect } from "react";
import Sidebar from "./Sidebar";
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
// import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import amplitude from "amplitude-js";
function ContactUs() {
  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
    amplitude
      .getInstance()
      .logEvent("ShareFeedback", "user has visited the share feedback page");
  }, []);

  const isSubmitting = useRef(false);
  function submitForm() {
    var token = "Bearer " + localStorage.getItem("access_token");
    isSubmitting.current = true;
    var user_feedback = document.getElementById("user_feedback").value;

    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/submit_feedback", {
        method: "POST",
        user_feedback: user_feedback,
      })

      .then((response) => {
        console.log(response);
        isSubmitting.current = false;
        //alert("Feedback submitted successfully");
        amplitude
          .getInstance()
          .setUserId(localStorage.getItem("email_address"));
        amplitude
          .getInstance()
          .logEvent("SubmitFeedback", "user has has submitted is feedback");
        setShow(true);
        document.getElementById("user_feedback").value = "";
        var submitButton = document.getElementById("submit_button");
        submitButton.classList.add("disable-button");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleCloseWithCancel = () => {
    setOpen(false);
    isSubmitting.current = false;
  };

  const handleCloseWithYes = () => {
    setOpen(false);
    submitForm();
  };

  const handleDelateOptionClick = (event) => {
    var user_feedback = document.getElementById("user_feedback").value;

    if (user_feedback.length <= 0) {
      //alert("Cannot submit empty feedback.");
      return;
    }
    setOpen(true);
  };

  const onUserFeedbackChange = (e) => {
    var submitButton = document.getElementById("submit_button");

    if (e.target.value.length === 0) {
      submitButton.classList.add("disable-button");
      return;
    }
    submitButton.classList.remove("disable-button");
  };

  return (
    <>
      <Sidebar item_id="report_problem" />
      <ToastContainer className="p-3 position-fixed top-12 right-0">
        <Toast
          style={{ backgroundColor: "#f7f2f9" }}
          onClose={() => setShow(false)}
          show={show}
          delay={5000}
          autohide
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">We're on it!</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>
            You’ll receive an email reply within a few hours.
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Dialog
        open={open}
        onClose={handleCloseWithCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Confirm</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to submit your feedback?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>NO</Button>
          <Button
            className="delete-button"
            onClick={handleCloseWithYes}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px]">
        <div className="home sm:ml[25px] md:ml-[60px]">
          <div>
            <label className="dashboard-label">Share Feedback</label>
            <div className="teepee-divider"></div>
          </div>
        </div>
        <div className="flex flex-row md:justify-left md:items-center sm:ml-0 md:ml-[60px] mt-[20px]">
          <div className=""></div>
          <div className="selectionDiv sm:w-[100%] md:w-[600px] ">
            <div className="form-div sm:w-[100%] md:w-[600px]">
              <div className="sm:w-[100%] md:w-[600px] ">
                <div>
                  <label className="create_quiz_label">
                    Share your experience with us
                  </label>
                </div>
                <div>
                  <textarea
                    type="text"
                    name="user_feedback"
                    maxLength={500}
                    minLength={100}
                    required={true}
                    id="user_feedback"
                    className="teepee-textarea sm:w-[100%] md:w-full"
                    placeholder=""
                    onChange={onUserFeedbackChange}
                  />
                </div>

                <div>
                  <button
                    onClick={handleDelateOptionClick}
                    id="submit_button"
                    disabled={isSubmitting.current}
                    className="teepee-button hover:bg-black disable-button"
                    style={{ width: "46%" }}
                  >
                    {isSubmitting.current && (
                      <div
                        className="spinner-border spinner-border-m float-left"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
