import React from "react";

function LiveUsersTable({ data, userType }) {
  return (
    <div>
      <table
        class="w-full text-sm text-left text-white dark:text-white"
        className="tracking_table w-[100%] "
        id="tbl"
      >
        <thead class="text-xs text-black dark:bg-white dark:text-white ">
          <tr className="tracking_table">
            <th scope="col" class="px-6 py-3 tracking_table spth bg-[#F7F2F9]">
              ID
            </th>
            <th
              scope="col"
              class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%]"
            >
              Email
            </th>
            <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
              User Type
            </th>
            <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {data !== undefined &&
            data.map((stats, index) => (
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white tracking_table_td"
                  style={{ backgroundColor: "rgba(103, 80, 164, 0.08)" }}
                >
                  {index + 1}
                </th>
                <td
                  class="px-6 py-4  tracking_table tracking_table_td"
                  style={{
                    backgroundColor: "rgba(103, 80, 164, 0.08)",
                    border: "1px solid rgba(73, 69, 79, 0.4)",
                  }}
                >
                  {userType === "Student"
                    ? stats.student_email
                    : stats.email_address}
                </td>
                <td
                  class="px-6 py-4  tracking_table_td"
                  style={{ border: "1px solid rgba(73, 69, 79, 0.4)" }}
                >
                  {userType}
                </td>
                <td class="px-6 py-4  tracking_table_td">Active</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default LiveUsersTable;
