import React from "react";
import Sidebar from "../../Sidebar";
import { useLocation, useNavigate } from "react-router-dom";

const QuizHeader = ({ quizCompleted }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const student_name = localStorage.getItem("student_name");
  return (
    <div>
      <Sidebar />
      {!quizCompleted && (
        <div class="flex justify-start items-center p-4 border-b-[8px] border-[#6750A4]">
          <div className="home sm:ml[25px] md:ml-[60px] underline  teacher_navigation_text">
            <button className="" onClick={() => navigate("/student-dashboard")}>
              {/* {props.text} */}
              {"Home"}
            </button>
            {/* <button onClick={() => navigate(-1)}>{" " + " > Create Quiz"}</button> */}
            <button onClick={() => navigate(-1)}>{" " + ` > ${location?.state?.class_data?.classroom_name}`}</button>
          </div>
          <div class="flex space-x-16 ml-[10%]">
            <div>
              <p class="text-display-b1 font-roboto">Quiz name</p>
              <p class="text-display-h4 font-roboto">{location.state?.quiz?.student_quiz_title}</p>
            </div>
            <div>
              <p class="text-display-b1 font-roboto">Student Name</p>
              <p class="text-display-h4 font-roboto">{student_name}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuizHeader;
