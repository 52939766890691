import axios from "axios";
import {
  REACT_APP_REST_API_BASE_URL,
  REACT_APP_VALIDATE_PROMOCODE_END_POINT,
} from "../../Constants/ApiConstant";
const useValidatePromoCode = () => {
  const handleAmount = (
    data,
    setDiscountedAmount,
    setSaveAmount,
    promocodeValidationStatus,
    discountedPriceForStripeIntant
  ) => {
    var totalAmount = (data.vouchersRate * 20) / 100 + data.vouchersRate;
    var offAmount = 0;
    if (promocodeValidationStatus.redeemed_status === 0) {
      offAmount =
        (totalAmount * promocodeValidationStatus.discount_percentage) / 100;
      setDiscountedAmount((totalAmount - offAmount).toFixed(2));
      setSaveAmount(offAmount);
      discountedPriceForStripeIntant.current = (
        totalAmount - offAmount
      ).toFixed(2);
    } else {
      setDiscountedAmount(totalAmount);
      discountedPriceForStripeIntant.current = totalAmount;
    }
  };

  const validatePormoCode = (
    promocode,
    data,
    callback,
    setDiscountedAmount,
    setSaveAmount,
    discountedPriceForStripeIntant,
    updatePaymentIntent
  ) => {
    var access_token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = access_token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(
        REACT_APP_REST_API_BASE_URL + REACT_APP_VALIDATE_PROMOCODE_END_POINT,
        {
          promocode: promocode,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: access_token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        callback(response.data);
        handleAmount(
          data,
          setDiscountedAmount,
          setSaveAmount,
          response.data,
          discountedPriceForStripeIntant
        );
        updatePaymentIntent();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return validatePormoCode;
};

export default useValidatePromoCode;
