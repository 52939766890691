import React from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import PredefinedQuestionsPrompt from "./PredefinedQuestionsPrompt";

export default function PredefinedQuestionsPromptContainer(props) {
  const addNewPredefinedQuestionsPrompt = () => {
    // alert(subject.subject_name);
    var url = props.name ? "/create_updated_predefined_questions_prompt" : "/create_new_predefined_questions_prompt";
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + url, {
        method: "POST",
        subject_id: props.subject_id,
        quiz_type: props.quizType,
      })

      .then((response) => {
        props.addTodo(props.subject_id);
      })
      .catch((error) => {
        console.log(error);
        alert("unable to add new topic");
      });
  };

  return (
    <div>
      {props.predefined_questions_prompt_list.length > 0 &&
        props.predefined_questions_prompt_list?.map((predefined_questions_prompt, subIopicIndex) => (
          <PredefinedQuestionsPrompt
            subject_id={props.subject_id}
            addTodo={props.addTodo}
            predefined_questions_prompt={predefined_questions_prompt}
          />
        ))}
      <Button className="delete-button" style={{ color: "#1976d2", padding: "6px 8px" }} onClick={() => addNewPredefinedQuestionsPrompt()}>
        Add New Predefined Question Prompt
      </Button>
    </div>
  );
}
