import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import amplitude from "amplitude-js";
import "./App.css";
import Dashboard from "./Componenets/Pages/Dashboard";
import Users from "./Componenets/Users";
import ContactUs from "./Componenets/ContactUs";
import ViewSubjectDetails from "./Componenets/ViewSubjectDetails";
import ViewSubject from "./Componenets/ViewSubject";
import UsersFeedback from "./Componenets/UsersFeedback";
import UsersQuestionEvaluationFeedback from "./Componenets/UsersQuestionEvaluationFeedback";
import Login from "./Componenets/Login";
import Register from "./Componenets/Register";
import { BrowserRouter } from "react-router-dom";
import CreateQuiz from "./Componenets/CreateQuiz";
import AddDetails from "./Componenets/AddDetails";
import Share from "./Componenets/Share";
import Quiz from "./Componenets/Quiz";
import ViewResult from "./Componenets/ViewResult";
import QuizDetailReview from "./Componenets/QuizDetailReview";
import SubjectContentContainer from "./Componenets/SubjectsContentContainer";
import SubjectPrompt from "./Componenets/SubjectPrompt";
import GoogleLogin from "./Componenets/GoogleLogin";
import StudentEmail from "./Componenets/QuizSettings/studentEmail";
import axios from "axios";
import JustifiedExample from "./Componenets/JustifiedExample";
import AboutUs from "./Componenets/AboutUs";
import { WindowSharp } from "@mui/icons-material";
import EditPredefinedQuestions from "./Componenets/EditPredefinedQuestions";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import MSLogin from "./Componenets/MSLogin";
import { Fragment } from "react";
import { Alert } from "bootstrap";
import TeacherClassroomHome from "./Componenets/Teacher/TeacherClassroomHome";
import CreateClassroomQuiz from "./Componenets/Teacher/CreateClassroomQuiz";
import StudentDashbaord from "./Componenets/Student/StudentDashbaord";
import StudentViewClassroom from "./Componenets/Student/StudentViewClassroom";
import ProtecredWebUserRoute from "./ProtectedWebUserRoute";
import ProtectedStudentRoute from "./ProtectedStudentRoute";
import ProtectedAdminRoutes from "./ProtectedAdminRoutes";
import ProtectedTeacherRoutes from "./ProtectedTeacherRoutes";
import TrackingAnalytics from "./Componenets/TrackingAnalytics";
import OnBoardingSubject from "./Componenets/OnBoarding/OnBoardingSubject";
import OnBoarding from "./Componenets/OnBoarding/OnBoarding";
import InterestAnalytics from "./Componenets/OnBoarding/InterestAnalytics";
import OnboardingVideos from "./Componenets/OnBoarding/OnboardingVideos";
import Tutorials from "./Componenets/OnBoarding/Tutorials";
import KeyMatrics from "./Componenets/KeyMatrics";
import SubjectAnalysis from "./Componenets/SubjectAnalysis";
import LiveUsersTable from "./Componenets/widgets/LiveUsersTable";
import { SelectSubject } from "./Componenets/QuizSettings/SelectSubject";
import EmailUs from "./Componenets/EmailUs";
import EmailUsFeedback from "./Componenets/EmailUsFeedback";
import EvaluationExperince from "./Componenets/OnBoarding/EvaluationExperince";
import Subscribe from "./Componenets/Subscription/Subscribe";
import Checkout from "./Componenets/Subscription/Checkout";
import ManageSubject from "./Componenets/Subscription/ManageSubject";
import SubscriptionTracking from "./Componenets/Subscription/Admin/SubscriptionTracking";
import TrialAndSubscriptionConfigure from "./Componenets/Subscription/Admin/TrialAndSubscriptionConfigure";
import Pricing from "./Componenets/PricingWordpress/Pricing";
import PromocodeTab from "./Componenets/Subscription/Admin/PromocodeTab";
import RSetting from "./Componenets/Admin/Retrival/RSetting";
import UpdateLessonPlans from "./Componenets/Teacher/UpdateLessonPlans";
import ViewLessonPlan from "./Componenets/Teacher/ViewLessonPlans";
import RetrievalQuiz from "./Componenets/Student/RetrievalQuiz";
import AddPriorPlan from "./Componenets/Teacher/AddPriorPlan";
import QuestionConfigurator from "./Componenets/widgets/QuestionConfigurator";
import AccountDeletion from "./Componenets/AccountDeletion";
import CustomNotification from "./Componenets/CustomNotification";
import AdminPricing from "./Componenets/AdminPricing";
import Confirm from "./Componenets/Subscription/Stripe/Confirm";
import TeacherSubjectHome from "./Componenets/Teacher/TeacherSubjectHome";
import StudentLeaderboard from "./Componenets/Student/widget/StudentLeaderboard";
const configuration = {
  auth: {
    clientId: "82c15ec2-c4b6-47e0-b520-6f3839d026c4",
  },
};

const pca = new PublicClientApplication(configuration);

function App() {
  const token = localStorage.getItem("access_token");
  const [loggedIn, setLoggedIn] = useState(true);

  const checkForInactivity = () => {
    const expireTime = localStorage.getItem("expireTime");

    if (expireTime < Date.now()) {
      var url = localStorage.getItem("user_type") === "3" ? "/student_logout" : "/logout";
      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["authorization"] = token;
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + url, {
          method: "POST",
        })
        .then((response) => {
          console.log(response);
          localStorage.removeItem("student_name");
          localStorage.removeItem("quiz_taker");
          localStorage.removeItem("quiz_type");
          localStorage.removeItem("class_code");
          localStorage.removeItem("student");
          localStorage.removeItem("access_token");
          localStorage.removeItem("user_type");
          localStorage.removeItem("email_address");
          alert("you've been logged out due to inactivity");
          window.location.replace("/login");
          setLoggedIn(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const updateExpireTime = () => {
    const expireTime = Date.now() + 7200000;
    localStorage.setItem("expireTime", expireTime);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.location.pathname === "/login") {
        console.log("Don't check");
        return;
      }
      checkForInactivity();
    }, 7200000);

    return () => clearInterval(interval);
  });

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (window.location.pathname === "/login") {
  //       console.log("Don't check");
  //       return;
  //     }
  //     checkForInactivity();
  //   }, 600000);

  //   return () => clearInterval(interval);
  // });

  useEffect(() => {
    updateExpireTime();

    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    return () => {
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    };
  }, []);
  //const navigate = useNavigate();
  useEffect(() => {
    if (token === null) {
      //https://staging.teepee.ai/quiz?id=358f6de3d9dfd517fe26587c30786d6a
      if (window.location.pathname === "/quiz" || window.location.pathname === "/about_us" || window.location.pathname === "/register") return;
      if (window.location.pathname !== "/login" && window.location.pathname === "experience") window.location.replace("/login");
    } else {
      getUserJwtSession();
    }
  }, []);

  const getUserJwtSession = () => {
    var token = localStorage.getItem("access_token");
    if (token === null) {
      //if(window.location.pathname!=='/login')
      //  window.location.replace('/login');
      return;
    }
    token = "Bearer " + localStorage.getItem("access_token");

    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/check_user_jwt_session",

        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
      })
      .then((res) => {
        console.log(res.data);
        console.log(window.location.pathname);
        if (window.location.pathname === "/login")
          //if(window.location.pathname!=='/')
          window.location.replace("/");
        // window.location.replace('/dashboard');
        //setQuizzes(res.data);
      })
      .catch((error) => {
        console.log(error);
        // if token is expired but page to access is about us pge then allow user to view this page.
        if (window.location.pathname === "/about_us") {
          return;
        }
        if (window.location.pathname === "/experience") {
          return;
        }

        localStorage.removeItem("access_token");
        localStorage.removeItem("student_name");
        localStorage.removeItem("user_type");
        localStorage.removeItem("email_address");
        localStorage.removeItem("quiz_taker");
        localStorage.removeItem("quiz_type");
        localStorage.removeItem("class_code");
        window.location.replace("/login");
      });
  };

  const AuthWrapper = () => {
    return isExpired(localStorage.getItem("access_token")) ? <Navigate to="/login" replace /> : <Outlet />;
  };

  const isExpired = (access_token) => {
    const decodedJwt = parseJwt(access_token);

    if (decodedJwt.exp * 1000 < Date.now()) {
      return true;
    }

    return false;
  };

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  console.log("JUST TESTING: ");

  return (
    <MsalProvider instance={pca}>
      <Routes>
        <Route path="ms_login" element={<MSLogin />} />
        <Route path="/" element={<ProtecredWebUserRoute />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/about_us" element={<AboutUs />}></Route>
        <Route path="/promo_code" element={<PromocodeTab />}></Route>
        {/* <Route element={<AuthWrapper />}> */}
        {/* <Route path="/dashboard" element={<Dashboard />}></Route> */}
        <Route path="/users" element={<ProtectedAdminRoutes element={<Users />} />}></Route>
        <Route path="/report_problem" element={<ContactUs />}></Route>
        {/* <Route path="/view_subject_details" element={<ViewSubjectDetails />}></Route> */}
        <Route path="/view_subject_content" element={<ProtectedAdminRoutes element={<SubjectContentContainer />} />}></Route>
        {/* <Route path="/edit_predefined_questions" element={<EditPredefinedQuestions />}></Route> */}
        <Route path="/subject_prompt" element={<ProtectedAdminRoutes element={<JustifiedExample />} />}></Route>
        <Route path="/view_subject" element={<ProtectedAdminRoutes element={<ViewSubject />} />}></Route>
        <Route path="/users_feedback" element={<ProtectedAdminRoutes element={<UsersFeedback />} />}></Route>
        <Route path="/evaluation_feedback" element={<ProtectedAdminRoutes element={<UsersQuestionEvaluationFeedback />} />}></Route>
        <Route path="/tracking_analytics" element={<ProtectedAdminRoutes element={<TrackingAnalytics />} />}></Route>
        <Route path="/create-quiz" element={<CreateQuiz />}></Route>
        <Route path="/add-details" element={<AddDetails />}></Route>
        <Route path="/share" element={<Share />}></Route>
        <Route path="/contact_us" element={<EmailUs />}></Route>
        <Route path="/email_us" element={<EmailUsFeedback />}></Route>
        <Route path="/quiz" element={<Quiz />}></Route>

        <Route path="/view-result" element={<ViewResult />}></Route>
        <Route path="/google-login" element={<GoogleLogin />}></Route>
        <Route path="/quiz-detail-review" element={<QuizDetailReview />}></Route>
        <Route path="/teacher-classroom-home" element={<ProtectedTeacherRoutes element={<TeacherClassroomHome />} />}></Route>
        <Route path="/teacher-subject-home" element={<ProtectedTeacherRoutes element={<TeacherSubjectHome />} />}></Route>
        <Route path="/create-classroom_quiz" element={<ProtectedAdminRoutes element={<CreateClassroomQuiz />} />}></Route>
        <Route path="/student-dashboard" element={<ProtectedStudentRoute element={<StudentDashbaord />} />}></Route>
        <Route path="/student-view-classroom" element={<ProtectedStudentRoute element={<StudentViewClassroom />} />}></Route>
        <Route path="/onboarding-subject" element={<OnBoardingSubject />}></Route>
        <Route path="/onboarding" element={<OnBoarding />}></Route>
        <Route path="/interest-analytics" element={<InterestAnalytics />}></Route>
        <Route path="/evaluation-experience" element={<EvaluationExperince />}></Route>
        <Route path="/onboarding-video" element={<OnboardingVideos />}></Route>
        <Route path="/tutorials" element={<Tutorials />}></Route>
        <Route path="/key-metrics" element={<KeyMatrics />}></Route>
        <Route path="/subject-analysis" element={<SubjectAnalysis />}></Route>
        <Route path="/live-users" element={<LiveUsersTable />}></Route>
        <Route path="/experience" element={<SelectSubject />}></Route>
        <Route path="/subscription" element={<Subscribe />}></Route>
        <Route path="/checkout" element={<Checkout />}></Route>
        <Route path="/manage-subject" element={<ManageSubject />}></Route>
        <Route path="/subscription-tracking" element={<SubscriptionTracking />}></Route>
        <Route path="/configure" element={<TrialAndSubscriptionConfigure />}></Route>
        <Route path="/pricing" element={<Pricing />}></Route>
        <Route path="/subject_settings" element={<RSetting />} />
        <Route path="/update-lesson-plans" element={<UpdateLessonPlans />} />
        <Route path="/add-lesson-plans" element={<ViewLessonPlan />} />
        <Route path="/retrieval_quiz" element={<RetrievalQuiz />} />
        <Route path="/add-prior-plans" element={<AddPriorPlan />} />
        <Route path="/question-configuration" element={<QuestionConfigurator />} />
        <Route path="/account-deletion" element={<AccountDeletion />} />
        <Route path="/custom_notification" element={<CustomNotification />} />
        <Route path="/admin_pricing" element={<AdminPricing />} />
        <Route path="/success" element={<Confirm />} />
        <Route path="/student-leaderboard" element={<StudentLeaderboard />} />
      </Routes>
    </MsalProvider>
  );
}

export default App;
