import React, { useContext } from "react";
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from "@material-tailwind/react";
import { useEffect } from "react";
import appContext from "../../../context/appContext";
import ResultsTabBody from "./ResultsTabBody";
import QuizAnalysisTabBody from "./QuizAnalysisTabBody";

function QuizAnalyticsTabs({
  selectedStudent,
  quiz_grace_time,
  quiz_title,
  created_timestamp,
  quizType,
  quiz_duration,
  csvReportForMiniMock,
  quiz_total_marks,
  quizData,
  getTimeString,
  handlerPerformance,
  get_list_of_attempted_students,
  handleSeeMoreClick,
  userType,
  handleRawResponseClick,
  url,
  studentName,
  topicData,
  subtopicData,
  subtopicPercentage,
  areaData,
  areaPercentage,
  tableSelection,
  areaNames,
  marks,
  tableData,
  averagePercentage,
  averageScore,
  questionMarks,
  minimockQuestionSequence,
  handleChange,
  handleIndividualQuiz,
  totalAreaMarks,
  handleOpenComingSoonDialogue,
  tableDataForCSV,
  questionClassPercentage,
}) {
  var tabState = localStorage.getItem("quizTabState");
  const state = useContext(appContext);

  console.log("VALUE IN TABS: ", url);

  useEffect(() => {
    if (state.quizTabLoadCount === 1) {
      localStorage.setItem("tabState", 1);
      console.log("LOCAL STORAGE HSA BEEN UPDATED: ", localStorage);
      state.setQuizTabLoadCount(2);
    }
  });

  const data = [
    {
      label: "Results",
      value: "1",
      body: (
        <ResultsTabBody
          quiz_grace_time={quiz_grace_time}
          quiz_title={quiz_title}
          created_timestamp={created_timestamp}
          quizType={quizType}
          quiz_duration={quiz_duration}
          csvReportForMiniMock={csvReportForMiniMock}
          quiz_total_marks={quiz_total_marks}
          quizData={quizData}
          getTimeString={getTimeString}
          handlerPerformance={handlerPerformance}
          get_list_of_attempted_students={get_list_of_attempted_students}
          handleSeeMoreClick={handleSeeMoreClick}
          userType={userType}
          handleRawResponseClick={handleRawResponseClick}
        />
      ),
    },
    {
      label: "Task Analysis",
      value: "2",
      body: (
        <QuizAnalysisTabBody
          selectedStudent={selectedStudent}
          totalAreaMarks={totalAreaMarks}
          handleIndividualQuiz={handleIndividualQuiz}
          handleChange={handleChange}
          minimockQuestionSequence={minimockQuestionSequence}
          questionMarks={questionMarks}
          averageScore={averageScore}
          averagePercentage={averagePercentage}
          tableData={tableData}
          marks={marks}
          areaNames={areaNames}
          tableSelection={tableSelection}
          areaPercentage={areaPercentage}
          areaData={areaData}
          subtopicPercentage={subtopicPercentage}
          subtopicData={subtopicData}
          studentName={studentName}
          topicData={topicData}
          url={url}
          quiz_title={quiz_title}
          created_timestamp={created_timestamp}
          quizType={quizType}
          quiz_duration={quiz_duration}
          quizData={quizData}
          handleOpenComingSoonDialogue={handleOpenComingSoonDialogue}
          tableDataForCSV={tableDataForCSV}
          questionClassPercentage={questionClassPercentage}
        />
      ),
    },
  ];
  return (
    <div>
      <Tabs id="custom-animation" value={tabState} className="w-[100%] ">
        <div>
          <center>
            <div className="flex">
              <div className="md:w-[18%] ml-[auto] mr-[10%]">
                <TabsHeader
                  className="w-[100%] h-[58px] justify-center bg-[#6750A414]"
                  indicatorProps={{
                    className: "bg-[#6750A414] shadow-none !text-gray-900",
                  }}
                >
                  {data.map(({ label, value, icon }) => (
                    <div
                      onClick={() => {
                        localStorage.setItem("quizTabState", value);
                      }}
                    >
                      <Tab id="ABC" key={value} value={value} icon={icon} className="w-[100%] mr-[20px] ">
                        <div>{label}</div>
                      </Tab>
                    </div>
                  ))}
                </TabsHeader>
              </div>
            </div>
          </center>
        </div>
        <div>
          <div className="w-[10%] float-right mb-[10px]"></div>
          <TabsBody animate={{}}>
            {data.map(({ value, body }) => (
              <TabPanel key={value} value={value}>
                {body}
              </TabPanel>
            ))}
          </TabsBody>
        </div>
      </Tabs>
    </div>
  );
}

export default QuizAnalyticsTabs;
