import React, { useState } from "react";

const TabSelection = ({ activeTab, setActiveTab }) => {
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="relative flex items-end justify-between md:w-1/4 sm:w-[80%] ml-4 pb-2">
      {/* Do These Tab */}
      <div
        onClick={() => handleTabClick("do")}
        className={`cursor-pointer ml-[5%]  text-gray-700 ${activeTab === "do" ? "font-semibold" : "font-normal"}`}
      >
        Do these!
      </div>

      {/* Done Them Tab */}
      <div
        onClick={() => handleTabClick("done")}
        className={`cursor-pointer  text-gray-700 mr-[5%] ${activeTab === "done" ? "font-semibold" : "font-normal"}`}
      >
        Done them!
      </div>

      {/* Animated Underline */}
      <div
        className="absolute bottom-0 h-[2px] bg-gray-300 transition-all duration-300 ease-in-out"
        style={{
          width: "50%", // Width of the underline based on each tab width
          transform: activeTab === "do" ? "translateX(100%)" : "translateX(0%)",
        }}
      />

      <div
        className="absolute bottom-0 h-[2px] bg-purple-600 transition-all duration-300 ease-in-out"
        style={{
          width: "50%", // Width of the underline based on each tab width
          transform: activeTab === "do" ? "translateX(0%)" : "translateX(100%)",
        }}
      />
    </div>
  );
};

export default TabSelection;
