import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import amplitude from "amplitude-js";
import { Menu, MenuItem, IconButton } from "@mui/material";
import ThreeDots from "../../../images/more_icon.svg";
import Person from "../../../images/person.png";
import ManageSubscription from "../../../images/manage-subscription.svg";
import ManageStudents from "../../../images/mange-students.svg";

function DashboardSubjectCard({ subjectsData, handleOpenManageStudents, handleOpenManageSubscription, setSelectedSubject, selectedSubject }) {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event, classroom) => {
    setAnchorEl(event.currentTarget);
    setSelectedSubject(classroom);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedSubject(null);
  };

  return (
    <div>
      <div className="flex flex-col items-center">
        <div className="grid xlg:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-0 w-full">
          {subjectsData?.map((item, index) => (
            <div className="quiz-div" style={{ height: "auto" }} key={index}>
              <div>
                <div className="inner-my-quizzes !p-0 !pr-[3%]">
                  <div>
                    <div className="flex justify-between items-center h-[63px]">
                      <span className="dashboard-my-quizzes-name text-with-dots ml-[2%] !font-medium !text-[16px] tracking-[0.15px] text-[#1D192BFF]">
                        {item.subject_name}
                      </span>
                      <div className="flex relative">
                        <div className="flex items-center ">
                          <img src={Person} alt="views" className="pr-1 h-[16px] w-[16px] object-contain" />
                          <span className=" text-sm">{item.students}</span>
                        </div>
                        <IconButton
                          onClick={(event) => handleMenuOpen(event, item)}
                          className={`!pl-0 !pr-0 ${(item.subscription_card_type === 1 || item.subscription_card_type === 2) && "!invisible"}`}
                        >
                          <img className="h-[10px] w-[14px] " src={ThreeDots} alt="more options" />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                          transformOrigin={{ vertical: "top", horizontal: "right" }}
                          PaperProps={{
                            style: {
                              backgroundColor: "#FFFBFE",
                              boxShadow: "0 0px 0px rgba(0, 0, 0, 0.0)",
                              paddingTop: "0",
                              marginTop: "0",
                              border: "0.05px solid rgba(0, 0, 0, 0.1)",
                            },
                          }}
                        >
                          <MenuItem onClick={handleOpenManageSubscription}>
                            <img className="mr-2 h-[14px] w-[14px]" src={ManageSubscription} alt="delete" />
                            Manage Subscription
                          </MenuItem>
                          <MenuItem onClick={() => handleOpenManageStudents(selectedSubject)}>
                            <img className="mr-2 h-[14px] w-[14px]" src={ManageStudents} alt="delete" />
                            Manage Students
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-start p-[3%] mb-[5%]">
                <div style={{ color: "#FFCC00", fontSize: "20px", marginRight: "10px" }}>{item.icon}</div>
                <div className="h-[50px]">
                  <div className="text-[16px] tracking-[0.15px] leading-6 text-[#1D192BFF]">{item.text}</div>
                  <div className="text-[#49454FFF] tracking-[0.15px] leading-4 text-[12px]">{item.sub_heading}</div>
                </div>
              </div>
              {item.html_content}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DashboardSubjectCard;
