import React from "react";
import { useNavigate } from "react-router-dom";

function TeacherNavigationText(props) {
  var navigate = useNavigate();
  const handleOnClick = () => {
    if (props.pop === 1) {
      navigate(-1);
    } else {
      navigate(-2);
    }
  };
  return (
    <div className="home sm:ml[25px] md:ml-[60px]">
      <button
        className="underline teacher_navigation_text"
        onClick={handleOnClick}
      >
        {props.text}
      </button>
    </div>
  );
}

export default TeacherNavigationText;
