import React, { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Cross from "../../images/cross.png";
import { useState } from "react";
import axios from "axios";

function CopyQuizDialogue(props) {
  return (
    <div>
      <Dialog
        PaperProps={{
          sx: {
            width: "768px",
            height: "562px",
            maxWidth: "768px",
            height: "562px",
            maxHeight: "366px",
          },
        }}
        open={props.quizNameDialogue}
        onClose={props.handleCloseDialogue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="flex">
          <button onClick={() => props.handleCloseDialogue(true)}>
            <img src={Cross}></img>
          </button>
          <div className="text-center w-[100%] create_new_classroom_dialogue_title">Enter your task name</div>
        </DialogTitle>
        <hr className="mt-0 "></hr>

        <div>
          <DialogContent style={{ marginLeft: "30px", marginRight: "50px" }}>
            <DialogContentText id="new-classroom-dialog-description">
              <div>
                <label className="create_your_class_label">Naming your Task Copy</label>
              </div>

              <div>
                <label className="class_name_hint_label">Assign a name to this task copy and proceed to the next step</label>
              </div>
            </DialogContentText>
            <div>
              <div>
                <input
                  maxLength={45}
                  onChange={(e) => props.onChangeNumber(e)}
                  placeholder="e.g., Copy of Class Task 1"
                  style={{ width: "549px", marginRight: "20px" }}
                  type="text"
                  name="class_code"
                  id="class_code"
                  className="class_name_input pl-[23px]"
                  required
                />
              </div>
              <div className="float-right pt-[5px] mr-[14%]">{props.inputText.length}/45</div>
            </div>
          </DialogContent>
        </div>
        <hr style={{ marginTop: "auto", width: "100%", marginBottom: "0px" }}></hr>

        <DialogActions
          style={{
            marginRight: "15px",
            float: "right",
          }}
        >
          <button
            style={{ width: "110px" }}
            id="button"
            onClick={props.handleClickContinue}
            className="teepee-button md:ml-12 hover:bg-black sm:w-[50%] md:w-[46%]"
          >
            Next
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CopyQuizDialogue;
