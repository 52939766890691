import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import FormativeAssessmentPrompt from "./FormativeAssessmentPrompt";
import ExamStyleAssessmentPrompt from "./ExamStyleAssessmentPrompt";
import Sidebar from "./Sidebar";
import SubjectMarksLevelPrompts from "./SubjectMarksLevelPrompts";
import SubjectMarksLevelPromptsformative from "./SubjectMarksLevelPromptsformative";
import PredefinedQuestionsPromptContainer from "./PredefinedQuestionsPromptContainer";

function JustifiedExample() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const [subjectData, setSubjectData] = useState(null);
  const [subjectMarksLevelPromptList, setSubjectMarksLevelPromptList] = useState([]);
  const [subjectMarksLevelPromptListFormataive, setSubjectMarksLevelPromptListFormative] = useState([]);
  const [predefinedQuestionsPromptList, setPredefinedQuestionsPromptList] = useState([]);
  const [updatedPredefinedQuestionsPromptList, setUpdatedPredefinedQuestionsPromptList] = useState([]);
  const [formativePredefinedQuestionsPromptList, setFormativePredefinedQuestionsPromptList] = useState([]);
  const [minimockPredefinedQuestionsPromptList, setMinimockPredefinedQuestionsPromptList] = useState([]);

  useEffect(() => {
    getSubjectDetails(id);
    getSubjectMarksLevelPrompts(id);
    getPredefinedQuestionsPrompts(id);
    getSubjectMarksLevelPromptListFormative(id); // Call the problematic API function here
  }, [id]);

  function getSubjectDetails(subject_id) {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_subject_prompt", {
        method: "POST",
        subject_id: subject_id,
      })
      .then((response) => {
        var subject1 = JSON.parse(response.data["subject"]);
        setSubjectData(subject1);
      })
      .catch((error) => {
        console.log("Error fetching subject details:", error);
      });
  }

  function getSubjectMarksLevelPrompts(subject_id) {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_subject_marks_level_prompt_list", {
        method: "POST",
        subject_id: id,
      })
      .then((response) => {
        var subjectMarksLevelPromptList = JSON.parse(response.data["subject_marks_level_prompt_list"]);
        setSubjectMarksLevelPromptList(subjectMarksLevelPromptList);
      })
      .catch((error) => {
        console.log("Error fetching subject marks level prompts:", error);
      });
  }

  function getSubjectMarksLevelPromptListFormative(subject_id) {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_subject_marks_level_prompt_list_formative", {
        method: "POST",
        subject_id: id,
      })
      .then((response) => {
        var subjectMarksLevelPromptListFormataive = JSON.parse(response.data["subject_marks_level_prompt_list"]);
        setSubjectMarksLevelPromptListFormative(subjectMarksLevelPromptListFormataive);
      })
      .catch((error) => {
        console.log("Error fetching formative subject marks level prompts:", error);
      });
  }

  function getPredefinedQuestionsPrompts(subject_id) {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_predefined_questions_prompt_list", {
        method: "POST",
        subject_id: id,
        show_disabled: true,
      })
      .then((response) => {
        var predefinedQuestionsPromptList = JSON.parse(response.data["predefined_questions_prompt_list"]);
        var formativePredefinedQuestionsPrompt = JSON.parse(response.data["formative_predefined_questions_prompt_list"]);
        var minimockPredefinedQuestionsPrompt = JSON.parse(response.data["mini_mock_predefined_questions_prompt_list"]);
        var updatedformativePredefinedQuestionsPrompt = JSON.parse(response.data["predefined_questions_prompt_list_updated"]);
        setPredefinedQuestionsPromptList(predefinedQuestionsPromptList);
        setFormativePredefinedQuestionsPromptList(formativePredefinedQuestionsPrompt);
        setMinimockPredefinedQuestionsPromptList(minimockPredefinedQuestionsPrompt);
        setUpdatedPredefinedQuestionsPromptList(updatedformativePredefinedQuestionsPrompt);
      })
      .catch((error) => {
        console.log("Error fetching predefined questions prompts:", error);
      });
  }

  return (
    <>
      <Sidebar item_id="view_subject" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px]">
        <div className="home sm:ml[25px] md:ml-[60px]">
          <div>
            <label className="dashboard-label">Subject Prompt</label>
            <div className="teepee-divider"></div>
          </div>
        </div>
        <div className="flex-row justify-left items-center sm:pl-[10px] sm:pr-[10px] md:pl-[60px] md:pr-[40px] mt-[20px]">
          {subjectData && (
            <Tabs>
              <TabList>
                <Tab>Formative Assessment Prompt</Tab>
                <Tab>Exams Assessment Prompt</Tab>
                <Tab>Examstyle marks level prompt</Tab>
                <Tab>Formative Marks level prompt</Tab>
                <Tab>Formative Predefined Question Prompt</Tab>
                <Tab>Exam Style Predefined Question Prompt</Tab>
                <Tab>Updated Exam Style Predefined Question Prompt</Tab>
                <Tab>Mini-mock Predefined Question Prompt</Tab>
              </TabList>
              <TabPanel>
                <FormativeAssessmentPrompt getSubjectDetails={getSubjectDetails} subject_data={subjectData} />
              </TabPanel>
              <TabPanel>
                <ExamStyleAssessmentPrompt getSubjectDetails={getSubjectDetails} subject_data={subjectData} />
              </TabPanel>
              <TabPanel>
                <SubjectMarksLevelPrompts
                  addTodo={getSubjectMarksLevelPrompts}
                  subject_id={id}
                  subject_marks_level_prompt_list={subjectMarksLevelPromptList}
                />
              </TabPanel>
              <TabPanel>
                <SubjectMarksLevelPromptsformative
                  addTodo={getSubjectMarksLevelPromptListFormative}
                  subject_id={id}
                  subject_marks_level_prompt_list={subjectMarksLevelPromptListFormataive}
                />
              </TabPanel>
              <TabPanel>
                <PredefinedQuestionsPromptContainer
                  addTodo={getPredefinedQuestionsPrompts}
                  subject_id={id}
                  predefined_questions_prompt_list={formativePredefinedQuestionsPromptList}
                  quizType={1}
                />
              </TabPanel>
              <TabPanel>
                <PredefinedQuestionsPromptContainer
                  addTodo={getPredefinedQuestionsPrompts}
                  subject_id={id}
                  predefined_questions_prompt_list={predefinedQuestionsPromptList}
                  quizType={3}
                />
              </TabPanel>
              <TabPanel>
                <PredefinedQuestionsPromptContainer
                  addTodo={getPredefinedQuestionsPrompts}
                  subject_id={id}
                  predefined_questions_prompt_list={updatedPredefinedQuestionsPromptList}
                  name="updated"
                  quizType={3}
                />
              </TabPanel>
              <TabPanel>
                <PredefinedQuestionsPromptContainer
                  addTodo={getPredefinedQuestionsPrompts}
                  subject_id={id}
                  predefined_questions_prompt_list={minimockPredefinedQuestionsPromptList}
                  quizType={2}
                />
              </TabPanel>
            </Tabs>
          )}
        </div>
      </div>
    </>
  );
}

export default JustifiedExample;
