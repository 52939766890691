import React, { useState, useContext } from "react";
import appContext from "../../context/appContext";

function AvailableSubjectsCard({ subject, index }) {
  const state = useContext(appContext);

  const handleGradient = () => {
    const arr = [
      "subscription-card-graident-type-1",
      "subscription-card-graident-type-2",
      "subscription-card-graident-type-3",
    ];
    const gradientType = Math.floor(Math.random() * 3);
    const selectedGradient = arr[gradientType];
    return selectedGradient;
  };

  const handleClickGetStarted = () => {
    state.setSelectedSubjectForPopUp(subject);
    state.handleClickOpenCreateClassroomDialogue();
  };
  return (
    <div className={`quiz-div ${handleGradient()} w-full !rounded-xl`}>
      <div className="p-3 text-[28px] font-medium text-white whitespace-nowrap overflow-hidden text-ellipsis">
        {subject.subject_name}
      </div>
      <div className="mt-[2%]  flex justify-center">
        <button
          className="p-3 bg-white ml-[2%] mr-[2%] w-full rounded text-[#2C84AE] text-[14px] font-medium"
          onClick={() => handleClickGetStarted()}
        >
          Get Started
        </button>
      </div>
    </div>
  );
}

export default AvailableSubjectsCard;
