import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#F0F0F0",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  background: "#f0f0f0",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function QuizRawResponseAccordian(props) {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const message = "No results.\n\nPlease try another search term.";

  return (
    <div>
      <Tabs>
        <TabList>
          <Tab>Input</Tab>
          <Tab>Output</Tab>
        </TabList>

        <TabPanel>
          {props.quizEvaluationLog.map((quizEvaluationLog, key) => {
            return (
              <>
                <Accordion
                  expanded={expanded === "panel1" + key}
                  onChange={handleChange("panel1" + key)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>{"Api call " + (key + 1)}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="border-t-[2px] border-b-[2px] h-[30px] border-black answer-paragraph">
                        <div style={{ float: "left" }}>
                          {"Creation Tokens: " +
                            quizEvaluationLog.creation_tokens}
                        </div>
                        <div style={{ float: "right" }}>
                          {"Completion Tokens: " +
                            quizEvaluationLog.completion_tokens}
                        </div>
                      </div>
                      <div className="new-line">
                        {quizEvaluationLog.openai_input}
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </>
            );
          })}
        </TabPanel>
        <TabPanel>
          {props.quizEvaluationLog.map((quizEvaluationLog, key) => {
            return (
              <>
                <Accordion
                  expanded={expanded === "panel1" + key}
                  onChange={handleChange("panel1" + key)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>{"Api call " + (key + 1)}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="border-t-[2px] border-b-[2px] h-[30px] border-black answer-paragraph">
                        <div style={{ float: "left" }}>
                          {"Creation Tokens: " +
                            quizEvaluationLog.creation_tokens}
                        </div>
                        <div style={{ float: "right" }}>
                          {"Completion Tokens: " +
                            quizEvaluationLog.completion_tokens}
                        </div>
                      </div>
                      <div className="new-line">
                        {quizEvaluationLog.openai_output}
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </>
            );
          })}
        </TabPanel>
      </Tabs>
    </div>
  );
}
