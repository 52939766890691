import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    border: "1px solid #dadde9",
    borderRadius: "8px",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(12),
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    maxWidth: 250,
    opacity: "0.8",
    padding: "12px 16px",
  },
}));
const Metric = ({ icon, label, value, tooltip }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const isArray = Array.isArray(value);
  return (
    <div className="flex items-center">
      <ClickAwayListener onClickAway={handleClose}>
        <div className="flex ">
          <HtmlTooltip
            title={tooltip}
            PopperProps={{
              disablePortal: true,
            }}
            enterTouchDelay={0}
            leaveTouchDelay={10}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
          >
            <div className="flex items-center">
              <button className="ml-[5px] mt-[2px]" onClick={handleOpen}>
                <img className="h-[40px] w-[40px]" src={icon} alt="" />
              </button>

              <div className="ml-3">
                <div className="text-[#403151] opacity-75 text-[16px] leading-[24px] font-roboto">
                  {label}
                </div>
                <div className="text-[#403151] text-[24px] leading-[20px] font-roboto">
                  {isArray
                    ? // Map over the array and render each percentage
                      value.map((item, index) => (
                        <span key={index}>
                          {parseInt(item) +
                            "%" +
                            (index + 1 === value?.length ? "" : ", ")}
                        </span>
                      ))
                    : // Render a single percentage
                      `${parseInt(value) + "%"}`}
                </div>
              </div>
            </div>
          </HtmlTooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
};
export default Metric;
