import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import AcknowledgeIcon from "../../../images/teepee-icon.svg";
import Cross from "../../../images/cross.png";
import appContext from "../../../context/appContext";
import { useContext } from "react";

function TrialPeriodAcknowledgmentPopup(props) {
  const state = useContext(appContext);
  console.log("DATA IN THE POPUP: ", state.selectedSubjectObject.current?.days_left);

  const handleClickLetsGo = () => {
    state.setOpenShareClassroomDialogue(true);
    props.handleCloseTrialAcknowledgePopup();
  };

  const handleUpgradeNow = () => {
    props.handleCloseTrialAcknowledgePopup();
    state.setOpenBuyVoucherPopUp(true);
  };

  const handleTotalNumberOfQuizzes = () => {
    if (localStorage.getItem("email_address") === process.env.REACT_APP_REQUESTED_USER && state.selectedSubjectIdForClass === 10) {
      return "35 days";
    } else {
      return `${state.selectedSubjectObject.current?.days_left} days`;
    }
  };

  return (
    <div>
      <Dialog
        PaperProps={{
          sx: {
            width: "60%",
            height: "70%",
            maxWidth: "70%",
            maxHeight: "70%",
            borderRadius: "12px",
            overflow: "auto",
            padding: "16px",
          },
        }}
        open={props.openTrialAcknowledgePopup}
        onClose={() => handleClickLetsGo()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div>
          <center>
            <DialogContent
              className="sm:ml-[4px] sm:mr-[4px] md:ml-[11%] md:mr-[11%] flex justify-center mt-[7%] overflow-y-auto item-center"
              style={{ scrollbarWidth: "none", msOverflowStyle: "none" }} // For Firefox and IE
            >
              <div style={{ maxHeight: "100%", overflowY: "auto" }}>
                <div className="pt-[1.6vh]">
                  <img src={AcknowledgeIcon} className="w-[50px] md:w-[70px]" alt="Acknowledge Icon" />
                </div>
                <div className="mt-[5%]  text-xl md:text-2xl font-bold text-[#1D192B] text-center">
                  🎉 Your {handleTotalNumberOfQuizzes()} Free Trial is Active!
                </div>
                <div
                  className="mt-[2%] text-sm md:text-base font-medium text-[#49454F] text-center"
                  style={{ lineHeight: "1.5rem", letterSpacing: "0.009rem" }}
                >
                  {`You’re on a ${handleTotalNumberOfQuizzes()} free trial for `}
                  <strong>{state.selectedSubjectForClass} —</strong>
                  <strong>
                    <i>no credit card required. </i>
                  </strong>
                  {` You can upgrade after the trial ends, or anytime before.`}
                </div>
                <div className="mt-[8%] flex justify-center">
                  <button
                    className="teepee-button !text-sm	!font-semibold h-[40px] !text-[#ffffff] w-[80%] md:w-[60%]"
                    onClick={() => handleClickLetsGo()}
                  >
                    Continue
                  </button>
                </div>
                <div className="mt-[4%] flex justify-center">
                  <button className="text-[#6750A4] text-sm md:text-base" onClick={() => handleUpgradeNow()}>
                    ⚡ Upgrade Now
                  </button>
                </div>
              </div>
            </DialogContent>
          </center>
        </div>
      </Dialog>
    </div>
  );
}

export default TrialPeriodAcknowledgmentPopup;
