import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import axios from "axios";
import Table from "./Widgets/table";

const EvaluationExperince = () => {
  useEffect(() => {
    getSubjectsList();
  }, []);

  const getSubjectsList = () => {
    axios
      .request({
        method: "GET",
        url:
          process.env.REACT_APP_REST_API_BASE_URL +
          "/get_evaluation_experience_data",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setRows(res.data.data);
      });
  };

  const [rows, setRows] = useState([]);
  return (
    <>
      <Sidebar item_id="evaluation_experience" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px] ">
        <div className=" md:ml-[60px]  ">
          <div>
            <Table rows={rows} />
          </div>
        </div>
      </div>
    </>
  );
};
export default EvaluationExperince;
