import React, { useState } from "react";
import Slider from "react-slick";
import subject_icon from "../../../images/menu_book.svg";
import quiz_icon from "../../../images/assignment.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function StudentDoTheseCard(props) {
  const { doThese, quizType, viewQuiz } = props;

  const [isSliding, setIsSliding] = useState(false);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: doThese?.length === 1 ? 1 : 3.1,
    slidesToScroll: doThese?.length === 1 ? 1 : 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    beforeChange: () => setIsSliding(true),
    afterChange: () => setIsSliding(false),
  };

  const handleClick = (item) => {
    if (!isSliding) {
      viewQuiz(item, 1);
    }
  };

  return (
    <div className="mt-10">
      <Slider {...settings}>
        {doThese.map((item, index) => (
          <div key={index} className="p-2">
            <div
              className={`bg-gradient-to-r ${quizType === 1 ? "from-[#937CFE]" : "from-[#91AEF4]"} ${
                quizType === 1 ? "to-[#B58DFE]" : "to-[#95C6F4]"
              } text-white p-6 rounded-lg shadow-md w-[285px]`}
              onClick={() => handleClick(item)}
            >
              <div className="flex justify-between items-start mb-4">
                <div className="text-lg font-bold">{quizType === 2 ? item.student_quiz_title : item.quiz_title}</div>
                <div
                  style={{ fontFamily: "Roboto" }}
                  className="bg-white text-[#424242] text-[14px] leading-[16px]  font-bold px-3 py-1 rounded-[4px] "
                >
                  {quizType === 1 ? (item.quiz_type === 2 ? "Mini-mock" : item.quiz_type === 1 ? "Formative" : "Exam Style") : "Retrieval"}
                </div>
              </div>
              <div className="flex items-center mb-4">
                <img src={subject_icon} alt="sub Icon" className="w-5 h-5 mr-2" />
                <span style={{ fontFamily: "Roboto" }} className="text-[14px] leading-[20px] font-medium">
                  {item.subject_name}
                </span>
              </div>
              <div className="flex items-center mb-6">
                <img src={quiz_icon} alt="Quiz Icon" className="w-5 h-5 mr-2" />
                <span style={{ fontFamily: "Roboto" }} className="text-[14px] leading-[20px] font-medium">
                  {quizType === 1 ? "Exam style Questions" : "Retrieval Question"}
                </span>
              </div>
              <button className="bg-white text-[16px] leading-[20px] text-[#424242] font-bold py-2 px-4 w-full rounded-md hover:bg-gray-200">
                {quizType === 1 ? "START TASK" : "START QUIZ"}
              </button>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default StudentDoTheseCard;
