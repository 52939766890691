import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import defaultAnimationData from "../../images/glow.json";

const Transition = () => {
  const [animationData, setAnimationData] = useState(defaultAnimationData);

  useEffect(() => {
    fetch("../../images/glow.json")
      .then((response) => response.json())
      .then((data) => setAnimationData(data))
      .catch((error) => console.error("Error fetching animation:", error));
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="item-center content-center">
      <span className="centered">
        <Lottie options={defaultOptions} height={256} width={256} />
      </span>
      <p>
        <span className="text-center justify-center item-center ">
          <center>
            {" "}
            Access expert-written <b>exam questions</b> and <b>mocks</b>, plus{" "}
            <u>AI marking & instant,</u> <br></br>
            reliable feedback.
          </center>
        </span>
      </p>
    </div>
  );
};

export default Transition;
