import React from "react";
import Delete from "../../../images/delete_footer.png";
import Copy from "../../../images/content_copy_footer.png";
import { Tooltip } from "@mui/material";

function StickyFooterDashboard({ checked, handleCancel, handleDeleteMultipleQuiz, handleCopyMultipleQuizzes }) {
  return (
    <div
      className="bg-[#6750A4] fixed top-[92%] w-[65%] h-[48px] z-10  rounded-xl flex items-center justify-between pl-[3%] pr-[3%] ml-[60px] "
      style={{ display: checked.length === 0 && "none" }}
    >
      <div className="sticky_dashboard_text">{`${checked.length} Quiz Selected`}</div>
      <div className="w-[20%] flex justify-evenly">
        <button onClick={() => handleDeleteMultipleQuiz()}>
          <Tooltip placement="top" title={"Delete"}>
            <img src={Delete} />
          </Tooltip>
        </button>
        {/* <button
          style={{ display: checked.length > 1 && "none" }}
          onClick={() =>
            editQuizName(stickyEditIndex, true, stickyEditQuiz.quiz_title)
          }
        >
          <Tooltip placement="top" title={"Rename"}>
            <img src={Edit} />
          </Tooltip>
        </button> */}
        <button onClick={() => handleCopyMultipleQuizzes()}>
          {checked.length !== 0 && (
            <Tooltip placement="top" title={"Make a copy"}>
              <img src={Copy} />
            </Tooltip>
          )}
        </button>
        <button onClick={handleCancel} className="sticky_dashboard_text">
          Cancel
        </button>
      </div>
    </div>
  );
}

export default StickyFooterDashboard;
