import React from "react";
import ComingSoon from "../widgets/ComingSoon";

function TeacherMarkbookTabBody() {
  return (
    <div>
      <center>
        <div>
          <ComingSoon />
        </div>
      </center>
    </div>
  );
}

export default TeacherMarkbookTabBody;
