import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import TrackingTabs from "./widgets/tracking_tabs/TrackingTabs";

function TrackingAnalytics() {
  return (
    <div>
      <Sidebar item_id="tracking_analytics" />
      <div className=" sm:ml-0 md:ml-[316px] mb-[40px]">
        <div id="quiz_table" className="ml-[46px] w-[90%]">
          <div id="tracking_tabs" className="mt-[56px]">
            <TrackingTabs />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrackingAnalytics;
