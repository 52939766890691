import React from "react";
import Sidebar from "../Sidebar";
import LiveUsersTabs from "./live_users_tabs/LiveUsersTabs";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

function LiveUsersTable() {
  const [liveUsersData, setLiveUsersData] = useState([]);

  useEffect(() => {
    getLiveUser();
  }, []);
  const getLiveUser = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/active_users", {
        method: "POST",
      })
      .then((response) => {
        console.log(response);
        console.log("dasdas", response.data);
        setLiveUsersData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Sidebar item_id="dashboard" />
      <div className=" sm:ml-0 md:ml-[316px] mb-[40px]">
        <div id="quiz_table" className="ml-[46px] w-[90%]">
          <div id="tracking_tabs" className="mt-[56px]">
            <LiveUsersTabs liveUsersData={liveUsersData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveUsersTable;
