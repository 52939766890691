import React from "react";
import Lottie from "react-lottie";
import animationData from "../../../images/retrievel.json";
const LessonPlanCommingSoon = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <h2 className="font-bold text-[20px] text-center " style={{ fontFamily: "roboto" }}>
        Coming soon!{" "}
      </h2>
      <p className="text-[16px] text-center" style={{ fontFamily: "roboto" }}>
        We're crafting amazing content for your retrieval practice{" "}
      </p>
      <Lottie options={defaultOptions} height={250} width={400} />
    </div>
  );
};

export default LessonPlanCommingSoon;
