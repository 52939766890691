import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function ClassroomDeletedDialog({ open, onClose }) {
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "400px",
          padding: "12px",
        },
      }}
      open={open}
      onClose={onClose}
      aria-labelledby="classroom-deleted-dialog"
    >
      <DialogTitle
        id="classroom-deleted-dialog"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontFamily: "Roboto",
          paddingBottom: "0", // Removes bottom padding to bring heading closer to the body
        }}
      >
        <b> Classroom Deleted</b>
        <IconButton aria-label="close" onClick={onClose} style={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ marginTop: "4px" }}>
        {" "}
        {/* Adds a small margin between heading and content */}
        <Typography variant="body2" style={{ color: "#7C7C7C" }}>
          The classroom has been deleted <br></br> successfully.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          style={{
            backgroundColor: "#6750A4",
            fontFamily: "Roboto",
            color: "#fff",
            textTransform: "none",
          }}
          variant="contained"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
