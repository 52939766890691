import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

export default function DeleteClassroomDialog({ open, onClose, handleDeleteConfirm, handleDeleteCancel }) {
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "400px", // Consistent with the size in ClassroomDeletedDialog
          padding: "12px",
        },
      }}
      open={open}
      onClose={onClose}
      aria-labelledby="delete-classroom-dialog"
    >
      <DialogTitle
        id="delete-classroom-dialog"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "0", // Consistent padding as ClassroomDeletedDialog
        }}
      >
        <Typography variant="h6" style={{ fontWeight: 600 }}>
          Delete Classroom
        </Typography>
        <IconButton aria-label="close" onClick={onClose} style={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body2" style={{ color: "#95A8B2", marginTop: "4px" }}>
          Are you sure you want to delete this
          <br /> Classroom?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleDeleteCancel}
          style={{
            backgroundColor: "#E5E7EB",
            color: "#4B5563",
            textTransform: "none",
          }}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          onClick={handleDeleteConfirm}
          style={{
            backgroundColor: "#6750A4",
            color: "#fff",
            textTransform: "none",
            marginLeft: "10px",
          }}
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
