import React from "react";

function StudentScoreTabBody(props) {
  const quizzes = props.quizzes;
  return (
    <div className="mt-[39px] ">
      <div>
        <div className="mb-[32px] student-score-table-label">You can track your performance across all tasks and view your task feedback</div>
      </div>
      <div className="overflow-x-auto">
        <table class="w-full text-sm text-left text-white dark:text-white">
          <thead class="text-xs text-white  bg-[#6750A4] dark:bg-white dark:text-white">
            <tr>
              <th scope="col" class="px-6 py-3">
                ID
              </th>
              <th scope="col" class="px-6 py-3 " style={{ textAlign: "center" }}>
                Task Name
              </th>
              <th scope="col" class="px-6 py-3" style={{ textAlign: "center" }}>
                Marks
              </th>
              <th scope="col" class="px-6 py-3" style={{ textAlign: "center" }}>
                Class Average Score
              </th>
              <th scope="col" class="px-6 py-3" style={{ textAlign: "center" }}>
                Feedback
              </th>
            </tr>
          </thead>
          <tbody>
            {quizzes.map((quiz, index) => {
              console.log("Average :", quiz.average_performance);
              return (
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {index + 1}
                  </th>
                  <td class="px-6 py-4 max-w-xs ">
                    <span class="truncate max-w-xs " style={{ textAlign: "center" }}>
                      {quiz.quiz_title}
                    </span>{" "}
                  </td>
                  <td class="px-6 py-4" style={{ textAlign: "center" }}>
                    {((quiz.quiz_total_marks / 100) * quiz.overall_score).toFixed(2) + "/" + quiz.quiz_total_marks}
                  </td>
                  <td class="px-6 py-4" style={{ textAlign: "center" }}>
                    {(quiz.average_performance * (quiz.quiz_total_marks / 100)).toFixed(2)}
                  </td>

                  <td class="px-6 py-4" style={{ textAlign: "center" }}>
                    {" "}
                    <button value={quiz.secret_key} className="table_text" onClick={() => props.handleOnClickDoneThemQuiz(index)}>
                      <span class="truncate max-w-xs" className="underline">
                        view
                      </span>{" "}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StudentScoreTabBody;
