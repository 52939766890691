import React, { useState, useRef, useEffect } from "react";
import { Select, MenuItem, Button, Popper, TextField, ClickAwayListener } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import crown from "../../images/crown.svg";
import xps from "../../images/xps.svg";
import leaderIcon from "../../images/leader.svg";
import goldmedal from "../../images/1st_medal.svg";
import silvermedal from "../../images/2nd_medal.svg";
import bronzemedal from "../../images/3rd_medal.svg";
import { useLocation } from "react-router-dom";

const Leaderboard = ({ leaderboardData, startDate, setStartDate, endDate, setEndDate }) => {
  const [dateFilter, setDateFilter] = useState("Last 7 Days");
  const [anchorEl, setAnchorEl] = useState(null);
  const selectRef = useRef(null);
  const [customeDateSelected, setCustomDateSelected] = useState(false);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const location = useLocation();
  const handleDateFilterChange = (event) => {
    const value = event.target.value;

    if (value === "Custom Date") {
      setDateFilter("Custom Date");
      setAnchorEl(selectRef.current); // Open Popper when "Custom Date" is selected
    } else {
      setDateFilter(value);
      setAnchorEl(null);
      applylast7DaysFilter();
    }
  };

  const applylast7DaysFilter = () => {
    const today = new Date();
    const pastWeek = new Date(today);
    pastWeek.setDate(today.getDate() - 7);

    setStartDate(pastWeek);
    setEndDate(today);
  };

  const applyCustomDateFilter = () => {
    if (tempStartDate && tempEndDate) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
      setAnchorEl(null);
      setCustomDateSelected(true);
    }
  };

  const handleClickAway = (event) => {
    if (selectRef.current && selectRef.current.contains(event.target)) {
      return; // Do not close Popper if clicking on the select
    }

    setAnchorEl(null);
  };

  const colors = ["bg-[#4DA9A9]", "bg-[#F4A93B]", "bg-[#5338AD]", "bg-[#E95353]", "bg-[#4290F5]"];

  const sortedLeaderboard = leaderboardData?.data
    ?.sort((a, b) => b.total_today_xp - a.total_today_xp)
    .map((entry, index) => ({ ...entry, rank: index + 1 }));
  const allZeroXP = sortedLeaderboard.every((entry) => entry.total_today_xp === "0");
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="bg-[#F7F2F9] shadow-sm rounded-lg p-8 w-full max-w-[100%] mb-[220px]  mx-auto mt-8" style={{ fontFamily: "Roboto" }}>
        {/* Leaderboard Header with Date Filter */}

        <div className="md:flex sm:flex-1 justify-between items-center mb-4">
          <h2 className="inline-flex text-2xl font-bold sm:mb-2 md:mb-0 ml-6">
            <b> Leaderboard</b>
            <span className="ml-2">
              <img src={leaderIcon} className="h-8 w-8" alt=" " />
            </span>
          </h2>
          <div className="relative">
            <Select
              value={dateFilter}
              onChange={handleDateFilterChange}
              className="bg-white rounded-md shadow-md"
              ref={selectRef}
              style={{ width: "content-fit", float: "right", display: (location.href = "/student-leaderboard" ? "none" : "") }}
            >
              <MenuItem
                value="Last 7 Days"
                onClick={() => {
                  setTempEndDate(null);
                  setTempStartDate(null);
                  setCustomDateSelected(false);
                }}
              >
                Last 7 Days
              </MenuItem>
              <MenuItem
                value="Custom Date"
                onClick={() => {
                  if (dateFilter === "Custom Date") {
                    setAnchorEl(selectRef.current);
                  } else {
                    setAnchorEl(selectRef.current);
                  }
                }}
              >
                Custom Date
              </MenuItem>
            </Select>

            <br />
            <br />
            {/* Conditionally render the start and end date below the Select */}
            {dateFilter === "Custom Date" && startDate && endDate && customeDateSelected === true && (
              <div className="flex items-center mt-6 space-x-2 ">
                <span>from</span>
                <TextField
                  value={startDate.toLocaleDateString("en-US")}
                  disabled
                  variant="outlined"
                  style={{ width: "120px", backgroundColor: "white" }}
                />
                <span>to</span>
                <TextField
                  value={endDate.toLocaleDateString("en-US")}
                  disabled
                  variant="outlined"
                  style={{ width: "120px", backgroundColor: "white" }}
                />
              </div>
            )}
          </div>
        </div>

        {/* Leaderboard Table */}
        <div className="rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full min-w-[600px] md:min-w-[100%]">
              <thead className="bg-[#F7F2F9]">
                <tr>
                  <th className="px-6 py-3 text-left text-sm md:text-base">
                    <div className="flex items-center space-x-4">
                      <div className="flex items-center justify-center w-10 h-10"></div>
                      <div className={`inline-flex items-center justify-center w-10 h-10  rounded-full`}>
                        <b>Name</b>
                      </div>
                      <span></span>
                    </div>
                  </th>
                  <th className={`py-3 ${sortedLeaderboard?.length === 0 ? " text-left" : "px-16 text-right"} text-sm md:text-base`}>
                    <b> {allZeroXP && sortedLeaderboard?.length > 0 ? " " : "Rank"}</b>
                  </th>
                  <th className="px-6 py-3 text-right text-sm md:text-base">
                    <b>Points (XP)</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedLeaderboard && sortedLeaderboard?.length > 0 ? (
                  sortedLeaderboard?.map((entry, index) => (
                    <tr key={index} className="border-b-[12px] border-[#F7F2F9] bg-white">
                      <td className="px-6 py-4 text-left">
                        <div className="flex items-center space-x-4">
                          <div className="flex items-center justify-center w-10 h-10">
                            {index === 0 && entry?.total_today_xp > 0 ? (
                              <img src={crown} alt="Crown" className="h-10 w-10" />
                            ) : (
                              <span>{index + 1}</span>
                            )}
                          </div>
                          <div
                            className={`inline-flex items-center justify-center w-10 h-10 text-white rounded-full ${colors[index % colors.length]}`}
                          >
                            {entry?.student_name[0]}
                          </div>
                          <span>{entry?.student_name}</span>
                        </div>
                      </td>
                      <td className="px-16 py-4 text-right">
                        {entry.rank >= 1 && entry.rank <= 3 && entry?.total_today_xp > 0 ? (
                          <img
                            src={entry.rank === 1 ? goldmedal : entry.rank === 2 ? silvermedal : bronzemedal}
                            alt="medal"
                            className="h-12 w-12 float-right"
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="px-6 py-4 text-right flex items-center justify-end mt-2">
                        <span>{entry.total_today_xp}</span>
                        <img src={xps} alt="XP Icon" className="h-5 w-5 ml-2" />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="border-b-[12px] border-[#F7F2F9] bg-white">
                    <td colSpan="3" className="text-center py-8 px-6">
                      <h3 className="text-xl font-bold">Leaderboard is empty</h3>
                      <p className="text-sm text-gray-600">Once students join and take quizzes, their results will appear here.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Custom Date Picker Popper below the Select */}
        <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start" disablePortal>
          <ClickAwayListener
            onClickAway={() => {
              setAnchorEl(null);
              if (!tempStartDate && !tempEndDate) {
                setDateFilter("Last 7 Days");
              }
            }}
          >
            <div className="bg-white shadow-lg rounded-lg p-4 mt-2 w-max fixed right-[-140px]">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className="flex flex-col space-y-2">
                  <span>
                    <b>Start Date:</b>
                  </span>
                  <DatePicker
                    value={tempStartDate}
                    onChange={(newValue) => setTempStartDate(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    disablePortal
                  />

                  <sub className="ml-3 mb-2 text-[#49454F]">MM/DD/YYYY</sub>
                  <span>
                    <b>End Date:</b>
                  </span>
                  <DatePicker
                    value={tempEndDate}
                    onChange={(newValue) => setTempEndDate(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    disablePortal
                  />
                  <sub className="ml-3 mb-2 text-[#49454F]">MM/DD/YYYY</sub>
                </div>
              </LocalizationProvider>
              <div className="flex justify-start mt-4 space-x-2">
                <Button onClick={applyCustomDateFilter} variant="contained" style={{ backgroundColor: "#6750A4" }}>
                  Search
                </Button>
              </div>
            </div>
          </ClickAwayListener>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default Leaderboard;
