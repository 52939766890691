import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import StudentIcon from "../../../images/studentIcon.svg";

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} placement="top" />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    border: "1px solid #dadde9",
    borderRadius: "8px",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(12),
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    maxWidth: 250,
    opacity: "0.8",
    padding: "12px 16px",
    textAlign: "center",
  },
}));
const AnalyticsTable = ({ selectedTopic, data, setStudentId, studentId, setLoading }) => {
  const [open, setOpen] = useState(false);
  const [openTooltipIndex, setOpenTooltipIndex] = useState(null);

  const handleTooltipOpen = (index) => {
    setOpenTooltipIndex(index);
  };

  const handleTooltipClose = () => {
    setOpenTooltipIndex(null);
  };

  console.log("data here is ", data);
  const returnToClass = () => {
    setLoading(true);
    setStudentId(null);
  };
  // Color handling based on average attempts to mastery
  const handleColor = (percentage) => {
    const value = parseInt(percentage);
    // if (value >= 80) {
    //   return "#14D38E";
    // } else if (value >= 60) {
    //   return "#835BEF";
    // } else if (value >= 40) {
    //   return "#F8B250";
    // } else if (value >= 20) {
    //   return "#0293EE";
    // } else {
    //   return "#DF557F";
    // }
    if (value >= 80) {
      return "#55CE98";
    } else if (value >= 60) {
      return "#E6CBA5";
    } else if (value >= 40) {
      return "#FEF1CE";
    } else if (value >= 20) {
      return "#F8B250";
    } else {
      return "#FAAD98";
    }
  };
  const getColorForPercentage = (percentageString) => {
    const value = parseInt(percentageString, 10); // Parse percentage as integer
    // if (value >= 80) {
    //   return "#14D38E";
    // } else if (value >= 60) {
    //   return "#835BEF";
    // } else if (value >= 40) {
    //   return "#F8B250";
    // } else if (value >= 20) {
    //   return "#0293EE";
    // } else {
    //   return "#DF557F";
    // }
    if (value >= 80) {
      return "#55CE98";
    } else if (value >= 60) {
      return "#E6CBA5";
    } else if (value >= 40) {
      return "#FEF1CE";
    } else if (value >= 20) {
      return "#F8B250";
    } else {
      return "#FAAD98";
    }
  };
  const renderTopicHeader = () => {
    if (!data?.topic_data || data?.topic_data.length === 0) {
      return (
        <tr>
          <th colSpan={100} className="text-center py-2 px-4">
            Data will be available once students start attempting the quizzes.
          </th>
        </tr>
      );
    }

    return (
      <tr className="bg-[#403151] text-white text-center" style={{ borderBottom: "0.1px solid #665A74" }}>
        <th
          className="py-2 px-4 "
          rowSpan={2}
          style={{
            borderRight: "0.1px solid white",
            backgroundImage: "linear-gradient(#403151, #7E418B)",
            color: "white!important",
          }}
        >
          Student Name
        </th>
        {data.topic_data.map((topic, index) => (
          <th
            key={topic.topic_id}
            className="py-2 px-4"
            style={{ borderRight: "0.1px solid white" }}
            colSpan={selectedTopic === "subtopic" ? topic.total_sub_topics : 1}
          >
            <div className="flex whitespace-pre	text-center justify-center">
              <div className="font-bold	">{`Topic ${index + 1}: `}</div>
              {topic.topic_name}
            </div>
            <div>({topic.total_questions})</div>
          </th>
        ))}
      </tr>
    );
  };

  const renderSubtopicHeader = () => {
    if (!data?.topic_data || data?.topic_data.length === 0) {
      return (
        <tr>
          <th colSpan={100} className="text-center py-2 px-4"></th>
        </tr>
      );
    }

    let subtopicCounter = 0;
    const subtopics = [];

    data?.topic_data.forEach((topic) => {
      for (let i = 0; i < topic.total_sub_topics; i++) {
        subtopicCounter = ++subtopicCounter;
        subtopics.push({
          sub_topic_id: data.sub_topics[subtopics.length]?.sub_topic_id,
          sub_topic_name: data.sub_topics[subtopics.length]?.sub_topic_name,
          total_questions: data.sub_topics[subtopics.length]?.total_questions,
          number: subtopicCounter,
        });
      }
      subtopicCounter = 0;
    });
    if (selectedTopic === "subtopic") {
      return (
        <tr className="bg-[#7E418B] text-white text-left ">
          {/* <th
            className="py-2 px-4 text-center"
            style={{ borderRight: "0.1px solid white" }}
          >
            Subtopics
          </th> */}
          {subtopics.map((subtopic, index) => (
            <th key={subtopic.sub_topic_id} className="py-2 px-4 text-left" style={{ borderRight: "0.1px solid white" }}>
              <div className="flex whitespace-pre	justify-center">
                <div className="font-bold	">{`Subtopic ${subtopic.number}: `}</div>
                {subtopic.sub_topic_name}
              </div>
              <div className="text-center">({subtopic.total_questions})</div>
            </th>
          ))}
        </tr>
      );
    }
    return null;
  };
  const renderStudentRows = () => {
    // Ensure student_data is always treated as an array
    const normalizedStudentData = Array.isArray(data?.student_data) ? data?.student_data : [data?.student_data];

    return normalizedStudentData?.map((student, index) => {
      return (
        <tr className="border-b" key={student?.student_name + index}>
          <td className={`py-2 px-4  text-center `} style={{ borderWidth: "thin" }}>
            <span
              onClick={() => {
                if (studentId === null) {
                  setStudentId(student.student_id);
                  setLoading(true);
                }
              }}
              style={{
                cursor: studentId === null ? "pointer" : "default",
                color: studentId === null ? "#001CFF" : "",
              }}
            >
              {" "}
              <HtmlTooltip
                title="Click to view detailed analytics for this student."
                PopperProps={{ disablePortal: true }}
                enterTouchDelay={0}
                leaveTouchDelay={10}
                open={openTooltipIndex === `${student?.student_name}-${index}`}
                onClose={handleTooltipClose}
              >
                <span
                  onMouseEnter={() => {
                    if (studentId === null) {
                      handleTooltipOpen(`${student?.student_name}-${index}`);
                    }
                  }}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: studentId === null ? "pointer" : "", // This ensures the cursor changes to pointer on hover
                    maxWidth: "150px", // Set a maximum width to control overflow, adjust as necessary
                  }}
                >
                  <div className="flex justify-center">
                    <img src={StudentIcon} className="mr-3" />
                    {student?.student_name}
                  </div>
                </span>
              </HtmlTooltip>
              {/* {student?.student_name} */}
            </span>
          </td>
          {selectedTopic === "topic"
            ? student?.mastered_questions?.map((masteredQuestions, idx) => (
                <td
                  key={idx}
                  className={`py-2 px-4 text-left ${idx + 1 !== student?.mastered_questions.length && "border-r"}`}
                  style={{
                    backgroundColor: getColorForPercentage((masteredQuestions / data?.topic_data[idx]?.total_questions) * 100),
                  }}
                >
                  <HtmlTooltip
                    title="The number of questions that the student has answered correctly three consecutive times"
                    PopperProps={{ disablePortal: true }}
                    enterTouchDelay={0}
                    leaveTouchDelay={10}
                    open={openTooltipIndex === `topic-${index}-${idx}`}
                    onClose={handleTooltipClose}
                  >
                    <span
                      onMouseEnter={() => handleTooltipOpen(`topic-${index}-${idx}`)}
                      onMouseLeave={handleTooltipClose}
                      className="cursor-pointer"
                    >
                      {masteredQuestions}
                    </span>
                  </HtmlTooltip>
                </td>
              ))
            : student?.mastered_subtopic_question?.map((masteredQuestions, idxx) => (
                <td
                  key={idxx}
                  className={`py-2 px-4 text-left ${idxx + 1 !== student?.mastered_subtopic_question.length && "border-r"}`}
                  style={{
                    backgroundColor: getColorForPercentage((masteredQuestions / data?.sub_topics[idxx]?.total_questions) * 100),
                  }}
                >
                  <HtmlTooltip
                    title="The number of questions that the student has answered correctly three consecutive times"
                    PopperProps={{ disablePortal: true }}
                    enterTouchDelay={0}
                    leaveTouchDelay={10}
                    open={openTooltipIndex === `subtopic-${index}-${idxx}`}
                    onClose={handleTooltipClose}
                  >
                    <span
                      onMouseEnter={() => handleTooltipOpen(`subtopic-${index}-${idxx}`)}
                      onMouseLeave={handleTooltipClose}
                      className="cursor-pointer"
                    >
                      {masteredQuestions}
                    </span>
                  </HtmlTooltip>
                </td>
              ))}
        </tr>
      );
    });
  };

  const renderFixedRows = (name) => {
    if (!data?.topic_data || data?.topic_data.length === 0) {
      return null;
    }
    return (
      <tr className={`bg-gray-100  ${name === (studentId === null ? "Class Mastery Level" : "Mastery Level") ? "border-b" : ""} `}>
        <td className="py-2 px-4  text-center" style={{ borderWidth: "thin" }}>
          {" "}
          <HtmlTooltip
            title={
              studentId === null
                ? name === "Class Mastery Level"
                  ? "The percentage of questions that the entire class has answered correctly three consecutive times."
                  : "It is a measure of the average number of attempts the entire class takes to master each question."
                : name === "Mastery Level"
                ? "The percentage of questions that a student has answered correctly three consecutive times."
                : "It is a measure of the average number of attempts a student takes to master each question."
            }
            PopperProps={{ disablePortal: true }}
            enterTouchDelay={0}
            leaveTouchDelay={10}
            open={openTooltipIndex === `${name}-${0}`}
            onClose={handleTooltipClose}
          >
            <span
              onMouseEnter={() => handleTooltipOpen(`${name}-${0}`)}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer", // This ensures the cursor changes to pointer on hover
                maxWidth: "150px", // Set a maximum width to control overflow, adjust as necessary
              }}
            >
              {name}
            </span>
          </HtmlTooltip>
        </td>
        {(selectedTopic === "topic" ? data?.topic_data : data?.sub_topics)?.map((topic, idxx) => (
          <td
            key={selectedTopic === "topic" ? topic.topic_id : topic.sub_topic_id}
            className={`py-2 px-4 text-left ${idxx + 1 !== data[selectedTopic === "topic" ? "topic_data" : "sub_topics"].length ? "border-r " : " "}`}
            style={{
              borderRight: "",

              backgroundColor:
                name === "Class Avg. Attempts to Mastery" || name === "Avg. Attempts to Mastery"
                  ? handleColor((3 / topic.average_attempt_to_mastery) * 100)
                  : handleColor(parseFloat(topic.recall_status_4_percentage)?.toFixed(2) + "%"),
            }}
          >
            {name === "Mastery Level" || name === "Class Mastery Level"
              ? parseFloat(topic.recall_status_4_percentage)?.toFixed(2) + "%"
              : parseInt(topic.average_attempt_to_mastery)}
          </td>
        ))}
      </tr>
    );
  };
  return (
    <div className="mt-4   overflow-x-auto">
      <table className="min-w-full bg-white  mb-[1%]">
        {renderTopicHeader()}
        {renderSubtopicHeader()}

        <tbody className="text-center ">
          {!data?.topic_data || (data?.topic_data.length > 0 && renderStudentRows())}
          {renderFixedRows(studentId === null ? "Class Mastery Level" : "Mastery Level")}
          {renderFixedRows(studentId === null ? "Class Avg. Attempts to Mastery" : "Avg. Attempts to Mastery")}
        </tbody>
      </table>
      <p className="cursor-pointer underline text-blue-600" style={{ display: studentId === null ? "none" : "inline" }} onClick={returnToClass}>
        Return to Class Analytics
      </p>
    </div>
  );
};
export default AnalyticsTable;
