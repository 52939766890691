import React from "react";

const QuizzesSentCard = ({ quizzesSent, lastUpdated }) => {
  return (
    <div className="bg-[#F1F2F7] rounded-lg p-4 pb-[1rem] shadow-sm w-full h-full flex flex-col justify-between" style={{ height: "content-fit" }}>
      {/* Title Section */}
      <div className="flex items-center">
        <h3 className="font-medium text-[#1D192B] text-[1.5rem] leading-[33px]">📊 Quizzes Sent to Students</h3>
      </div>

      {/* Description */}
      <p className="text-gray-600 text-sm">The number of quizzes delivered to your students so far.</p>

      {/* Quizzes Sent Count */}
      <div className="text-gray-900 text-5xl font-bold my-4 text-center">{quizzesSent}</div>

      {/* Spacer to push the last section down */}
      {/* <div className="flex-grow"></div> */}

      {/* Last Updated Section */}
      <div className=" text-xs ">Last updated: {lastUpdated} ago</div>
    </div>
  );
};

export default QuizzesSentCard;
