import React, { useEffect, useState } from "react";
import Sidebar from "../../Sidebar";
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from "@material-tailwind/react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import SubscriptionDetailTable from "./widget/SubscriptionDetailTable";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
function SubscriptionTracking() {
  const selectFieldStyles = {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid",
      borderColor: "black",
      // Change the border color to your desired focus color
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#79747e", // Change the normal border color here
    },
    "& .MuiSelect-select.MuiSelect-select": {
      marginLeft: "13px",
      color: "#474747", // Change the color of the selected option(s)
      fontSize: "18px",
      fontWeight: "399",
      fontFamily:
        'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"', // Change the font size of the selected option(s)
    },
  };

  const [tabState, setTabState] = useState("1");

  const [startData, setStartDate] = useState(null);
  const [endData, setEndDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [startDataSubscription, setStartDateSubscription] = useState(null);
  const [endDataSubscription, setEndDateSubscription] = useState(null);
  const [openSubscription, setOpenSubscription] = useState(false);
  const [startDataSubject, setStartDateSubject] = useState(null);
  const [endDataSubject, setEndDateSubject] = useState(null);
  const [openSubject, setOpenSubject] = useState(false);
  const [trackingData, setTrackingData] = useState();
  const trialColumns = [
    { title: "ID", key: "id", width: "10%" },
    { title: "Email", key: "email_address", width: "20%" },
    { title: "Password", key: "password", width: "20%" },
    { title: "Subject", key: "subject_name", width: "15%" },
    { title: "Trial Start", key: "trial_start_date", width: "15%" },
    { title: "Trial End", key: "trial_end_date", width: "15%" },
    {
      title: "No of Quizzes Generated",
      key: "quiz_gen_count_trial",
      width: "15%",
    },
    {
      title: "Number of quizzes shared/drafted",
      key: "quiz_count",
      width: "15%",
    },
    {
      title: "Quizzes attempted",
      key: "quiz_attempted_trial",
      width: "15%",
    },
    {
      title: "Number of students enrolled",
      key: "students_enrolled_trial",
      width: "15%",
    },
    {
      title: "Create classroom",
      key: "create_classroom_trial",
      width: "15%",
    },
  ];
  const handleClose = () => {
    setStartDate(null);
    setEndDate(null);
    setOpen(false);
  };

  const handleConfirm = () => {
    if (startData === null || endData === null) {
      alert("please add both dates");
      return;
    }
    console.log("TAB VALUE : ", startData, endData);
    const year = startData.$y;
    const month = startData.$M + 1; // Adding 1 because months are 0-based
    const day = startData.$D;

    const year2 = endData.$y;
    const month2 = endData.$M + 1; // Adding 1 because months are 0-based
    const day2 = endData.$D;

    const startDate = `${year}-${month}-${day}`;
    const endDate = `${year2}-${month2}-${day2}`;
    handleClose();
    // const url =
    //   tabState === "1"
    //     ? "/filter_stat_inside_class"
    //     : "/filter_stat_outside_class";
    // axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    // axios.defaults.headers.post["Content-Type"] =
    //   "application/json;charset=utf-8";
    // axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    // axios
    //   .post(process.env.REACT_APP_REST_API_BASE_URL + url, {
    //     method: "POST",
    //     start_date: startDate,
    //     end_date: endDate,
    //   })

    //   .then((response) => {
    //     console.log(response);
    //     if (tabState === "1") {
    //       setinsideClassroomStats(response.data);
    //     } else {
    //       setOutsideClassroomStats(response.data);
    //     }
    //     handleClose();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
  const handleCloseSubscription = () => {
    setStartDateSubscription(null);
    setEndDateSubscription(null);
    setOpenSubscription(false);
  };

  const handleConfirmSubscription = () => {
    if (startDataSubscription === null || endDataSubscription === null) {
      alert("please add both dates");
      return;
    }
    console.log("start date " + startDataSubscription + " end date " + endDataSubscription);
    // console.log("TAB VALUE : ", tabState, startData, endData);
    // const year = startData.$y;
    // const month = startData.$M + 1; // Adding 1 because months are 0-based
    // const day = startData.$D;

    // const year2 = endData.$y;
    // const month2 = endData.$M + 1; // Adding 1 because months are 0-based
    // const day2 = endData.$D;

    // const startDate = `${year}-${month}-${day}`;
    // const endDate = `${year2}-${month2}-${day2}`;
    handleCloseSubscription();
    // const url =
    //   tabState === "1"
    //     ? "/filter_stat_inside_class"
    //     : "/filter_stat_outside_class";
    // axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    // axios.defaults.headers.post["Content-Type"] =
    //   "application/json;charset=utf-8";
    // axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    // axios
    //   .post(process.env.REACT_APP_REST_API_BASE_URL + url, {
    //     method: "POST",
    //     start_date: startDate,
    //     end_date: endDate,
    //   })

    //   .then((response) => {
    //     console.log(response);
    //     if (tabState === "1") {
    //       setinsideClassroomStats(response.data);
    //     } else {
    //       setOutsideClassroomStats(response.data);
    //     }
    //     handleClose();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
  const handleCloseSubject = () => {
    setStartDateSubject(null);
    setEndDateSubject(null);
    setOpenSubject(false);
  };

  const handleConfirmSubject = () => {
    if (startDataSubject === null || endDataSubject === null) {
      alert("please add both dates");
      return;
    }
    console.log("start date " + startDataSubject + " end date " + endDataSubject);
    // console.log("TAB VALUE : ", tabState, startData, endData);
    // const year = startData.$y;
    // const month = startData.$M + 1; // Adding 1 because months are 0-based
    // const day = startData.$D;

    // const year2 = endData.$y;
    // const month2 = endData.$M + 1; // Adding 1 because months are 0-based
    // const day2 = endData.$D;

    // const startDate = `${year}-${month}-${day}`;
    // const endDate = `${year2}-${month2}-${day2}`;
    handleCloseSubject();
    // const url =
    //   tabState === "1"
    //     ? "/filter_stat_inside_class"
    //     : "/filter_stat_outside_class";
    // axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    // axios.defaults.headers.post["Content-Type"] =
    //   "application/json;charset=utf-8";
    // axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    // axios
    //   .post(process.env.REACT_APP_REST_API_BASE_URL + url, {
    //     method: "POST",
    //     start_date: startDate,
    //     end_date: endDate,
    //   })

    //   .then((response) => {
    //     console.log(response);
    //     if (tabState === "1") {
    //       setinsideClassroomStats(response.data);
    //     } else {
    //       setOutsideClassroomStats(response.data);
    //     }
    //     handleClose();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  const getData = () => {
    const url = "/get_subscription_tracking";
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + url, {
        method: "POST",
        start_date: null,
        end_date: null,
      })

      .then((response) => {
        console.log(response);
        setTrackingData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const subscriptionColumns = [
    { title: "ID", key: "id", width: "10%" },
    { title: "Email", key: "email_address", width: "20%" },
    {
      title: "Password",
      key: "password",
      width: "20%",
    },
    {
      title: "Subject",
      key: "subject_name",
      width: "20%",
    },
    {
      title: "Subscription Started",
      key: "subscription_start_date",
      width: "15%",
    },
    { title: "Subscription Ended", key: "subscription_end_date", width: "15%" },
    {
      title: "No. of vouchers bought",
      key: "total_voucher_bought",
      width: "15%",
    },
    {
      title: "Number of Quizzes Generated",
      key: "quiz_gen_subscribed",
      width: "15%",
    },
    {
      title: "Number of quizzes shared/drafted",
      key: "quiz_count_subscribed",
      width: "15%",
    },
    {
      title: "available vouchers",
      key: "vouchers_bought",
      width: "15%",
    },
    {
      title: "Vouchers active",
      key: "disabled_vouchers",
      width: "15%",
    },
    {
      title: "Number of students enrolled",
      key: "students_enrolled_subscription",
      width: "15%",
    },
    {
      title: "Quizzes attempted",
      key: "attempted_quiz_subscription",
      width: "15%",
    },
    {
      title: "Total classrooms",
      key: "create_classroom_subscription",
      width: "15%",
    },
  ];
  const subjectsColumns = [
    { title: "ID", key: "id", width: "10%" },
    { title: "Subject", key: "subject_name", width: "20%" },
    {
      title: "No of users that started trial ",
      key: "num_users_started_trial",
      width: "20%",
    },
    {
      title: "No of users that bought subscription after trial ",
      key: "num_users_bought_after_trial",
      width: "15%",
    },
    {
      title: "No of users that bought subscription during trial ",
      key: "num_users_bought_during_trial",
      width: "15%",
    },
  ];
  var table1 = [];
  var table2 = [];
  var table3 = [];
  if (trackingData) {
    table1 = trackingData.trial;
    table2 = trackingData.subscription;
    table3 = trackingData.subject;
  }

  const data = [
    {
      label: "Trial",
      value: "1",
      body: (
        <>
          {/* <FormControl
            sx={selectFieldStyles}
            className="w-[10%] float-right mb-2"
          >
            <InputLabel
              shrink={false}
              id="demo-simple-select-label"
              style={{ marginBottom: "10px" }}
            >
              Date
            </InputLabel>
            <Select
              open={open}
              onClose={handleClose}
              onOpen={() => setOpen(true)}
              style={{ height: "53px" }}
              className=""
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              name="chapter"
            >
              <div className="pr-[35px] pl-[35px] pt-[12px]">
                <div>
                  <div className="mb-[8px]">Start Date</div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={startData}
                      onChange={(newValue) => setStartDate(newValue)}
                      format="YYYY-MM-DD"
                    />
                  </LocalizationProvider>
                </div>
                <div className="pt-[8px]">End Date</div>
                <div className="mt-[8px]">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={endData}
                      onChange={(newValue) => setEndDate(newValue)}
                      format="YYYY-MM-DD"
                    />
                  </LocalizationProvider>
                </div>
                <div className="">
                  <button
                    className="dialogue-button-text mt-[30px]"
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </button>
                  <button
                    className="teepee-button  mt-[20px] mb-[17px]"
                    onClick={() => handleConfirm()}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Select>
          </FormControl> */}
          <SubscriptionDetailTable columns={trialColumns} data={table1} />
        </>
      ),
    },
    {
      label: "Subscription",
      value: "2",
      body: (
        <>
          {/* <FormControl
            sx={selectFieldStyles}
            className="w-[10%] float-right mb-2"
          >
            <InputLabel
              shrink={false}
              id="demo-simple-select-label"
              style={{ marginBottom: "10px" }}
            >
              Date
            </InputLabel>
            <Select
              open={openSubscription}
              onClose={handleCloseSubscription}
              onOpen={() => setOpenSubscription(true)}
              style={{ height: "53px" }}
              className=""
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              name="chapter"
            >
              <div className="pr-[35px] pl-[35px] pt-[12px]">
                <div>
                  <div className="mb-[8px]">Start Date</div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={startDataSubscription}
                      onChange={(newValue) =>
                        setStartDateSubscription(newValue)
                      }
                      format="YYYY-MM-DD"
                    />
                  </LocalizationProvider>
                </div>
                <div className="pt-[8px]">End Date</div>
                <div className="mt-[8px]">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={endDataSubscription}
                      onChange={(newValue) => setEndDateSubscription(newValue)}
                      format="YYYY-MM-DD"
                    />
                  </LocalizationProvider>
                </div>
                <div className="">
                  <button
                    className="dialogue-button-text mt-[30px]"
                    onClick={() => handleCloseSubscription()}
                  >
                    Cancel
                  </button>
                  <button
                    className="teepee-button  mt-[20px] mb-[17px]"
                    onClick={() => handleConfirmSubscription()}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Select>
          </FormControl> */}
          <SubscriptionDetailTable columns={subscriptionColumns} data={table2} />
        </>
      ),
    },
    {
      label: "Subjects",
      value: "3",
      body: (
        <>
          {/* <FormControl
            sx={selectFieldStyles}
            className="w-[10%] float-right mb-2"
          >
            <InputLabel
              shrink={false}
              id="demo-simple-select-label"
              style={{ marginBottom: "10px" }}
            >
              Date
            </InputLabel>
            <Select
              open={openSubject}
              onClose={handleCloseSubject}
              onOpen={() => setOpenSubject(true)}
              style={{ height: "53px" }}
              className=""
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              name="chapter"
            >
              <div className="pr-[35px] pl-[35px] pt-[12px]">
                <div>
                  <div className="mb-[8px]">Start Date</div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={startDataSubject}
                      onChange={(newValue) => setStartDateSubject(newValue)}
                      format="YYYY-MM-DD"
                    />
                  </LocalizationProvider>
                </div>
                <div className="pt-[8px]">End Date</div>
                <div className="mt-[8px]">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={endDataSubject}
                      onChange={(newValue) => setEndDateSubject(newValue)}
                      format="YYYY-MM-DD"
                    />
                  </LocalizationProvider>
                </div>
                <div className="">
                  <button
                    className="dialogue-button-text mt-[30px]"
                    onClick={() => handleCloseSubject()}
                  >
                    Cancel
                  </button>
                  <button
                    className="teepee-button  mt-[20px] mb-[17px]"
                    onClick={() => handleConfirmSubject()}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Select>
          </FormControl> */}
          <SubscriptionDetailTable columns={subjectsColumns} data={table3} />
        </>
      ),
    },
  ];
  return (
    <div>
      <Sidebar item_id="tracking_sub" />
      <div className="sm:ml-0 md:ml-[350px] mb-[40px] mr-[1%]">
        <div>
          {" "}
          <label className="dashboard-label  mb-[12px]">
            Subscription Details <div className="teepee-divider"></div>
          </label>
        </div>
        <Tabs id="custom-animation" value={tabState} className="w-[100%] ">
          <div>
            <center>
              <div className="flex">
                <div className="w-[30%] ml-[auto]">
                  <TabsHeader
                    className=" gap-[8px] h-[58px] justify-center bg-[#6750A414]"
                    indicatorProps={{
                      className: "bg-[#6750A414] shadow-none !text-gray-900",
                    }}
                  >
                    {data?.map(({ label, value, icon }) => (
                      <div
                        onClick={() => {
                          localStorage.setItem("tabState", value);
                        }}
                      >
                        <Tab id="ABC" key={value} value={value} icon={icon} className="w-[100%] mr-[20px] ">
                          <div>{label}</div>
                        </Tab>
                      </div>
                    ))}
                  </TabsHeader>
                </div>
              </div>
            </center>
          </div>
          <div className="mt-[9%]">
            <TabsBody animate={{}}>
              {data.map(({ value, body }) => (
                <TabPanel key={value} value={value} className="p-0 overflow-auto">
                  {body}
                </TabPanel>
              ))}
            </TabsBody>
          </div>
        </Tabs>
      </div>
    </div>
  );
}

export default SubscriptionTracking;
