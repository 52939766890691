import React, { useEffect, useRef } from "react";
import MonacoEditor from "@monaco-editor/react";

function CodeEditor({ language = "javascript", theme = "vs-dark", onChange, initialCode = "", value = "", readOnly }) {
  const combinedValue = `${initialCode}\n${value}`;
  const initialRender = useRef(true); // Tracks if the component is on its first render

  // Call onChange once when initialCode is set, if applicable
  useEffect(() => {
    if (initialCode && initialRender.current) {
      onChange(initialCode); // Trigger onChange with initialCode once
      initialRender.current = false; // Mark that initial onChange call has happened
    }
  }, [initialCode, onChange]);

  return (
    <MonacoEditor
      height="300px"
      width="100%"
      language="python"
      theme="vs-dark"
      value={combinedValue}
      options={{
        fontSize: 16,
        minimap: { enabled: false },
        lineNumbers: "on",
        scrollBeyondLastLine: false,
        automaticLayout: true,
        readOnly: readOnly,
      }}
      onChange={readOnly ? undefined : (newValue) => onChange && onChange(newValue)}
    />
  );
}

export default CodeEditor;
