import React, { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Cross from "../../images/cross.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function PaperCategoryDialogue(props) {
  const handleClickBack = () => {
    props.handleClickClose();
  };

  const handleClickContinue = () => {
    var paperCategoryName = document.getElementById("paper_category");
    if (paperCategoryName.value.length <= 0) {
      alert("Please enter paper category");
      return;
    }
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_new_category", {
        method: "POST",
        paper_name: paperCategoryName.value.toLowerCase(),
        subject_id: props.subject_id,
      })
      .then((response) => {
        console.log("Required RESPONSE IS: ", response.data.msg);
        if (response.data.msg === 1) {
          alert("Paper category has been added successfully.");
          props.handleClickClose();
          return;
        }
        if (response.data.msg === 0) {
          alert("Please use another name");
          return;
        }
        props.handleClickClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <Dialog
        PaperProps={{
          sx: {
            width: "768px",
            height: "562px",
            maxWidth: "768px",
            height: "562px",
            maxHeight: "562px",
          },
        }}
        open={props.open}
        onClose={props.handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="flex">
          <button onClick={() => props.handleClickClose()}>
            <img src={Cross}></img>
          </button>
          <div className="text-center w-[100%] create_new_classroom_dialogue_title">
            Add Paper Category
          </div>
        </DialogTitle>
        <hr className="mt-0 "></hr>

        <div>
          <DialogContent style={{ marginLeft: "30px", marginRight: "50px" }}>
            <DialogContentText id="new-classroom-dialog-description">
              <div>
                <label className="create_your_class_label">
                  Enter the name of the paper category
                </label>
              </div>
            </DialogContentText>
            <div className="flex">
              <input
                style={{ marginRight: "20px" }}
                type="text"
                name="paper_category"
                id="paper_category"
                className="class_name_input pl-[23px] md:!w-[549px] sm:!w-[100%]"
                required
              />
            </div>
          </DialogContent>
        </div>
        <hr
          style={{ marginTop: "auto", width: "100%", marginBottom: "0px" }}
        ></hr>

        <DialogActions
          style={{
            marginRight: "15px",
            justifyContent: "space-between",
          }}
        >
          <button
            style={{ width: "60px" }}
            className=" sm:w-[44%] md:w-[46%]"
            onClick={() => handleClickBack()}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-[#6750A4]">Back</div>
            </div>
          </button>
          <button
            style={{ width: "110px" }}
            id="button"
            onClick={handleClickContinue}
            className="teepee-button md:ml-12 hover:bg-black sm:w-[50%] md:w-[46%]"
          >
            Continue
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PaperCategoryDialogue;
