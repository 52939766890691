import React from "react";
import OrderDetailsLineCard from "./OrderDetailsLineCard";
import { useNavigate } from "react-router-dom";

function OrderPlacedCheckout({ subscriptionDetails, data, discountedAmount, value, email, purchaseOrderNumber }) {
  const navigate = useNavigate();
  console.log("ORDER DETAILS: ", subscriptionDetails);
  return (
    <div className="md:ml-[60px] sm:ml-[20px] md:mt-[2%] sm:mt-[3%]">
      <div className="font-bold text-[22px] leading-[28px] mb-1 text-[#1D192B]" style={{ fontFamily: "Roboto" }}>
        Purchase Order Submitted
      </div>
      <div className="font-bold text-[16px] text-[#1D192B] mb-1" style={{ fontFamily: "Roboto" }}>
        Thank You!
      </div>
      <div className="font-medium text-[14px] leading-[20px] text-[#1D192B]" style={{ fontFamily: "Roboto" }}>
        Your purchase order has been successfully submitted. Please email the PO document to{" "}
        <a href="mailto:support@highersummit.com" class="text-[#1555F9] underline ml-2">
          support@highersummit.com
        </a>{" "}
        for processing.
      </div>
      <div className="font-bold text-[22px] leading-[28px] mb-[12px] text-[#1D192B] mt-[35px]" style={{ fontFamily: "Roboto" }}>
        Next Steps:
      </div>
      <div class="text-[14px] leading-[20px] font-roboto">
        <ul class="list-disc ml-2 space-y-[12px]">
          <li>
            <span class="font-bold">Invoice Sent:</span> An invoice will be sent to your school's finance department for processing.
          </li>
          <li>
            <span class="font-bold">Access Granting:</span> Your students will gain access to the platform once the purchase order is processed and
            confirmed.
          </li>
          <li>
            <span class="font-bold">Support:</span> If you need any assistance, please contact our support team at
            <a href="mailto:support@highersummit.com" class="text-[#1555F9] underline ml-2">
              support@highersummit.com
            </a>
            .
          </li>
        </ul>
      </div>

      <div className="flex mt-[2%] ">
        {purchaseOrderNumber.value && <OrderDetailsLineCard heading="ORDER NUMBER: " body={purchaseOrderNumber.value} shoBorder={true} />}
        <OrderDetailsLineCard heading="DATE: " body={new Date().toDateString().split(" ").slice(1, 4).join(" ")} shoBorder={true} />
        <OrderDetailsLineCard heading="EMAIL: " body={email.value} shoBorder={true} />
        <OrderDetailsLineCard heading="TOTAL: " body={`£${discountedAmount.toFixed(2)}`} shoBorder={true} />
        <OrderDetailsLineCard heading="PAYMENT METHOD: " body={value !== "credit" ? "Purchase Order" : "Credit Card"} shoBorder={false} />
      </div>
      <div className="mt-[2%] sm:ml-[10px] md:ml-[0px] mb-4">
        <div className="w-[90%] subscription-card-box-shadow rounded-xl	">
          <div className="pt-3 pl-4 pb-2 font-bold text-[24px]">Order Details</div>
          <div style={{ border: "1px solid #CAC4D0" }}></div>
          <div className="flex">
            <div className="p-3 w-[70%] font-bold text-[18px]" style={{ borderRight: "1px solid #CAC4D0" }}>
              Product:
            </div>
            <div className="w-[30%] text-center items-center flex justify-center font-extrabold	text-[18px]">Total</div>
          </div>
          <div style={{ border: "1px solid #CAC4D0" }}></div>
          <div className="flex">
            <div className="p-3 w-[70%]" style={{ borderRight: "1px solid #CAC4D0" }}>
              <div className=" font-semibold text-[18px] text-[#6750A4]">{`${data.subjectName} x ${data.noOfStudent}`}</div>
              <div className="flex">
                <div className="font-semibold text-[18px]">Group Title: </div>
                <div className="ml-[1%] text-[18px]">{data.subjectName}</div>
              </div>
            </div>
            <div className="w-[30%] text-center items-center flex justify-center text-[18px]">{"£" + data.vouchersRate}</div>
          </div>
          <div style={{ border: "1px solid #CAC4D0" }}></div>
          <div className="flex">
            <div className="p-3 w-[70%] font-bold text-[18px]" style={{ borderRight: "1px solid #CAC4D0" }}>
              Subtotal:
            </div>
            <div className="w-[30%] text-center items-center flex justify-center text-[18px]">{"£" + data.vouchersRate}</div>
          </div>
          <div style={{ border: "1px solid #CAC4D0" }}></div>
          <div className="flex">
            <div className="p-3 w-[70%] font-bold text-[18px]" style={{ borderRight: "1px solid #CAC4D0" }}>
              VAT:
            </div>
            <div className="w-[30%] text-center items-center flex justify-center text-[18px]">£{((data.vouchersRate * 20) / 100).toFixed(2)}</div>
          </div>
          <div style={{ border: "1px solid #CAC4D0" }}></div>
          <div className="flex">
            <div className="p-3 w-[70%] font-bold text-[18px]" style={{ borderRight: "1px solid #CAC4D0" }}>
              Payment Method
            </div>
            <div className="w-[30%] text-center items-center flex justify-center font-extrabold	text-[18px]">
              {value !== "credit" ? "Purchase Order" : "Credit Card"}
            </div>
          </div>
          <div style={{ border: "1px solid #CAC4D0" }}></div>
          <div className="flex">
            <div className="p-3 w-[70%] font-bold text-[18px]" style={{ borderRight: "1px solid #CAC4D0" }}>
              Total
            </div>
            <div className="w-[30%] text-center items-center flex justify-center text-[18px]">£{discountedAmount.toFixed(2)}</div>
          </div>
        </div>
      </div>
      <div className="w-[90%] flex justify-between  mb-4">
        <button className="teepee-back-button " onClick={() => navigate("/subscription")}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>Back</div>
          </div>
        </button>
        <button id="button" onClick={() => navigate("/")} className="teepee-button ">
          Go to Home
        </button>
      </div>
    </div>
  );
}

export default OrderPlacedCheckout;
