import React, { useState } from "react";
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from "@material-tailwind/react";
import StudentDashbaordTabBody from "./widget/StudentDashbaordTabBody";
import StudentScoreTabBody from "./widget/StudentScoreTabBody";
import StudentMasteryLevelTabBody from "./widget/StudentMasteryLevelTabBody";

function StudentClassroomTabs(props) {
  console.log("DATA SHOULD BE: ", props.doneThemRetrieval);
  const [tabState, setTabState] = useState(1);
  const data = [
    {
      label: "Dashboard",
      value: 1,
      body: (
        <StudentDashbaordTabBody
          activeTab={props.activeTab}
          doThese={props.doThese}
          viewQuiz={props.viewQuiz}
          doneThem={props.doneThem}
          handleOnClickDoneThemQuiz={props.handleOnClickDoneThemQuiz}
          doTheseRetrieval={props.doTheseRetrieval}
          viewQuizRetrieval={props.viewQuizRetrieval}
          doneThemRetrieval={props.doneThemRetrieval}
        />
      ),
    },
    {
      label: "Score",
      value: 2,
      body: <>{tabState === 1 ? "" : <StudentScoreTabBody quizzes={props.doneThem} handleOnClickDoneThemQuiz={props.handleOnClickDoneThemQuiz} />}</>,
    },
    {
      label: "Mastery Levels",
      value: 3,
      body: <>{tabState === 1 && tabState === 2 ? "" : <StudentMasteryLevelTabBody masteryLevels={props.masteryLevels} />}</>,
    },
  ];

  const handleTabState = (value) => {
    setTabState(value);
  };
  return (
    <div>
      <Tabs id="custom-animation" value={tabState} className="w-[100%] ">
        <div>
          <center>
            <div className="flex">
              <div className="classroom_heading mt-[30px]" style={{ display: tabState === 1 ? "block" : "none" }}></div>
              <div className="md:w-[30%] ml-[auto]">
                <TabsHeader
                  className="w-[100%] h-[58px] hidden justify-center bg-[#6750A414]"
                  indicatorProps={{
                    className: "bg-[#6750A414] shadow-none !text-gray-900",
                  }}
                >
                  {data.map(({ label, value, icon }) => (
                    <div
                      // className="w-[100%] "
                      onClick={() => handleTabState(value)}
                    >
                      <Tab key={value} value={value} icon={icon} className="md:w-[100%]">
                        <div>{label}</div>
                      </Tab>
                    </div>
                  ))}
                </TabsHeader>
              </div>
            </div>
          </center>
        </div>

        <TabsBody animate={{}} className="w-[100%]">
          {data.map(({ value, body }) => (
            <TabPanel key={value} value={value} className="md:p-0 ">
              {body}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  );
}

export default StudentClassroomTabs;
