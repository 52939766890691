import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
function Users() {
  const columns = [
    {
      id: "email_address",
      label: "Email",
      minWidth: 170,
      maxWidth: 200,
      align: "center",
    },
    {
      id: "password",
      label: "Password",
      minWidth: 170,
      maxWidth: 200,
      align: "center",
    },
    {
      id: "first_name",
      label: "First Name",
      minWidth: 170,
      maxWidth: 200,
      align: "center",
    },
    {
      id: "last_name",
      label: "Last Name",
      minWidth: 170,
      maxWidth: 200,
      align: "center",
    },
    {
      id: "account_auth_type",
      label: "Opt-in Type",
      minWidth: 170,
      maxWidth: 200,
      align: "center",
    },
    {
      id: "created_timestamp",
      label: "Creation Date",
      minWidth: 100,
      maxWidth: 150,
      align: "center",
    },
    {
      id: "last_login_date",
      label: "Last Login Date",
      minWidth: 100,
      maxWidth: 150,
      align: "center",
    },
    {
      id: "last_quiz_creation_date",
      label: "Last Quiz Creation Date",
      minWidth: 170,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "last_attempted_quiz_date",
      label: "Last Attempted Quiz Date",
      minWidth: 170,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "creation_tokens_count",
      label: "Token Usage (Creation)",
      minWidth: 170,
      maxWidth: 200,
      align: "center",
    },
    {
      id: "evaluation_tokens_count",
      label: "Token Usage (Evaluation)",
      minWidth: 170,
      maxWidth: 200,
      align: "center",
    },
  ];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [rows, setRows] = useState([]);
  const [createUser, setCreateUser] = useState({
    email_address: "",
    password: "",
    user_type: "",
  });
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  useEffect(() => {
    if (userType === "1") {
      navigate("/");
      return;
    }
  }, [navigate, userType]);

  const onChange = (e) => {
    setCreateUser({ ...createUser, [e.target.name]: e.target.value });
  };
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredRows = rows.filter((row) => {
    return row.email_address.toLowerCase().includes(searchText.toLowerCase());
  });

  const getWebUsers = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_web_users",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
      })
      .then((res) => {
        var web_users = res.data.web_users;
        console.log(web_users);
        setRows(web_users);
      });
  };

  useEffect(() => {
    getWebUsers();
  }, []);

  const isSubmitting = useRef(false);
  function submitForm() {
    var token = "Bearer " + localStorage.getItem("access_token");
    var firstname = document.getElementById("firstname").value;
    var lastname = document.getElementById("lastname").value;
    var email_address = document.getElementById("email_address").value;
    var password = document.getElementById("password").value;
    var user_type = document.getElementById("user_type_dropdown").value;

    //const sub_topic_id_list = [1, 2]
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_new_user", {
        method: "POST",
        first_name: firstname,
        last_name: lastname,
        email: email_address,
        password: password,
        user_type: user_type,
      })

      .then((response) => {
        console.log(response);

        isSubmitting.current = false;

        alert("Account created successfully");
        getWebUsers();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseWithCancel = () => {
    setOpen(false);
    isSubmitting.current = false;
  };

  const handleCloseWithYes = () => {
    setOpen(false);
    submitForm();
  };

  const handleDelateOptionClick = (event) => {
    //setUserMessage(param1)
    //setPrompt(param2)
    var email_address = document.getElementById("email_address").value;
    var password = document.getElementById("password").value;
    if (email_address.length > 0 && password.length > 0) {
    } else {
      alert("Please enter all details");
      // resolve();
      return;
    }
    setOpen(true);
  };

  const updateUserAccountStatus = (status, web_user_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL +
          "/update_web_user_account_status",
        {
          method: "POST",
          web_user_id: web_user_id,
          status: status,
        }
      )

      .then((response) => {
        alert("Subject Status changed");
        getWebUsers();
      })
      .catch((error) => {
        console.log(error);
        alert("unable to update web user status");
      });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseWithCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Confirm</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to create this account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>NO</Button>
          <Button
            className="delete-button"
            onClick={handleCloseWithYes}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Sidebar item_id="user_option" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px] ">
        <div className="home sm:ml[25px] md:ml-[60px]">
          <div>
            <label className="dashboard-label">Add New User</label>
            <div className="teepee-divider"></div>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center ">
          <div className=""></div>
          <div className="selectionDiv">
            <div className="form-div">
              <div className="md:w-[600px]">
                <div>
                  <label className="create_quiz_label">Enter first name</label>
                </div>
                <div>
                  <input
                    type="text"
                    name="firstname"
                    onChange={onChange}
                    id="firstname"
                    className="teepee-input"
                    placeholder=""
                    required
                  />
                </div>
                <div>
                  <label className="create_quiz_label">Enter last name</label>
                </div>
                <div>
                  <input
                    type="text"
                    name="lastname"
                    onChange={onChange}
                    id="lastname"
                    className="teepee-input"
                    placeholder=""
                    required
                  />
                </div>
                <div>
                  <label className="create_quiz_label">
                    Enter user email address
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    name="email_address"
                    onChange={onChange}
                    id="email_address"
                    className="teepee-input"
                    placeholder=""
                    required
                  />
                </div>

                <div>
                  <label className="create_quiz_label">Password</label>
                </div>
                <div>
                  <input
                    type="text"
                    name="password"
                    onChange={onChange}
                    id="password"
                    className="teepee-input"
                    placeholder=""
                    required
                  />
                </div>

                <div>
                  <label className="create_quiz_label">Role.</label>
                </div>
                <div>
                  <div className="">
                    <select
                      id="user_type_dropdown"
                      onChange={onChange}
                      name="user_type"
                      className="teepee-select shadow-none leading-[30px]  mb-5 font-[300] px-4 w-[100%] h-[100%] bg-transparent"
                    >
                      <option value="1">Client</option>
                      <option value="2">Admin</option>
                    </select>
                  </div>
                </div>
                <div>
                  {/* <button
                    onClick={handleClick}
                    className="teepee-button hover:bg-black"
                  >
                    Create New User
                  </button> */}

                  <button
                    onClick={handleDelateOptionClick}
                    disabled={isSubmitting.current}
                    className="teepee-button hover:bg-black"
                    style={{ width: "46%" }}
                  >
                    {isSubmitting.current && (
                      <div
                        className="spinner-border spinner-border-m float-left"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    Create User
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:ml-[10px] md:ml-[60px] sm:mr-[10px] md:mr-[60px] mt-[28px]">
          <input
            type="text"
            placeholder="Search by email"
            value={searchText}
            className="teepee-input mt-[24px] searching-icon"
            onChange={handleSearchChange}
          />
        </div>
        <div className="flex flex-row justify-left items-center sm:ml-[10px] md:ml-[60px] mt-[20px] sm:mr-[10px] md:mr-[60px]">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 840 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#f7f2f9",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell
                      key={"action"}
                      align={"center"}
                      style={{ minWidth: "50px", backgroundColor: "#f7f2f9" }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                style={{
                                  minWidth:
                                    column.id === "email_address"
                                      ? "300px"
                                      : column.minWidth,
                                  maxWidth: column.maxWidth,
                                  overflowWrap: "break-word",
                                  borderLeft:
                                    "1px solid rgba(224, 224, 224, 1)",
                                }}
                                key={column.id}
                                align={
                                  column.id === "email_address" ||
                                  column.id === "password"
                                    ? "left"
                                    : "center"
                                }
                              >
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                          <TableCell
                            style={{
                              minWidth: 170,
                              maxWidth: 170,
                              overflowWrap: "break-word",
                              borderLeft: "1px solid rgba(224, 224, 224, 1)",
                            }}
                            key={"action"}
                            align={"center"}
                          >
                            {row["status"] === 0 && (
                              <button
                                onClick={() =>
                                  updateUserAccountStatus(1, row["web_user_id"])
                                }
                                className="btn btn-danger"
                              >
                                Activate
                              </button>
                            )}
                            {row["status"] === 1 && (
                              <button
                                onClick={() =>
                                  updateUserAccountStatus(0, row["web_user_id"])
                                }
                                className="btn btn-danger"
                              >
                                De-Activate
                              </button>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={{ backgroundColor: "#f7f2f9" }}
              rowsPerPageOptions={[100, 150, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </>
  );
}

export default Users;
