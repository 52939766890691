import React from "react";
import SortableItem from "./SortableItem";
import { SortableContainer } from "react-sortable-hoc";

function SortableList({
  items,
  onSortEnd,
  onSaveSubject,
  sendDeleteRequest,
  isVideo,
}) {
  return (
    <div>
      {items !== null &&
        items.map((subject, index) => (
          <SortableItem
            key={`item-${index}`}
            isVideo={isVideo}
            index={index}
            value={isVideo ? subject.video_links : subject.subject_name}
            onSaveSubject={onSaveSubject}
            subject={subject}
            sendDeleteRequest={sendDeleteRequest}
          />
        ))}
    </div>
  );
}

export default SortableContainer(SortableList);
