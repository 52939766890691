import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Edit from "../../../images/edit-student.png";
import DeleteStudent from "../../../images/delete-student.png";
import axios from "axios";
function ManageSubjectStudentTable({ subjectStudents, handleChange, changeStudentEnrollmentStatus, handleOpen, bannerObject }) {
  const handleClickChangeStudentEnrollmentStatus = (student, index, event) => {
    console.log("STUDENT: ", student);
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/change_enrollment_status_of_student", {
        method: "POST",
        student_id_by_teacher: student.student_id,
        class_id: null,
        status: parseInt(event.target.value),
        subject_id: bannerObject.subject_id,
        account_status: bannerObject.account_status,
      })
      .then((response) => {
        handleChange(event);
        changeStudentEnrollmentStatus();
      })
      .catch((error) => {
        console.log(error);
        alert("failed to delete student");
      });
  };
  return (
    <table class="w-full text-sm text-left text-white dark:text-white mb-[160px]">
      <thead class="text-xs text-white  bg-[#6750A4] dark:bg-white dark:text-white">
        <tr>
          <th scope="col" class="px-6 py-3">
            ID
          </th>
          <th scope="col" class="px-6 py-3">
            Name
          </th>
          <th scope="col" class="px-6 py-3">
            Email
          </th>
          <th
            scope="col"
            class="px-6 py-3"
            style={
              {
                //   borderRight: props.selectedQuizType !== 1 && "1px solid white",
              }
            }
          >
            Joining Date
          </th>

          <th scope="col" class="px-6 py-3">
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        {subjectStudents !== null &&
          subjectStudents.map((student, index) => {
            return (
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {index + 1}
                </th>
                <td class="px-6 py-4 max-w-xs text-with-dots-without-width  ">{student.student_name}</td>
                <td class="px-6 py-4">{student.student_email}</td>
                <td class="px-6 py-4">{student.classroom_joined_timestamp}</td>
                <td class="px-6 py-4">
                  <Dropdown className="w-full h-full mb-[12%]">
                    <Dropdown.Toggle id="profile-dropdown" className="w-full">
                      <div className={`${student.student_status === 1 ? "bg-[#62C370]" : "bg-[#DC362E]"} w-fit	 rounded text-white`}>
                        <div className="flex justify-between">
                          <div className="ml-2 flex items-center p-2">{student.student_status === 1 ? "Enrolled" : "Disabled"}</div>
                          <button className="bg-white mr-[2px] mt-[3px] mb-[3px] rounded-r flex items-center p-2 w-max	">
                            <img style={{ width: "14px" }} src={Edit} />
                          </button>
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="!bg-[#F3EDF8] !pb-0">
                      <div className="p-3 w-[200px]">
                        <div className="mb-[10%]">
                          <label className="flex">
                            <input
                              type="radio"
                              value={1}
                              checked={student.student_status === 1}
                              onChange={(e) => handleClickChangeStudentEnrollmentStatus(student, index, e)}
                            />
                            <div className="ml-[10%] font-medium	">Assign License</div>
                          </label>
                          <br />
                        </div>
                        <div>
                          <label className="flex">
                            <input
                              type="radio"
                              value={2}
                              checked={student.student_status === 2}
                              onChange={(e) => handleClickChangeStudentEnrollmentStatus(student, index, e)}
                            />
                            <div className="ml-[10%] font-medium	">Disable License</div>
                          </label>
                        </div>
                      </div>

                      <Dropdown.Divider className="m-0" />
                      <Dropdown.Item onClick={() => handleOpen(student, index)}>
                        <div className="flex mt-[5%] mb-[5%]">
                          <div className="flex items-center">
                            <img src={DeleteStudent} />
                          </div>
                          <div className="ml-[12%] text-[#DC362E] " onClick={() => handleOpen(student, index)}>
                            Delete Student
                          </div>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}

export default ManageSubjectStudentTable;
