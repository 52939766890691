import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, tooltipClasses } from "@mui/material";
import React from "react";
import Cross from "../../../images/cross.png";
import { styled } from "@mui/material/styles";

const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});
function QuizCopyConfirmationDialogue(props) {
  const title = props.title;
  const dialogueText = props.text;
  const handleCloseWithConfirm = props.handleCloseWithConfirm;
  const handleCloseWithCancel = props.handleCloseWithCancel;
  const open = props.open;

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "370px",
          height: "224px",
        },
      }}
      open={open}
      onClose={handleCloseWithCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        <button className="float-right" onClick={() => props.handleCloseWithCancel()}>
          <img src={Cross}></img>
        </button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialogueText}</DialogContentText>
      </DialogContent>
      <DialogActions className="mb-[20px]">
        <Tooltip placement="top" title={"Save the copied task in drafts for future use"}>
          <button className="dialogue-button-text mr-[51px]" onClick={() => handleCloseWithConfirm(2)}>
            Add Drafts
          </button>
        </Tooltip>
        <CustomWidthTooltip style={{ maxWidth: "500px" }} placement="top" title={"This will immediately share the copied task with your students"}>
          <button className="teepee-button mr-[20px] " onClick={() => handleCloseWithConfirm(1)}>
            Share Task
          </button>
        </CustomWidthTooltip>
      </DialogActions>
    </Dialog>
  );
}

export default QuizCopyConfirmationDialogue;
