import React from "react";

const ShimmerEffect = () => {
  return (
    <div className="shimmer-wrapper rounded">
      <div className="shimmer"></div>
    </div>
  );
};

export default ShimmerEffect;
