import React from "react";
import trophy_icon from "../../../images/trophy.svg";
import checked from "../../../images/green_check.svg";
import rounded_trophy from "../../../images/rounded_trophy.svg";
const Stepper = ({ steps, currentStep }) => {
  console.log("current step", currentStep);
  const selected_step = currentStep === 1 ? [] : currentStep === 2 ? [1] : currentStep === 3 ? [1, 2] : currentStep === 4 ? [1, 2, 3] : [];
  return (
    <div className="relative flex items-center justify-between w-full my-4">
      {/* Background Line */}
      <div className="absolute top-1/2 left-0 w-full h-0.5 bg-gray-300" />

      {steps.map((step, index) => (
        <div key={index} className="relative z-10 flex items-center">
          <div
            className={`w-7 h-7 flex items-center justify-center rounded-full border-2 
                        ${selected_step[index] === index + 1 ? "hidden border-[#49454FB8] text-[#49454FB8]" : "border-[#49454FB8] text-[#49454FB8]"}
                        font-semibold bg-white`}
          >
            {index < 2 ? index + 1 : <img className="h-8 w-8" src={trophy_icon} />}
          </div>
          <div
            className={`w-7 h-7 flex items-center justify-center rounded-full border-2 
                        ${selected_step[index] === index + 1 ? " " : "hidden border-[#49454FB8] text-[#49454FB8]"}
                        font-semibold bg-white`}
          >
            {index < 2 ? <img className="h-7 w-7" src={checked} /> : <img className="h-10 w-10" src={rounded_trophy} />}
          </div>
          {/* Connecting Line with dynamic width based on completion */}
          {index < steps.length - 1 && (
            <div className={`flex-1 h-0.5 mx-2 ${index < selected_step[index] - 1 ? "bg-[#49454F6B]" : "bg-[#49454F6B]"}`} />
          )}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
