import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

function PaymentRadioButtons({ value, handleChange }) {
  return (
    <FormControl>
      <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={value} onChange={handleChange}>
        {/* <FormControlLabel
          value="credit"
          control={
            <Radio
              size="extra-small"
              sx={{
                "&.Mui-checked": {
                  color: "#6750A4",
                },
              }}
            />
          }
          label="Credit Card (Stripe)"
        /> */}
        <FormControlLabel
          value="purchase"
          control={
            <Radio
              size="extra-small"
              sx={{
                "&.Mui-checked": {
                  color: "#6750A4",
                },
              }}
            />
          }
          label="Purchase Order"
        />
      </RadioGroup>
    </FormControl>
  );
}

export default PaymentRadioButtons;
