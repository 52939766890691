import React, { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import infoLogo from "../../images/info.png";
import BackArrow from "../../images/arrow_back_ios_new.png";
import AddToQuiz from "../../images/addquiz.png";
import Hand from "../../images/hand.png";
import Cross from "../../images/cross.png";

function BlacklistedQuestionDialogue(props) {
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState([]);

  const checkCategory = (questionMarks) => {
    let condition = 0;
    if (questionMarks >= 1 && questionMarks <= 3) {
      return (condition = 1);
    } else if (questionMarks >= 4 && questionMarks <= 7) {
      return (condition = 2);
    } else if (questionMarks >= 8) {
      return (condition = 3);
    }
  };

  const handleClick = (index, rowData) => () => {
    let questionToReplace = checkCategory(props.selectedQuestionForReplacement.question_marks);
    let questionToReplaceWith = checkCategory(rowData.question_marks);
    if (questionToReplace !== questionToReplaceWith) {
      alert(
        `You can only replace with ${
          checkCategory(props.selectedQuestionForReplacement.question_marks) === 1
            ? "1-3"
            : checkCategory(props.selectedQuestionForReplacement.question_marks) === 2
            ? "4-7"
            : "8"
        } marks question`
      );
      return;
    }
    setOpen(true);
    setRowData(rowData);
  };

  const handleCloseWithCancel = () => {
    setOpen(false);
  };
  const handleCloseWithYes = () => {
    props.handleCloseNoQuestionDialogue();
    props.replaceQuestion(props.questionIndex, rowData);
    setOpen(false);
    props.handleCloseParentDialogue(false);
  };
  const handleCrossWithCrossButton = () => {
    props.handleClose();
    props.handleCloseReplaceQuestionDialogue();
    props.handleCloseParentDialogue();
  };

  useEffect(() => {}, []);
  return (
    <div>
      <Dialog open={open} onClose={handleCloseWithCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <b>Confirm</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to add this question?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>NO</Button>
          <Button className="delete-button" onClick={handleCloseWithYes} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        PaperProps={{
          sx: {
            width: "850px",
            maxWidth: "900px",
            height: "690px",
            maxHeight: "700px",
          },
        }}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ display: "flex", marginTop: "10px" }}>
          <button className=" mt-1" onClick={props.handleClose}>
            <img src={BackArrow}></img>
          </button>
          <div className="text-center w-[100%] flex justify-center">
            {"Select your favourite question"}
            {props.response_data.length !== 0 ? <img src={Hand}></img> : ""}
          </div>
          <button onClick={() => handleCrossWithCrossButton()}>
            <img src={Cross}></img>
          </button>
        </DialogTitle>
        <hr className="mr-[30px] ml-[30px]"></hr>
        <DialogContent>
          {(props.selectedQuizType === 1 || props.selectedQuizType === 3 || props.mock_exam === null) &&
            props.response_data?.map((rowData, index) => (
              <Fragment key={index}>
                <div className="md:w-[800px]">
                  <div
                    style={{
                      padding: "10px",
                      borderRadius: "5px",
                      width: "100%",
                      marginBottom: "20px",
                      backgroundColor: "#F0F0F0",
                    }}
                  >
                    <p
                      className=" flex answer-paragraph"
                      style={{
                        fontSize: "16px",
                        fontFamily: "Roboto",
                        color: "#49454F",
                      }}
                    >
                      <b>Question {index + 1}: </b>
                      <p className="w-[78%]" dangerouslySetInnerHTML={{ __html: rowData.question }}></p>
                      <b> [Marks: {rowData.question_marks}]</b>
                    </p>
                    {rowData.image_file_name !== null && (
                      <div className="flex justify-center mb-[20px] mt-[20px]">
                        <img alt="" className="" src={process.env.REACT_APP_CDN_URL_FOR_QUESTION_IMAGES + rowData.image_file_name}></img>
                      </div>
                    )}
                    <p
                      className="answer-paragraph"
                      style={{
                        fontSize: "16px",
                        fontFamily: "Roboto",
                        color: "#49454F",
                        display: props.isAnswerRubric === 1 ? "none" : "",
                      }}
                    >
                      <b>Answer: </b>
                      {rowData.answer.split("Rubric:")[0]}
                    </p>

                    {props.selectedQuizType !== 1 && rowData.answer.split("Rubric:").length > 1 && (
                      <p
                        className="answer-paragraph"
                        style={{
                          fontSize: "16px",
                          fontFamily: "Roboto",
                          color: "#49454F",
                          display: props.isAnswerRubric === 1 ? "none" : "",
                        }}
                      >
                        <b>Rubric: </b>
                        {rowData.answer.split("Rubric:")[1]}
                      </p>
                    )}
                  </div>
                  {/* <button
                    onClick={removeQuestion}
                    value={index}
                    style={{ height: "50px", float: "right", fontSize: "14px" }}
                    className="bg-[#edeaf4] text-[#6750A4]  px-4 "
                  >
                     <span className="spinner-grow spinner-grow-sm"></span>
                    Remove
                  </button> */}

                  {/* <button
                        type="button"
                        className="close bg-[#edeaf4] text-[#6750A4]  px-4"
                        aria-label="Close"
                        onClick={() => removeQuestion(index)}
                        value={index}
                        style={{
                          display: id != null ? "none" : "block",
                          height: "50px",
                          float: "right",
                          fontSize: "16px",
                          borderRadius: "5px",
                        }}
                        // className=" "
                      >
                        <span className="pr-[10px]" aria-hidden="true">
                          &times;
                        </span>
                        Remove
                      </button> */}
                  <button
                    data-modal-target="defaultModal"
                    type="button"
                    className="close bg-[#edeaf4] text-[#6750A4]  px-4 mr-[10px]"
                    aria-label="Close"
                    // onClick={() => <ReplaceDialogue />}
                    onClick={handleClick(index, rowData)} // Pass index and rowData here                        value={index}
                    value={index}
                    style={{
                      height: "50px",
                      float: "right",
                      fontSize: "16px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    // className=" "
                  >
                    <span className="pr-[10px]" aria-hidden="true">
                      <img src={AddToQuiz}></img>
                    </span>
                    Add to quiz
                  </button>

                  <div
                    className="close bg-[white] text-[black]  px-4 mr-[100px] pt-[12px] flex"
                    aria-label="Close"
                    style={{
                      height: "50px",
                      float: "left",
                      fontSize: "16px",
                      borderRadius: "5px",
                      width: "250px",
                      border: "1px solid black",
                    }}
                    // className=" "
                  >
                    <span className="pr-[10px] text-[#6750A4] subtopic_name" aria-hidden="true" style={{ width: "170px" }}>
                      {rowData.sub_topic_name}
                    </span>
                    <div className="mt-[3px]">
                      <img src={infoLogo}></img>
                    </div>{" "}
                    {/* {sub_topic_name.current[index]} */}
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    height: "1px",
                    backgroundColor: "#B3B3B3",
                    marginTop: "100px",
                    marginBottom: "10px",
                  }}
                />
              </Fragment>
            ))}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export default BlacklistedQuestionDialogue;
