import axios from "axios";
import {
  REACT_APP_REST_API_BASE_URL,
  REACT_APP_GET_PROMOCODE_END_POINT,
} from "../../Constants/ApiConstant";
const useGetPromoCode = () => {
  const getPormoCode = (callback) => {
    var access_token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = access_token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL +
          REACT_APP_GET_PROMOCODE_END_POINT,
        {
          method: "POST",
        }
      )
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return getPormoCode;
};

export default useGetPromoCode;
