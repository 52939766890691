import React from "react";
import { useNavigate } from "react-router-dom";
import amplitude from "amplitude-js";
const TrialMocks = (props) => {
  const navigate = useNavigate();

  const onhandleClick = () => {
    if (props.experience === "Teacher") {
      amplitude.getInstance().logEvent("TETryMiniMockClk");
      navigate(`/quiz?id=${props.minimocks[0].secret_key}`, {
        state: {
          quiz_setting: 1,
          subject_name: props.subject,
          experience: props.experience,
        },
      });
    } else {
      amplitude.getInstance().logEvent("VETryMiniMockClk");
      navigate(`/quiz?id=${props.minimocks[0].secret_key}`, {
        state: { quiz_setting: 1, subject_name: props.subject },
      });
    }
  };

  // Render the div if props.minimocks is provided
  return (
    <div>
      <p
        style={{
          color: "#212529",
          fontFamily: "Roboto",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "24px", // This is typically a unitless value in React inline styles
          letterSpacing: "0.1px",
        }}
      >
        Or
      </p>
      <button
        onClick={onhandleClick}
        style={{
          color: "#6750A4",
          fontFamily: "Roboto",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "24px", // This is typically a unitless value in React inline styles
          letterSpacing: "0.1px",
        }}
      >
        {" "}
        Try a Mini Mock
      </button>
    </div>
  );
};

export default TrialMocks;
