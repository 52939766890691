import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import flame_icon from "../../../images/flame.svg";
import streak_icon from "../../../images/streak.svg";
import { DateRange } from "react-date-range";
import { enUS } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const StreakPopUp = ({ isOpen, onClose, streakCount, streakDates }) => {
  // Function to set initial range based on streakDates
  const getInitialRange = () => {
    if (streakDates) {
      if (streakDates?.length === 0) {
        const date = new Date();
        return [{ startDate: date, endDate: date, key: "selection" }];
      } else if (streakDates?.length === 1) {
        const date = new Date(streakDates[0]);
        return [{ startDate: date, endDate: date, key: "selection" }];
      } else {
        const startDate = new Date(streakDates[0]);
        const endDate = new Date(streakDates[streakDates?.length - 1]);
        return [{ startDate, endDate, key: "selection" }];
      }
    } else {
      const date = new Date();
      return [{ startDate: date, endDate: date, key: "selection" }];
    }
  };

  const [range, setRange] = useState(getInitialRange());

  // Update range if streakDates changes
  useEffect(() => {
    setRange(getInitialRange());
  }, [streakDates]);

  if (!isOpen) return null;

  return (
    <div style={{ zIndex: "1000" }} className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white w-full max-w-md pt-6 pb-6 rounded-lg shadow-lg relative">
        {/* Close Button */}
        <div className="flex justify-between items-center mb-1 pr-6 pl-6">
          <button className="text-gray-600 hover:text-gray-800" onClick={onClose}>
            <AiOutlineClose size={20} />
          </button>
          <h2 className="text-lg font-semibold text-gray-800 mb-0">Streak</h2>
          <div className="flex items-center text-gray-800">
            <img className="w-5 h-5 mr-1" src={streak_icon} alt="Streak Icon" />
            <span>{streakCount}</span>
          </div>
        </div>

        <div className="mb-1 w-full h-[1px] bg-gray-200"></div>

        {/* Streak Display */}
        <div className="flex justify-between items-center bg-[#2C90FC] text-white p-12 mb-4">
          <div className="text-center">
            <span className="text-[57px] leading-[64px] text-[#F6F7FB] text-stroke font-bold">{streakCount}</span>
            <p className="text-lg font-roboto font-bold">day streak!</p>
          </div>
          <img src={flame_icon} alt="Flame Icon" className="w-[140px] h-[100px] ml-4" />
        </div>

        {/* Date Range Calendar */}
        <div className="p-2 w-full flex items-center justify-center">
          <DateRange
            editableDateInputs={false}
            onChange={(item) => setRange([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={1}
            direction="horizontal"
            locale={enUS}
            showDateDisplay={false}
          />
        </div>
      </div>
    </div>
  );
};

export default StreakPopUp;
