import React, { useState } from "react";
import ReactSwitch from "react-switch";
import IncrementDecrementCounter from "./IncrementDecrementCounter";
import QuizFeedbackAccordian from "./QuizFeedbackAccordian";

function QuizSetting({
  quizDuration,
  handleChange,
  quizTiming,
  onIncDecClickQuizDuration,
  graceDurationToggle,
  graceDuration,
  onIncDecClickQuizGraceDuration,
  autoSubmissionToggle,
  hideAnswerToggle,
  configureToggle,
  shuffleQuestionToggle,
  quizType,
  AIQuestionToggle,
}) {
  const handleText = () => {
    console.log("QUIZ TYPE", quizType);
    if (quizType === 1) {
      return "Configure as Default for Formative Type Quiz";
    } else if (quizType === 2) {
      return "Configure as Default for Mini Mock Type Quiz";
    } else if (quizType === 3) {
      return "Configure as Default for Exam Style Type Quiz";
    }
  };
  return (
    <div>
      <div className="bg-[#FEF7FF]" style={{ border: "1px solid #79747E", borderRadius: "12px" }}>
        <div className="flex ">
          <div className="w-[50%] p-[15px] pl-[20px]">
            <div className="disabled-label" style={{ fontSize: "16px" }}>
              Task Duration
            </div>
            <div className="classroom_details_label">Set the total time allowed for task completion</div>
          </div>
          <div className="w-[50%] pt-[10px] mr-[20px]">
            <div className="classroom_name_div ">
              <div className="classroom_details_label flex float-right">
                <ReactSwitch className="float-right" checked={quizDuration} onChange={(value) => handleChange(1, value)} />
              </div>
            </div>
            <div
              className="classroom_details_label mt-[10px] float-right p-2 bg-white"
              style={{
                border: "1px solid #79747E",
                borderRadius: "12px",
                display: quizDuration ? "block" : "none",
              }}
            >
              <IncrementDecrementCounter marks={quizTiming} onIncDecClick={onIncDecClickQuizDuration} label="" uper_limit={150} lower_limit={5} />
            </div>
          </div>
        </div>

        <div className="flex mt-[20px]" style={{ display: quizDuration ? "flex" : "none" }}>
          <div className="w-[50%] p-[15px] pl-[20px]">
            <div className="disabled-label" style={{ fontSize: "16px" }}>
              Submission grace period
            </div>
            <div className="classroom_details_label">Allow extra time post task duration</div>
          </div>
          <div className="w-[50%] pt-[10px] ">
            <div className="classroom_name_div ">
              <div className="classroom_details_label flex float-right mr-[20px]">
                <ReactSwitch className="float-right" checked={graceDurationToggle} onChange={(value) => handleChange(2, value)} />
              </div>
            </div>
            <div
              className="classroom_details_label mt-[10px] float-right p-2 mr-[20px] bg-white"
              style={{
                border: "1px solid #79747E",
                borderRadius: "12px",
                display: graceDurationToggle ? "block" : "none",
              }}
            >
              <IncrementDecrementCounter
                marks={graceDuration}
                onIncDecClick={onIncDecClickQuizGraceDuration}
                label=""
                uper_limit={150}
                lower_limit={1}
              />
            </div>
          </div>
        </div>

        <div className="flex mt-[20px] mr-[20px]" style={{ display: quizDuration ? "flex" : "none" }}>
          <div className="w-[60%] p-[15px] pl-[20px]">
            <div className="disabled-label" style={{ fontSize: "16px" }}>
              Allow auto submission
            </div>
            <div className="classroom_details_label">Task will be auto-submitted at the end of allotted time</div>
          </div>
          <div className="w-[50%] pt-[10px] ">
            <div className="classroom_name_div ">
              <div className="classroom_details_label flex float-right">
                <ReactSwitch className="float-right" checked={autoSubmissionToggle} onChange={(value) => handleChange(3, value)} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-[24px]">
        <div className="bg-[#FEF7FF] p-[15px] pl-[20px]" style={{ border: "1px solid #79747E", borderRadius: "12px" }}>
          <QuizFeedbackAccordian hideAnswerToggle={hideAnswerToggle} handleChange={handleChange} />
        </div>
      </div>

      <div className="mt-[24px]">
        <div className="bg-[#FEF7FF] p-[15px] pl-[20px]" style={{ border: "1px solid #79747E", borderRadius: "12px" }}>
          <div className="flex  mr-[20px]">
            <div className="w-[60%] p-[15px] pl-[20px]">
              <div className="disabled-label" style={{ fontSize: "16px" }}>
                Shuffle Questions
              </div>

              <div className="classroom_details_label sm:truncate sm:whitespace-nowrap sm:overflow-hidden sm:text-ellipsis sm:max-w-[80px] md:max-w-[100%]">
                Present a random order of questions to every student
              </div>
            </div>
            <div className="w-[50%] pt-[10px] ">
              <div className="classroom_name_div ">
                <div className="classroom_details_label flex float-right">
                  <ReactSwitch className="float-right" checked={shuffleQuestionToggle} onChange={(value) => handleChange(6, value)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mt-[24px]">
        <div
          className="bg-[#FEF7FF] p-[15px] pl-[20px]"
          style={{ border: "1px solid #79747E", borderRadius: "12px" }}
        >
          <div className="flex  mr-[20px]">
            <div className="w-[50%] p-[15px] pl-[20px]">
              <div className="disabled-label" style={{ fontSize: "16px" }}>
                Try our AI Marking
              </div>
              <div className="classroom_details_label !overflow-visible">
                Save this quiz for Teepee experience
              </div>
            </div>
            <div className="w-[50%] pt-[10px] ">
              <div className="classroom_name_div ">
                <div className="classroom_details_label flex float-right">
                  <ReactSwitch
                    className="float-right"
                    checked={AIQuestionToggle}
                    onChange={(value) => handleChange(7, value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="mt-[24px]">
        <div className="bg-[#FEF7FF] p-[15px] pl-[20px]" style={{ border: "1px solid #79747E", borderRadius: "12px" }}>
          <div className="flex  mr-[20px]">
            <div className="w-[60%] p-[15px] pl-[20px]">
              <div className="disabled-label" style={{ fontSize: "16px" }}>
                {() => handleText()}
              </div>
              <div className="classroom_details_label  sm:truncate sm:whitespace-nowrap sm:overflow-hidden sm:text-ellipsis sm:max-w-[80px] md:max-w-[100%]">
                Save these settings as the default for all future tasks
              </div>
            </div>
            <div className="w-[50%] pt-[10px] ">
              <div className="classroom_name_div ">
                <div className="classroom_details_label flex float-right">
                  <ReactSwitch className="float-right" checked={configureToggle} onChange={(value) => handleChange(5, value)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizSetting;
