import React, { useContext } from "react";
import Footer from "./Footer";
import LoginPageRightSide from "./LoginPageRightPart";
import LoginPageLeftSide from "./LoginPageLeftSide";
import amplitude from "amplitude-js";
import appContext from "../context/appContext";

function Login() {
  const state = useContext(appContext);

  React.useEffect(() => {
    try {
      if (state.loginState === 1) {
        amplitude.getInstance().setUserId(null);
        amplitude.getInstance().logEvent("LP-Page View-Student");
      } else {
        amplitude.getInstance().setUserId(null);
        amplitude.getInstance().logEvent("LP-Page View-Teacher");
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <React.Fragment>
      <section>
        <div className="w-auto h-auto grid  text-white text-4xl md:grid-cols-2 sm:grid-cols-1 overflow-hidden">
          <LoginPageLeftSide />
          <LoginPageRightSide />
        </div>
      </section>
    </React.Fragment>
  );
}

export default Login;
