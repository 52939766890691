import React from "react";

const DashboardLabel = ({ text }) => (
  <div className="md:pl-4 w-fit">
    <label className="dashboard-label">{text}</label>
    <div className="label-bar mt-[0.688rem]"></div>
  </div>
);

export default DashboardLabel;
