import React from "react";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";

const DayViewModal = ({ isOpen, onRequestClose, date, events = [] }) => {
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          All Events for {date ? moment(date).format("LL") : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {events.length > 0 ? (
          <table className="table">
            <tbody>
              {events.map((event, index) => {
                if (moment(event?.start).isSame(date, "day")) {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          <strong>Subject:</strong>
                        </td>
                        <td>{event.data.subject_name}</td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Areas:</strong>
                        </td>
                        <td>
                          {event.data.area_object_lists &&
                            event.data.area_object_lists.map((area, index) =>
                              area.area_name ? (
                                Array.isArray(area.area_name) ? (
                                  area.area_name.map((name, nameIndex) => (
                                    <div key={`${index}-${nameIndex}`}>
                                      {name}
                                    </div>
                                  ))
                                ) : (
                                  <div key={index}>{area.area_name}</div>
                                )
                              ) : area.sub_topic_name ? (
                                Array.isArray(area.sub_topic_name) ? (
                                  area.sub_topic_name.map((name, nameIndex) => (
                                    <div key={`${index}-${nameIndex}`}>
                                      {name}
                                    </div>
                                  ))
                                ) : (
                                  <div key={index}>{area.sub_topic_name}</div>
                                )
                              ) : null
                            )}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </tbody>
          </table>
        ) : (
          <p>No events for this day.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button style={{ backgroundColor: "#3E1846" }} onClick={onRequestClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DayViewModal;
