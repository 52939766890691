import React from "react";
import trophyIcon from "../../../images/trophy2.svg"; // Replace with actual path
import starIcon from "../../../images/star2.svg";
import searchIcon from "../../../images/search2.svg";
import targetIcon from "../../../images/spot2.svg";
import magicIcon from "../../../images/magic.svg";

const RewardsList = ({ quizScore, recallStatusCount, quizTotalMarks }) => {
  const rewardsData = [
    { icon: trophyIcon, title: "Quiz Champion", multiplier: <img src={magicIcon} alt="Magic Icon" className="w-4 h-4 inline" />, xp: 50 },
    { icon: starIcon, title: "Flawless Victory", multiplier: null, xp: quizScore === quizTotalMarks ? 100 : "_" },
    { icon: searchIcon, title: "Question Mastery", multiplier: `${recallStatusCount} × 200`, xp: recallStatusCount * 200 },
    { icon: targetIcon, title: "Spot-On", multiplier: `${quizScore} × 10`, xp: quizScore * 10 },
  ];

  return (
    <div className="bg-[#F1EEF6] p-4 rounded-lg shadow-md w-full sm:w-[100%] md:w-[80%] md:ml-[10%] relative">
      {/* Vertical Divider Line (Visible on larger screens only) */}
      <div className="hidden md:block absolute top-4 bottom-4 left-[85%] w-0.5 bg-[#D7D5DA]"></div>

      {/* Rewards List Items */}
      {rewardsData.map((reward, index) => (
        <div key={index} className="flex flex-col sm:flex-row items-center justify-between py-2 border-b last:border-b-0 border-gray-200">
          {/* Left Side: Icon and Title */}
          <div className="flex items-center space-x-2 w-full sm:w-3/4 mb-2 sm:mb-0">
            <img src={reward.icon} alt={reward.title} className="w-6 h-6" />
            <div className="text-sm font-medium text-gray-700">{reward.title}</div>
          </div>

          {/* Multiplier (if available) and XP */}
          <div className="flex items-center justify-between w-full sm:w-1/4 text-sm text-gray-500 sm:text-right">
            {reward.multiplier && <div className="text-xs sm:ml-auto-1">{reward.multiplier}</div>}
            <div className="font-bold text-purple-700 sm:text-right ml-auto">+ {reward.xp} XP</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RewardsList;
