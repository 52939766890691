import React, { useRef, useState, useEffect } from "react";
import Sidebar from "./Sidebar";
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import MuiAccordion from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
// import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { AccordionDetails } from "@mui/material";
import IncDecCounter from "./IncDecCounter";
function SubjectMarksLevelExamPromptContent(props) {
  const unique_index = props.subject_marks_level_prompt.subject_marks_level_prompt_id;
  const [expand, setExpand] = React.useState(false);
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };
  const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const [show, setShow] = useState(false);

  const [open, setOpen] = React.useState(false);

  console.log("SUBJECT PROMT LEVEL: ", props.subject_marks_level_prompt);
  const subjectData = props.subject_marks_level_prompt;
  const options = ["gpt-4o-mini", "gpt-3.5-turbo-16k-0613", "gpt-4"];
  var defaultOptionGeneration = subjectData.generation_model === null ? "gpt-4o-mini" : subjectData.generation_model;
  var defaultOptionEvaluation = subjectData.evaluation_model === null ? "gpt-4o-mini" : subjectData.evaluation_model;

  useEffect(() => {
    // document.getElementById("generation_model" + unique_index).value =
    //   subjectData.generation_model;
    document.getElementById("generation_identity" + unique_index).value = subjectData.generation_identity;
    document.getElementById("generation_example" + unique_index).value = subjectData.generation_example;
    document.getElementById("generation_query" + unique_index).value = subjectData.generation_query;

    // document.getElementById("evaluation_model" + unique_index).value =
    //   subjectData.evaluation_model;
    document.getElementById("evaluation_identity" + unique_index).value = subjectData.evaluation_identity;
    document.getElementById("evaluation_example" + unique_index).value = subjectData.evaluation_example;
    document.getElementById("evaluation_query" + unique_index).value = subjectData.evaluation_query;
  }, [subjectData]);

  const isSubmitting = useRef(false);
  function submitForm() {
    console.log("data", subjectData);
    var token = "Bearer " + localStorage.getItem("access_token");
    isSubmitting.current = true;

    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_subject_marks_level_prompt", {
        method: "POST",
        subject_marks_level_prompt: subjectData,
      })

      .then((response) => {
        console.log(response);
        isSubmitting.current = false;

        setShow(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleCloseWithCancel = () => {
    setOpen(false);
    isSubmitting.current = false;
  };

  const handleCloseWithYes = () => {
    setOpen(false);
    // submitForm();
  };

  const handleSubmitButtonClick = (event) => {
    var evaluation_model = defaultOptionEvaluation;
    var evaluation_identity = document.getElementById("evaluation_identity" + unique_index).value;
    var evaluation_example = document.getElementById("evaluation_example" + unique_index).value;
    var evaluation_query = document.getElementById("evaluation_query" + unique_index).value;

    if (evaluation_model.length <= 0 || evaluation_identity.length <= 0 || evaluation_example.length <= 0 || evaluation_query.length <= 0) {
      alert("Cannot submit with empty field");
      return;
    }

    var generation_model = defaultOptionGeneration;
    var generation_identity = document.getElementById("generation_identity" + unique_index).value;
    var generation_example = document.getElementById("generation_example" + unique_index).value;
    var generation_query = document.getElementById("generation_query" + unique_index).value;

    if (generation_model.length <= 0 || generation_identity.length <= 0 || generation_example.length <= 0 || generation_query.length <= 0) {
      alert("Cannot submit with empty field");
      return;
    }
    console.log("Data is: ", subjectData);

    subjectData.generation_model = generation_model;
    subjectData.generation_identity = generation_identity;
    subjectData.generation_example = generation_example;
    subjectData.generation_query = generation_query;

    subjectData.evaluation_model = evaluation_model;
    subjectData.evaluation_identity = evaluation_identity;
    subjectData.evaluation_example = evaluation_example;
    subjectData.evaluation_query = evaluation_query;

    // setOpen(true);
    submitForm();
  };
  const onChangeGenerationModel = (e) => {
    defaultOptionGeneration = e.value;
  };
  const onChangeEvaluationModel = (e) => {
    defaultOptionEvaluation = e.value;
  };
  return (
    <>
      <ToastContainer className="p-3 position-fixed top-12 right-0">
        <Toast style={{ backgroundColor: "#f7f2f9" }} onClose={() => setShow(false)} show={show} delay={5000} autohide>
          <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <strong className="me-auto">We're on it!</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>Prompt data updated successfully</Toast.Body>
        </Toast>
      </ToastContainer>
      <Dialog open={open} onClose={handleCloseWithCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <b>Confirm</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to update prompt data?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>NO</Button>
          <Button className="delete-button" onClick={handleCloseWithYes} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <div className=" flex-row justify-left items-center pl-[0px] pr-[0px] mt-[20px]">
        <div className=""></div>
        <div className="selectionDiv mb-[100px]">
          <div>
            {/* <div>
                <p style={{fontSize: "18px", fontFamily: "Roboto", color: "#49454F",}}>
                  <b>Subject Name: </b>        
                            {subjectData.current !=null ? subjectData.current.subject_name : ""} 
                            </p>
                </div> */}

            <div className="w-full">
              <div>
                <p
                  className="mt-[10px] mb-[5px]"
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    color: "#49454F",
                  }}
                >
                  <b>Generation Model: </b>
                </p>
              </div>
              <div>
                {/* <input
                  type="text"
                  name={"generation_model" + unique_index}
                  disabled
                  value="gpt-3.5-turbo"
                  defaultValue={"gpt-3.5-turbo"}
                  required={true}
                  className="p-2.5 shadow-sm outline-none rounded-[5px] border-[1px] border-[#474747] w-full h-[50px] text-[#474747] leading-[30px] text-[16px] font-[400] px-4 bg-[#efeded]"
                  id={"generation_model" + unique_index}
                  placeholder=""
                /> */}
              </div>
              <Dropdown
                controlClassName="!p-2.5 !shadow-sm !outline-none !rounded-[5px] !border-[1px] !border-[#474747] w-full h-[50px] !text-[#474747] !leading-[30px] !text-[16px] !font-[400] !px-4 !bg-[#efeded]"
                options={options}
                onChange={onChangeGenerationModel}
                value={defaultOptionGeneration}
                placeholder="Select an option"
              />

              <div>
                <p
                  className="mt-[10px] mb-[5px]"
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    color: "#49454F",
                  }}
                >
                  <b>Generation Identity: </b>
                </p>
              </div>
              <div>
                <textarea
                  type="text"
                  name={"generation_identity" + unique_index}
                  required={true}
                  id={"generation_identity" + unique_index}
                  className="teepee-prompt-textarea"
                  placeholder=""
                />
              </div>

              <div>
                <p
                  className="mt-[10px] mb-[5px]"
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    color: "#49454F",
                  }}
                >
                  <b>Generation Example: </b>
                </p>
              </div>
              <div>
                <textarea
                  type="text"
                  name={"generation_example" + unique_index}
                  required={true}
                  id={"generation_example" + unique_index}
                  className="teepee-prompt-textarea"
                  placeholder=""
                />
              </div>

              <div>
                <p
                  className="mt-[10px] mb-[5px]"
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    color: "#49454F",
                  }}
                >
                  <b>Generation Query: </b>
                </p>
              </div>
              <div>
                <textarea
                  type="text"
                  name={"generation_query" + unique_index}
                  required={true}
                  id={"generation_query" + unique_index}
                  className="teepee-prompt-textarea"
                  placeholder=""
                />
              </div>
            </div>
          </div>

          <div className="mt-[30px] mb-[30px]">
            <div className="w-[100%]">
              <div>
                <p
                  className="mt-[10px] mb-[5px]"
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    color: "#49454F",
                  }}
                >
                  <b>Evaluation Model: </b>
                </p>
              </div>
              <div>
                {/* <input
                  type="text"
                  name={"evaluation_model" + unique_index}
                  disabled
                  value="gpt-3.5-turbo"
                  required={true}
                  className="p-2.5 bg-[#efeded] shadow-sm outline-none rounded-[5px] border-[1px] border-[#474747] w-full h-[50px] text-[#474747] leading-[30px] text-[16px] font-[400] px-4"
                  id={"evaluation_model" + unique_index}
                  placeholder=""
                /> */}
              </div>
              <Dropdown
                controlClassName="!p-2.5 !shadow-sm !outline-none !rounded-[5px] !border-[1px] !border-[#474747] w-full h-[50px] !text-[#474747] !leading-[30px] !text-[16px] !font-[400] !px-4 !bg-[#efeded]"
                options={options}
                onChange={onChangeEvaluationModel}
                value={defaultOptionEvaluation}
                placeholder="Select an option"
              />

              <div>
                <p
                  className="mt-[10px] mb-[5px]"
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    color: "#49454F",
                  }}
                >
                  <b>Evaluation Identity: </b>
                </p>
              </div>
              <div>
                <textarea
                  type="text"
                  name={"evaluation_identity" + unique_index}
                  required={true}
                  id={"evaluation_identity" + unique_index}
                  className="teepee-prompt-textarea"
                  placeholder=""
                />
              </div>

              <div>
                <p
                  className="mt-[10px] mb-[5px]"
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    color: "#49454F",
                  }}
                >
                  <b>Evaluation Example: </b>
                </p>
              </div>
              <div>
                <textarea
                  type="text"
                  name={"evaluation_example" + unique_index}
                  required={true}
                  id={"evaluation_example" + unique_index}
                  className="teepee-prompt-textarea"
                  placeholder=""
                />
              </div>

              <div>
                <p
                  className="mt-[10px] mb-[5px]"
                  style={{
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    color: "#49454F",
                  }}
                >
                  <b>Evaluation Query: </b>
                </p>
              </div>
              <div>
                <textarea
                  type="text"
                  name={"evaluation_query" + unique_index}
                  required={true}
                  id={"evaluation_query" + unique_index}
                  className="teepee-prompt-textarea"
                  placeholder=""
                />
              </div>

              <div className="mb-[10px]">
                <button
                  onClick={handleSubmitButtonClick}
                  id={"submit_button" + unique_index}
                  className="teepee-button hover:bg-black"
                  style={{ width: "46%" }}
                >
                  {isSubmitting.current && (
                    <div className="spinner-border spinner-border-m float-left" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubjectMarksLevelExamPromptContent;
