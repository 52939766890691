import { Navigate, Route, Routes } from "react-router-dom";

const ProtectedAdminRoutes = ({ element }) => {
  const user_type = localStorage.getItem("user_type");
  console.log("USER TYPE OF THE USER: ", user_type);
  if (user_type === "2") {
    return element;
  } else {
    if (user_type === "3") {
      return <Navigate to="/student-dashboard" />;
    }
    if (user_type === null) {
      return <Navigate to="/login" />;
    }
    return <Navigate to="/" />;
  }
};
export default ProtectedAdminRoutes;
