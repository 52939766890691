import React, { Fragment } from "react";
import { Autorenew } from "@material-ui/icons";
import clsx from "clsx";
import amplitude from "amplitude-js";
import axios from "axios";
import { makeStyles } from "@material-ui/core";
function ReEvaluateButton(props) {
  const [spin, setSpin] = React.useState(false);
  const useStyles = makeStyles((theme) => ({
    refresh: {
      marginTop: "-4px",
      cursor: "pointer",
      float: "right",
      margin: "auto",
      "&.spin": {
        animation: "$spin 1s 131",
        pointerEvents: "none",
      },
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  }));
  const classes = useStyles();

  const refreshCanvas = () => {
    setSpin(true);
    reEvaluateQuiz();

    // setTimeout(() => {
    //   setSpin(false);
    // }, 8000);
  };

  const reEvaluateQuiz = () => {
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/re_evaluate_quiz", {
        method: "POST",
        quiz_response_id: props.quiz.quiz_response_id,
        quiz_secret_key: props.quiz_secret_key,
      })

      .then((response) => {
        console.log(response);
        setSpin(false);
        props.get_list_of_attempted_students();

        amplitude.getInstance().logEvent("Quiz re-evaluate request submitted");
      })
      .catch((error) => {
        console.log(error);
        setSpin(false);
        document.getElementById("analyzing").style.display = "none";
        document.getElementById("openaidown").style.display = "block";
      });
  };

  return (
    <Autorenew
      className={clsx({
        [classes.refresh]: true,
        spin: spin,
      })}
      onClick={refreshCanvas}
      spin={360}
    />
  );
}

export default ReEvaluateButton;
