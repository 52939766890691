import React from "react";
import { AiOutlineClose } from "react-icons/ai"; // Close icon
import bolt_icon from "../../../images/bolt.svg";
import trophy_icon from "../../../images/trophy2.svg";
import star_icon from "../../../images/star2.svg";
import seacrch_icon from "../../../images/search2.svg";
import spot_icon from "../../../images/spot2.svg";
const achievements = [
  {
    title: "Quiz Champion",
    description: "Awarded for completing the quiz.",
    xp: 50,
    icon: <img src={trophy_icon} className="h-[48px] w-[48px]" alt="" />, // Emoji or image icon
  },
  {
    title: "Flawless Victory",
    description: "No errors! You've completed the quiz perfectly.",
    xp: 100,
    icon: <img src={star_icon} className="h-[48px] w-[48px]" alt="" />,
  },
  {
    title: "Question Mastery",
    description: "You’ve mastered a particular question by answering it correctly three times.",
    xp: 200,
    icon: <img src={seacrch_icon} className="h-[48px] w-[48px]" alt="" />,
  },
  {
    title: "Spot-On",
    description: "You answered a question correctly.",
    xp: 10,
    icon: <img src={spot_icon} className="h-[48px] w-[48px]" alt="" />,
  },
];

const XPPopUp = ({ isOpen, onClose, totalXp }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white w-full max-w-md p-6  rounded-lg shadow-lg relative">
        {/* Close Button */}
        <div className="">
          {/* Close Button */}
          <button className="absolute top-8 left-6 text-gray-600 hover:text-gray-800" onClick={onClose}>
            <AiOutlineClose size={20} />
          </button>

          {/* Header */}
          <div className="flex  justify-between items-center mb-1 w-full">
            <h2 className="text-lg font-semibold text-gray-800 w-[50%] mb-0 text-right">XP</h2>

            <div className="flex items-center font-semibold text-gray-800 w-[45%] justify-end">
              <img className="mr-1 w-[28px] h-[28px]" src={bolt_icon} alt="XP Icon" />
              {totalXp}
            </div>
          </div>
        </div>
        <div className="teepee-divider mb-4 w-[100%] "></div>
        {/* Achievement List */}
        <div className="space-y-4">
          {achievements.map((achievement, index) => (
            <div key={index} className={`flex items-start space-x-6 ${index !== achievements.length - 1 ? "border-b border-[#79747E29]" : ""}`}>
              <div className="text-3xl">{achievement.icon}</div>
              <div className="flex-1">
                <h3 className="font-bold text-[#252744] text-[16px] leading-[24px]">{achievement.title}</h3>
                <p className="text-[#252744] text-[14px]">{achievement.description}</p>
              </div>
              <div className="bg-[#8B5CF6] flex text-white text-xs font-semibold px-2 py-1 rounded-lg">
                <img src={bolt_icon} className="w-[16px] h-[16px]" alt="" />+ {achievement.xp} XP
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default XPPopUp;
