import React, { useRef, useState, useEffect } from "react";
import Sidebar from "./Sidebar";
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSwitch from 'react-switch';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import MuiAccordion from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";
// import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { AccordionDetails } from "@mui/material";
import IncDecCounter from "./IncDecCounter";
import SubjectMarksLevelExamPromptContent from "./SubjectMarksLevelExamPromptContent";
function SubjectMarksLevelExamStyleAssessmentPrompt(props) {
  const unique_index=props.subject_marks_level_prompt.subject_marks_level_prompt_id;
  const [expand, setExpand] = React.useState(false);
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const [show, setShow] = useState(false);
  const toastTitle =useRef("");
  const toastDescription =useRef("");
  
 
  const deleteSubjectMarksLevelPrompt = ((subject_marks_level_prompt_id) =>{
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL +
          "/delete_subject_marks_level_prompt",
        {
          method: "POST",
          subject_marks_level_prompt_id: subject_marks_level_prompt_id,
        }
      )

      .then((response) => {
        props.addTodo(props.subject_id)
      })
      .catch((error) => {
        console.log(error);
       
      });
  })

  const onIncDecClick = ((value) => {
    props.subject_marks_level_prompt.prompt_marks = value;
    onUpdateSubjectMarksLevelPromptMarks(value, props.subject_marks_level_prompt)
  });

  const onUpdateSubjectMarksLevelPromptMarks = (value, subject_marks_level_prompt) => {
    // chapter.chapter_name=value;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/update_subject_marks_level_prompt_marks",
        {
          method: "POST",
          prompt_marks: value,
          subject_marks_level_prompt_id: subject_marks_level_prompt.subject_marks_level_prompt_id,
        }
      )

      .then((response) => {
        //props.addTodo(props.subject_id);
      })
      .catch((error) => {
        console.log(error);
        alert("unable to update subject");
      });
  };

  const handleChange = (value, subject_marks_level_prompt_id) => {
    

  
    props.subject_marks_level_prompt.is_enabled=value
    sendSubjectMarksLevelPromptStatusUpdateRequest(value, subject_marks_level_prompt_id);
  }

  function sendSubjectMarksLevelPromptStatusUpdateRequest(value, subject_marks_level_prompt_id)
  {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_subject_marks_level_prompt_status", {
        method: "POST",
        is_enabled: value,
        subject_marks_level_prompt_id: subject_marks_level_prompt_id
      })
      .then((response) => {
        setShow(true)
        toastTitle.current = "We're on it!";
        toastDescription.current = "Prompt status updated successfully";
      })
      .catch((error) => {
        console.log(error);
        alert("failed to change mock exam status");
      });
  }

  return (
    <>
    <ToastContainer className="p-3 position-fixed top-12 right-0">
        <Toast style={{backgroundColor:"#f7f2f9"}} onClose={() => setShow(false)} show={show} delay={5000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{toastTitle.current}</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>{toastDescription.current}</Toast.Body>
        </Toast>
    </ToastContainer>
         
      <div>
      <Accordion
          expanded={expand}
        >
          <AccordionSummary  aria-controls="panel1a-content" id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          
          IconButtonProps={{
            onClick: toggleAcordion
          }}
          style={{
            backgroundColor:
               "rgba(255, 255, 255, .05)",
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)",
            },
            "& .MuiAccordionSummary-content": {
              marginLeft: 0,
            },
            padding: 0
          }}
          >
            
            <Typography className="w-full"> 
            
            <table style={{width:"500px"}}>
                        <tr>
                            <td style={{width:"100px"}}>
                                <div>Prompt Marks: </div>
                            </td>
                            
                            <td>
                                <b>  
                                <IncDecCounter marks={props.subject_marks_level_prompt.prompt_marks} onIncDecClick={onIncDecClick} label="" uper_limit={150} lower_limit={1}/>
                                </b>
                            </td>

                            <td>
                            <ReactSwitch
                                  className="float-right"
                                    checked={props.subject_marks_level_prompt.is_enabled}
                                    onChange={(value)=>handleChange(value, props.subject_marks_level_prompt.subject_marks_level_prompt_id)}
                                  />
                            </td>
                        </tr>
                    </table>
                                          
                                          </Typography>
                                          
                                          <Button style={{float:"right"}} onClick={() => deleteSubjectMarksLevelPrompt(props.subject_marks_level_prompt.subject_marks_level_prompt_id)}>
                                            <FontAwesomeIcon icon={faTrash} />  
                                          </Button>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
                <SubjectMarksLevelExamPromptContent subject_marks_level_prompt = {props.subject_marks_level_prompt}/>
            </Typography>
          </AccordionDetails>
          </Accordion>
      </div>
    </>
  );
}

export default SubjectMarksLevelExamStyleAssessmentPrompt;
