import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import CircularProgress from "../../Teacher/widgets/CircularProgress";

function TopicAccordion(props) {
  const [open, setOpen] = React.useState(1);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  const handleParticipationText = (participationPercentage) => {
    if (participationPercentage !== NaN) {
      if (participationPercentage >= 80) {
        return "Mastered";
      } else if (
        participationPercentage >= 60 &&
        participationPercentage < 80
      ) {
        return "Competent";
      } else if (
        participationPercentage >= 40 &&
        participationPercentage < 60
      ) {
        return "Intermediate";
      } else if (participationPercentage < 40 && participationPercentage > 0) {
        return "Developing";
      } else if (participationPercentage === 0) {
        return "Insufficient";
      }
    }
  };

  const getColorForPercentage = (percentageString) => {
    const value = parseInt(percentageString, 10); // Parse percentage as integer
    if (value >= 80) {
      return "#55CE98";
    } else if (value >= 60) {
      return "#F2D249";
    } else if (value >= 40) {
      return "#F2C474";
    } else if (value >= 20) {
      return "#F8B250";
    } else {
      return "#FAAD98";
    }
  };
  return (
    <>
      {props.topicData &&
        props.topicData.map((topic, index) => (
          <Accordion open={open === index + 1}>
            <AccordionHeader onClick={() => handleOpen(index + 1)}>
              <div className="flex justify-between w-full">
                <div className="flex items-center">{topic.topic_name}</div>
                <div>
                  <CircularProgress
                    StudentParticipationRate={
                      topic.mastered_questions_percentage
                    }
                    label={handleParticipationText(
                      parseInt(topic.mastered_questions_percentage)
                    )}
                    color={getColorForPercentage(
                      parseInt(topic.mastered_questions_percentage) + "%"
                    )}
                    isSize={true}
                  />
                </div>
              </div>
            </AccordionHeader>
            <AccordionBody>
              {topic.subtopics.map((subtopic, index) => {
                return (
                  <div className="flex justify-between w-full mb-[2%]">
                    <div className="flex items-center">
                      {subtopic.sub_topic_name}
                    </div>
                    <div>
                      <CircularProgress
                        StudentParticipationRate={
                          subtopic.mastered_questions_percentage
                        }
                        label={handleParticipationText(
                          parseInt(subtopic.mastered_questions_percentage)
                        )}
                        color={getColorForPercentage(
                          parseInt(subtopic.mastered_questions_percentage) + "%"
                        )}
                        isSize={true}
                      />
                    </div>
                  </div>
                );
              })}
            </AccordionBody>
          </Accordion>
        ))}
    </>
  );
}

export default TopicAccordion;
