import React from "react";
import Dash from "../../../images/dash.png";

function OrderDetailsLineCard({ heading, body, shoBorder }) {
  return (
    <>
      <div>
        <div className="text-[14px] text-[#49454F]">{heading}</div>
        <div className="font-semibold text-[16px]">{body}</div>
      </div>
      <div
        className="mr-[1%] ml-[1%]"
        style={{ display: !shoBorder && "none" }}
      >
        <img src={Dash} />
      </div>
    </>
  );
}

export default OrderDetailsLineCard;
