import React from "react";

const ChunkedProgressBar = ({ currentQuestionIndex, totalQuestions }) => {
  const chunks = [];
  const completion = (currentQuestionIndex / totalQuestions) * 100;

  for (let i = 0; i < totalQuestions; i++) {
    chunks.push(
      <div
        key={i}
        style={{
          borderRadius: "4px",
          width: `${totalQuestions * 10 + "%"}`, // Each chunk takes up 10% of the total width
          height: "10px", // Height of the chunk
          display: "inline-block",
          backgroundColor: i < currentQuestionIndex ? "#7E418B" : "#e0e0e0", // Filled color for completed questions, grey for the rest
          borderRight: "1px solid #fff", // Add separation between chunks except for the last one
          borderLeft: "1px solid #fff",
        }}
      ></div>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {chunks}
    </div>
  );
};

export default ChunkedProgressBar;
