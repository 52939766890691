import React, { useState, useEffect } from "react";
import star from "../../../images/Star.svg";

const LevelProgress = ({ TotalScore, oldXp }) => {
  const [level, setLevel] = useState(1); // Current level
  const [animatingXp, setAnimatingXp] = useState(oldXp); // XP used for animation
  const maxXp = 3000;
  const targetXp = TotalScore + oldXp; // Total XP to reach after animation

  // Animate XP increase smoothly over 2-3 seconds
  useEffect(() => {
    const duration = 2000; // Animation duration in milliseconds
    const startTime = performance.now();

    const animate = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1); // Calculate progress (0 to 1)
      let currentXp = oldXp + (targetXp - oldXp) * progress; // Interpolated XP value

      // If current XP reaches or exceeds maxXp, level up and reset XP
      if (currentXp >= maxXp) {
        const newLevel = Math.floor(currentXp / maxXp); // Calculate the new level based on XP
        setLevel(newLevel + 1); // Update the level state
        currentXp = currentXp % maxXp; // Reset XP for the new level after leveling up
      }

      setAnimatingXp(Math.floor(currentXp)); // Update animating XP

      if (progress < 1) {
        requestAnimationFrame(animate); // Continue animation
      } else {
        // Finalize level and XP after animation completes
        setAnimatingXp(currentXp);
      }
    };

    requestAnimationFrame(animate); // Start animation
  }, [TotalScore, oldXp, targetXp]);

  // Calculate percentage for progress bar
  const percentage = Math.min((animatingXp / maxXp) * 100, 100);

  return (
    <div className="flex flex-col items-center space-y-2">
      {/* Progress Bar */}
      <div className="relative w-[307px] h-6 rounded-full bg-gray-200 overflow-hidden">
        {/* Level Text - Fixed on the left */}
        <span style={{ zIndex: "1000" }} className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[12px] font-semibold text-gray-900">
          Level {level}
        </span>

        {/* XP Fill with Star at the end */}
        <div className="absolute top-0 left-0 h-full rounded-full bg-gradient-to-r from-[#FFCC81] to-[#FEB343]" style={{ width: `${percentage}%` }}>
          <img
            src={star}
            alt="Star"
            className="absolute right-[-10px] top-1/2 transform -translate-y-1/2 w-4 h-4"
            style={{ right: percentage === 100 ? "4px" : "10px" }}
          />
        </div>
      </div>

      {/* XP Text */}
      <div className="text-purple-700 font-bold">
        {animatingXp}/{maxXp} XP
      </div>
    </div>
  );
};

export default LevelProgress;
