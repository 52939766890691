import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import axios from "axios";
import DashboardVideosCard from "../DashboardVideosCard";
import TutorialsVideoCard from "./TutorialsVideoCard";
import amplitude from "amplitude-js";

function Tutorials() {
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
    amplitude.getInstance().logEvent("Tutorials");
    getVideosList();
  }, []);
  const getVideosList = () => {
    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_videos",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log(JSON.parse(res.data.data));
        setVideoList(JSON.parse(res.data.data));
      });
  };
  return (
    <div>
      <Sidebar item_id="tutorials" />
      <div className=" sm:ml-0 md:ml-[316px] mb-[40px] md:w-[70%]">
        <div className="home sm:ml[25px] md:ml-[60px] ml-[25px]">
          <div>
            <div className="flex">
              <label className={"dashboard-label cursor-pointer"}>
                Tutorials
              </label>
            </div>

            {<div className="teepee-divider"></div>}
          </div>
        </div>{" "}
        <div className="sm:my-quiz-div sm:ml-[25px] md:ml-[60px] mb-4 mr-[20px] mt-[20px]">
          {/* <DashboardVideosCard videoList={videoList} /> */}
          <TutorialsVideoCard videoList={videoList} />
        </div>
      </div>
    </div>
  );
}

export default Tutorials;
