import React from "react";
import ForgotPassword from "./ForgotPassword";
import LoginTabs from "./LoginTabs";
import LoginOptions from "./LoginOptions";

function LoginPageRightSide(props) {
  return (
    <div>
      <div className="w-full h-full bg-white centered md:h-screen p-10 md:p-36 sm:p-5">
        <div className="main-content-div w-[500px] sm:w-[360px] md:w-[520px]">
          <div className="sign-in-label mb-[20px]">
            <p>Join Teepee as a</p>
          </div>
          <div className="w-[100%] flex justify-center">
            <LoginTabs />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPageRightSide;
