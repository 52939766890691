import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import axios from "axios";

function InterestAnalytics() {
  useEffect(() => {
    getSubjectsList();
  }, []);

  const getSubjectsList = () => {
    axios
      .request({
        method: "GET",
        url:
          process.env.REACT_APP_REST_API_BASE_URL + "/get_onboarding_interest",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log(JSON.parse(res.data.data));
        setRows(JSON.parse(res.data.data));
      });
  };

  const [rows, setRows] = useState([]);

  return (
    <div>
      <Sidebar item_id="interest_analytics" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px] ">
        <div className=" md:ml-[60px]  ">
          <div>
            <table
              class="w-full text-sm text-left text-white dark:text-white"
              className="tracking_table w-[100%] "
              id="tbl"
            >
              <thead class="text-xs text-black dark:bg-white dark:text-white ">
                <tr className="tracking_table">
                  <th
                    scope="col"
                    class="px-6 py-3 tracking_table spth bg-[#F7F2F9]"
                  >
                    S.no
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%]"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 tracking_table whitespace-nowrap"
                  >
                    Sign up via
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 tracking_table whitespace-nowrap"
                  >
                    Subjects
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 tracking_table whitespace-nowrap"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 tracking_table whitespace-nowrap"
                  >
                    Time
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows.map((stats, index) => (
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white tracking_table_td"
                      style={{ backgroundColor: "rgba(103, 80, 164, 0.08)" }}
                    >
                      {index + 1}
                    </th>
                    <td
                      class="px-6 py-4  tracking_table tracking_table_td"
                      style={{ backgroundColor: "rgba(103, 80, 164, 0.08)" }}
                    >
                      {stats.email_address}
                    </td>
                    <td class="px-6 py-4 tracking_table  tracking_table_td">
                      {stats.account_auth_type === 0
                        ? "Created by admin"
                        : stats.account_auth_type === 1
                        ? "Email"
                        : stats.account_auth_type === 2
                        ? "Google"
                        : "Microsoft"}
                    </td>
                    <td class="px-6 py-4 tracking_table  tracking_table_td">
                      {stats.subjects_data.toString()}
                    </td>
                    <td class="px-6 py-4 tracking_table  tracking_table_td">
                      {stats.date}
                    </td>
                    <td class="px-6 py-4 tracking_table tracking_table_td">
                      {stats.time}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InterestAnalytics;
