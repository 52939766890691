import { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import StudentDashbaord from "./Componenets/Student/StudentDashbaord";

const ProtectedStudentRoute = ({ element }) => {
  const user_type = localStorage.getItem("user_type");
  console.log("USER TYPE OF THE USER: ", user_type);
  if (user_type === "3") {
    return element;
  } else {
    if (user_type === null) {
      return <Navigate to="/login" />;
    }
    return <Navigate to="/" />;
  }
};
export default ProtectedStudentRoute;
