import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import appContext from "../../context/appContext";
import axios from "axios";
import moment from "moment";
import UpgradePlan from "../Subscription/UpgradePlan";
import Sidebar from "../Sidebar";
import SubscriptionExpirationBanner from "./widgets/SubscriptionExpirationBanner";
import DashboardClassroomCard from "./DashboardClassroomCard";
import PlusIcon from "../../images/plus.svg";
import JoinClassCodeDialogue from "./create_classroom_dialogue/JoinClassCodeDialogue";

function TeacherSubjectHome() {
  const state = useContext(appContext);
  const search = window.location.search;
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const [trialObject, setTrialObject] = useState();
  const [subjectData, setSubjectData] = useState();
  const [openJoinClassroomDialogue, setOpenJoinClassroomDialogue] = useState(false);
  const [classroomList, setClassroomList] = useState([]);
  const [loading, setLoading] = useState(true);

  var subject_id = params.get("id");

  useEffect(() => {
    getSubjectData();
  }, [subject_id]);

  const getSubjectData = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_quizzes", {
        method: "POST",
        folder_id: null,
        class_id: null,
        subject_id: subject_id,
        trial_start_date: moment().format("Y-MM-DD HH:mm:ss"),
        trial_end_date: moment().format("Y-MM-DD HH:mm:ss"),
        cuurent_date: moment().format("Y-MM-DD HH:mm:ss"),
      })

      .then((res) => {
        console.log("DATA: ", res.data);
        res.data.subject["trial_period_tracking_id"] = res.data.subject_trial.trial_data.trial_period_tracking_id;
        console.log("AFTER ALTERATION DATA: ", res.data);

        setSubjectData(res.data);
        setTrialObject(res.data.subject_trial.trial_data);
        setClassroomList(res.data.classes_data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => {
    window.location.reload();
    state.setOpenBuyVoucherPopUp(false);
    // setVouchersRate(5);
    // setOffIndicator(-1);
    // setSliderRange(1);
    // setNoOfStudents(1);
    // isStep.current = false;
  };

  const handleClick = () => {
    state.setSelectedSubjectForPopUp(subjectData?.subject);
    state.handleClickOpenCreateClassroomDialogue();
  };

  const handleClickCloseJoinClassroomDialogue = () => {
    setOpenJoinClassroomDialogue(false);
  };
  return (
    <div>
      <JoinClassCodeDialogue
        openJoinClassroomDialogue={openJoinClassroomDialogue}
        handleClickCloseJoinClassroomDialogue={handleClickCloseJoinClassroomDialogue}
        setOpenJoinClassroomDialogue={setOpenJoinClassroomDialogue}
      />
      {loading ? (
        <div className="flex items-center justify-center mt-[15%]  w-full">
          <div role="status">
            <svg
              aria-hidden="true"
              class="w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          {subjectData?.subject && (
            <UpgradePlan
              open={state.openBuyVoucherPopup}
              handleClose={handleClose}
              subjectName={subjectData?.subject?.subject_name}
              subject={subjectData?.subject}
            />
          )}
          <div>
            <Sidebar />
          </div>
          <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px]">
            <div>
              <div className={`w-full p-[1.5rem] bg-[#F7F2FA] font-medium text-[32px] text-center tracking-[0.15px]	`}>
                {subjectData?.subject.subject_name}
              </div>
            </div>
            {trialObject !== undefined && Object.keys(trialObject).length && (
              <SubscriptionExpirationBanner setOpen={state.setOpenBuyVoucherPopUp} bannerObject={trialObject} subjectData={subjectData.subject} />
            )}
            <div className="home !text-[#6750A4] sm:ml[25px] md:ml-[60px] underline teacher_navigation_text mt-[1.5vw]">
              <button className="" onClick={() => navigate("/")}>
                {"Back"}
              </button>
            </div>
            <div className="home sm:ml[25px] md:ml-[60px] ml-[25px] sm:mt-[15%] md:mt-[50px] ">
              <div>
                <label className="dashboard-label">Get Started</label>
                <div className="teepee-divider"></div>
              </div>
            </div>
            <div className="flex">
              <div className="create_quiz_div-main body sm:ml[25px] md:ml-[60px] ml-[25px] cursor-pointer" onClick={handleClick}>
                <button style={{ width: "100%" }} type="button" className={`create_quiz_button_dashboard `} onClick={handleClick}>
                  <div className="inline-flex">
                    <img className="create-quiz-button-icon" src={PlusIcon} alt="" />{" "}
                    <span>{classroomList?.length > 0 ? "Create Classrooom" : "Create Your First Classroom"}</span>
                  </div>
                </button>
              </div>

              <div
                className="text-2xl font-semibold text-gray-500 flex justify-center items-center mt-[20px] mx-[3%]"
                style={{ display: classroomList?.length > 0 && "none" }}
              >
                OR
              </div>

              <div
                className="create_quiz_div-main body sm:ml[25px] cursor-pointer bg-white border-[1px] border-[#664dac] "
                onClick={() => setOpenJoinClassroomDialogue(true)}
                style={{ display: classroomList?.length > 0 && "none" }}
              >
                <div className="inline-flex">
                  <img className="create-quiz-button-icon" src={PlusIcon} alt="" />{" "}
                  <span className="text-[#6750a4]">{classroomList?.length > 0 ? "Join Shared Class" : "Join Shared Classroom!"}</span>
                </div>
              </div>
            </div>
            <div className="home sm:ml[25px] md:ml-[60px] ml-[25px] sm:mt-[15%] md:mt-[50px]">
              <div>
                <div className="flex">
                  <label className={"dashboard-label cursor-pointer"}>My Classrooms</label>
                </div>

                {<div className="teepee-divider"></div>}
              </div>
            </div>
            <div className="my-quiz-div sm:ml-[20px] md:ml-[60px]">
              <DashboardClassroomCard
                classroomData={classroomList}
                handleClick={handleClick}
                setClassroomList={setClassroomList}
                setOpenJoinClassroomDialogue={setOpenJoinClassroomDialogue}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TeacherSubjectHome;
