import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Sidebar from "./Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import BackArrow from "../images/icon.svg";
import Dialog from "@mui/material/Dialog";
import magicianImage from "../images/magician.gif";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import amplitude from "amplitude-js";
import { type } from "@testing-library/user-event/dist/type";
import PrefinedQuestions from "./PredefinedQuestions";
import IncDecCounter from "./IncDecCounter";
import YesNoDialog from "../Componenets/YesNoDialog";

function EditPredefinedQuestions(props) {
  const location = useLocation();

  const [marks, setMarks] = React.useState(0);
  const [
    minimockPredefinedQuestionsPromptList,
    setMinimockPredefinedQuestionsPromptList,
  ] = useState([]);

  const onIncDecClick = (value) => {
    setMarks(value);
  };

  const removeQuestion = (predefined_question_answer_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/delete_predefined_question",
        {
          method: "POST",
          predefined_question_answer_id: predefined_question_answer_id,
        }
      )

      .then((response) => {
        props.getPredefinedQuestionAnswerList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // React.useEffect(() => {
  //   getPredefinedQuestionsPrompts();
  // }, []);

  // function getPredefinedQuestionsPrompts() {
  //   var token = "Bearer " + localStorage.getItem("access_token");
  //   axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  //   axios.defaults.headers.post["Content-Type"] =
  //     "application/json;charset=utf-8";
  //   axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  //   axios.defaults.headers.post["authorization"] = token;
  //   axios
  //     .post(
  //       process.env.REACT_APP_REST_API_BASE_URL +
  //         "/get_predefined_questions_prompt_list",
  //       {
  //         method: "POST",
  //         subject_id: props.subject.subject_id,
  //         show_disabled: false,
  //       }
  //     )

  //     .then((response) => {
  //       var minimockPredefinedQuestionsPrompt = JSON.parse(
  //         response.data["mini_mock_predefined_questions_prompt_list"]
  //       );
  //       setMinimockPredefinedQuestionsPromptList(
  //         minimockPredefinedQuestionsPrompt
  //       );
  //       //generation_model
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  return (
    <div>
      <div className="flex flex-row justify-left items-center ml-[60px] mt-[20px] mr-[60px]">
        <div className=""></div>
        <div className="selectionDiv">
          <div className="form-div">
            <div className="w-[800px]">
              <div>
                <PrefinedQuestions
                  addTodoParent={props.addTodoParent}
                  addTodo={props.addTodo}
                  is_mini_mock={true}
                  removeQuestion={removeQuestion}
                  predefined_question_answer_list={
                    props.predefinedQuestionAnswerList
                  }
                  predefinedQuestionsPromptList={
                    props.predefinedQuestionsPromptList
                  }
                  mockExamsListIndex={props.mockExamsListIndex}
                />
                {/* <div className="mt-5">
                  <button
                     onClick={onSaveBtnClick}
                    className="teepee-button hover:bg-black hover:cursor-pointer"
                  >
                    Save
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditPredefinedQuestions;
