import useGetApi from "../../ApiTemplate/GetApi";
import apiConfig from "../../../Config/apiConfig";

const useGetSharedClassroom = () => {
  const getApiCall = useGetApi();

  // Make getTopics function async to wait for the API response
  const getSharedClassroom = async (apiData) => {
    console.log("IN  getSharedClassroom");
    try {
      // Await the result of the API call
      console.log("url here", apiConfig.endpoints.getSharedClassroom);
      const data = await getApiCall(apiConfig.endpoints.getSharedClassroom);

      // Perform any operations you need with the fetched data
      apiData(data);

      // Optional: Debugging/logging
    } catch (error) {
      console.error("Error fetching data:", error);
      // Optionally, handle the error by passing it to apiData or other logic
    }
  };

  return getSharedClassroom;
};

export default useGetSharedClassroom;
