import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Cross from "../../../images/cross.png"; // Ensure the cross icon is available
import axios from "axios";
import InvalidClassCodeDialog from "../correctincorrectdialogue/InvalidClassCodeDialog";
import { Button, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import swal from "sweetalert"; // If using npm
import CustomDialog from "../confirmationdialogue/CustomDialog";

function JoinClassCodeDialogue(props) {
  const [dialogType, setDialogType] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [header, setHeader] = useState("");
  const [message, setMessage] = useState("");
  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClosed = () => {
    setOpenDialog(false);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleTryAgain = () => {
    console.log("Try Again clicked");
    setDialogOpen(false);
    props.setOpenJoinClassroomDialogue(true);
  };

  const handleClickBack = () => {
    props.handleClickCloseJoinClassroomDialogue();
  };

  const handleClickContinue = () => {
    const classCodeInput = document.getElementById("class_code");
    if (classCodeInput.value.length <= 0) {
      alert("Please enter class code");
      return;
    }

    // Uncomment the following block to enable API functionality
    const token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/check_classroom_code_teacher", {
        method: "POST",
        class_code: classCodeInput.value,
      })
      .then((response) => {
        console.log("data of join classroom:", response.data);
        if (response.data.state === 0) {
          props.handleClickCloseJoinClassroomDialogue();
          setDialogType(response.data.state);
        }
        if (response.data.state === 1) {
          setDialogType(response.data.state);
          props.handleClickCloseJoinClassroomDialogue();
        }
        if (response.data.state === 2) {
          alert("Request already sent wait for approval");
          return;
        }
        if (response.data.state === 3) {
          props.handleClickCloseJoinClassroomDialogue();
          setHeader("You Are Already a Co-Teacher");
          setMessage("You are already a co-teacher in this classroom and do not need to join again.");
          handleOpen();
          return;
        }
        if (response.data.state === 4) {
          props.handleClickCloseJoinClassroomDialogue();
          setHeader("Invalid Action");
          setMessage("You are already the owner of this classroom and cannot join as a co-teacher.");
          handleOpen();

          return;
        }
        if (response.data.class_id !== 0) {
          // handleViewClass(response.data.class_id);
        }

        setDialogOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <InvalidClassCodeDialog open={dialogOpen} onClose={handleClose} onTryAgain={handleTryAgain} dialogType={dialogType} />
      <CustomDialog open={openDialog} handleClose={handleClosed} header={header} message={message} />
      <Dialog
        PaperProps={{
          sx: {
            maxHeight: "600px", // Consider responsiveness
            width: "80%", // Better for smaller screens
            height: "60vh",
            padding: "10px",
          },
        }}
        open={props.openJoinClassroomDialogue}
        onClose={props.handleClickCloseJoinClassroomDialogue}
        aria-labelledby="join-shared-class-title"
        aria-describedby="join-shared-class-description"
      >
        {/* Title and Close Button */}
        <DialogTitle>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Join Shared Class</span>
            <IconButton onClick={props.handleClickCloseJoinClassroomDialogue} style={{ position: "relative", top: "-18px", right: "-18px" }}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        {/* Dialog content */}
        <DialogContent>
          <DialogContentText style={{ marginBottom: "16px", fontSize: "12px", color: "#6b7280" }}>
            Enter the class code provided by the lead teacher to join this classroom as a co-teacher.
          </DialogContentText>
          <TextField
            id="class_code"
            sx={{
              width: "90%", // Adjust the width to 70%
            }}
            variant="outlined"
            // label="Enter class code"
            size="small"
          />
        </DialogContent>

        {/* Action buttons */}
        <DialogActions
          style={{
            justifyContent: "end", // Align the buttons properly
            borderTop: "1px solid #CAC4D0",
          }}
        >
          <Button
            onClick={handleClickBack}
            style={{
              color: "#6750A4",
              textTransform: "none",
              border: "1px solid #6750A4",
              padding: "8px 24px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleClickContinue}
            variant="contained"
            style={{
              backgroundColor: "#6750A4",
              color: "#fff",
              textTransform: "none",
              padding: "8px 24px",
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default JoinClassCodeDialogue;
