import { useEffect, useRef, forwardRef } from "react";

import CheckoutForm from "./CheckoutForm";

const Payment = forwardRef(({ clientSecret, stripePromise, value, handleClickPlaceOrder, handleInputFieldValidation }, ref) => {
  const checkoutFormRef = useRef();

  const handlePlaceOrderClick = () => {
    if (checkoutFormRef.current) {
      checkoutFormRef.current.handleSubmit();
    }
  };

  return (
    <>
      <CheckoutForm
        value={value}
        handleClickPlaceOrder={handleClickPlaceOrder}
        ref={ref}
        clientSecret={clientSecret}
        handleInputFieldValidation={handleInputFieldValidation}
      />
    </>
  );
});

export default Payment;
