import React, { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Cross from "../../../images/cross.png";
import appContext from "../../../context/appContext";
import amplitude from "amplitude-js";

function ShareClassCodeDialogue(props) {
  const state = useContext(appContext);

  const handleClickBack = () => {
    props.handleCloseShareClassroomDialogue();
    state.handleClickOpenCreateClassroomDialogue();
  };
  return (
    <div>
      <Dialog
        PaperProps={{
          sx: {
            width: "768px",
            height: "562px",
            maxWidth: "768px",
            height: "562px",
            maxHeight: "562px",
          },
        }}
        open={props.openShareClassroomDialogue}
        onClose={props.handleCloseShareClassroomDialogue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="flex">
          <button onClick={() => props.handleCloseShareClassroomDialogue()}>
            <img src={Cross}></img>
          </button>
          <div className="text-center w-[100%] create_new_classroom_dialogue_title">
            Create new class
          </div>
        </DialogTitle>
        <hr className="mt-0 "></hr>

        <div>
          <DialogContent style={{ marginLeft: "30px", marginRight: "50px" }}>
            <DialogContentText id="new-classroom-dialog-description">
              <div>
                <label className="create_your_class_label">
                  Share code with students
                </label>
              </div>

              <div>
                <label className="class_name_hint_label">
                  Have your students visit
                  <b className="text-black">{" app.teepee.ai/login"}</b> and
                  enter the class code
                </label>
              </div>
            </DialogContentText>
            <div className="flex">
              <input
                disabled
                style={{ marginRight: "20px" }}
                type="text"
                name="subject_name"
                id="subject_name"
                className="class_name_input pl-[23px] sm:!w-[65%] md:w-[549px] "
                placeholder={props.classroomData.classroom_code}
                required
              />
              <button
                className="class_name_dialogue_copy_button"
                onClick={() => {
                  amplitude
                    .getInstance()
                    .setUserId(localStorage.getItem("email_address"));
                  amplitude.getInstance().logEvent("CopyClassCode");
                  navigator.clipboard.writeText(
                    props.classroomData.classroom_code
                  );
                  alert("Class code has been copied");
                }}
              >
                Copy
              </button>
            </div>
          </DialogContent>
        </div>
        <hr
          style={{ marginTop: "auto", width: "100%", marginBottom: "0px" }}
        ></hr>

        <DialogActions
          style={{
            marginRight: "15px",
            justifyContent: "space-between",
          }}
        >
          <button
            style={{ width: "60px" }}
            className=" sm:w-[44%] md:w-[46%]"
            onClick={() => handleClickBack()}
          >
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-[#6750A4]">Back</div>
            </div> */}
          </button>
          <button
            style={{ width: "110px" }}
            id="button"
            onClick={props.handleCloseShareClassroomDialogue}
            className="teepee-button md:ml-12 hover:bg-black sm:w-[50%] md:w-[46%]"
          >
            Done
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ShareClassCodeDialogue;
