import React, { useRef, useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import BackArrow from "../images/icon.svg";
import { useNavigate } from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import amplitude from "amplitude-js";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ReactSwitch from "react-switch";
import Button from "@mui/material/Button";
import axios from "axios";

import { MdSettings } from "react-icons/md";

export default function Share() {
  const userType = localStorage.getItem("user_type");
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [requiredSso, setRequiredSso] = useState(false);
  const [subjectSettings, setSubjectSettings] = useState(false);
  var id = params.get("id");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState({});
  const [open2, setOpen2] = useState(false);
  var trialState = useRef(0);
  const isSubmitting = useRef(false);
  const toastMessage = useRef("");
  //const [sso_required, setSsoRequired] = useState(true);
  const goBack = () => {
    navigate(-1);
  };
  const handleChange = (val) => {
    setRequiredSso(val);
    updateSsoRequiredStatus(val);
  };
  const handleChanged = (value) => {
    if (value) {
      trialState = 1;
      console.log("trial state here ", trialState);
      setSubjectSettings(true);
      toastMessage.current = " Quiz setting Changed";
      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.post["authorization"] = token;
      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_trial_state", {
          method: "POST",
          subject_id: data.subject_id,
          mock_exam_id: data.mock_exam_id,
          quiz_type: data.quiz_type,
          quiz_secret_key: id,
          topic_id: data.topic_id,
          trial_state: trialState,
        })

        .then((response) => {
          console.log(response.data.msg);

          if (response.data.msg === "Already exist") {
            setOpen2(true);
            return;
          }
          setShow(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      trialState = 0;
      console.log("trial state here ", trialState);
      setSubjectSettings(false);
      toastMessage.current = " Quiz setting Changed";
      toastMessage.current = " Quiz setting Changed";
      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.post["authorization"] = token;
      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_trial_state", {
          method: "POST",
          subject_id: data.subject_id,
          mock_exam_id: data.mock_exam_id,
          quiz_type: data.quiz_type,
          quiz_secret_key: id,
          topic_id: data.topic_id,
          trial_state: trialState,
        })

        .then((response) => {
          console.log(response);
          setShow(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const gettrialStates = () => {
    console.log("secret key", id);
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_trial_state", {
        method: "POST",
        quiz_secret_key: id,
      })

      .then((response) => {
        var jsonData = JSON.parse(response.data.data);
        console.log("trial data", jsonData[0]);
        setData(jsonData[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    gettrialStates();
    trialState.current = data.trial_state;

    if (trialState.current === 1) {
      setSubjectSettings(true);
      trialState = 1;
    } else {
      setSubjectSettings(false);
      trialState = 0;
    }
  }, [open]);
  useEffect(() => {
    if (id != null && id.length > 0) {
      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.post["authorization"] = token;
      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_quiz_sso_required_status", {
          method: "POST",
          quiz_secret_key: id,
        })

        .then((response) => {
          console.log(response);
          setRequiredSso(response.data["sso_required"]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    console.log("data here", window.location);
  }, [id]);

  const handleCloseWithCancel = () => {
    setOpen(false);
    isSubmitting.current = false;
  };

  const handleCloseWithYes = () => {
    updateSsoRequiredStatus();
  };

  function updateSsoRequiredStatus(status) {
    var token = "Bearer " + localStorage.getItem("access_token");
    //isSubmitting.current = true;

    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_quiz_sso_required_status", {
        method: "POST",
        sso_required: status,
        quiz_secret_key: id,
      })

      .then((response) => {
        console.log(response);
        isSubmitting.current = false;
        //toastMessage.current="Quiz is marked to Limit one response"
        //setShow(true)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleCloseQuizSettingDialogue = () => {
    setOpen2(false);
    setSubjectSettings(false);
  };
  const handleYesQuizSettingDialogue = () => {
    console.log("trial state new", trialState);
    var token = "Bearer " + localStorage.getItem("access_token");
    //isSubmitting.current = true;

    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_trial_state_part_2", {
        method: "POST",
        subject_id: data.subject_id,
        mock_exam_id: data.mock_exam_id,
        quiz_type: data.quiz_type,
        quiz_secret_key: id,
        topic_id: data.topic_id,
        trial_state: 1,
      })

      .then((response) => {
        console.log(response);
        setShow(true);
        setOpen2(false);
      })
      .catch((error) => {
        console.log(error);
        setOpen2(false);
      });
  };
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        className="overflow-y-hidden"
        open={open2}
        onClose={handleCloseQuizSettingDialogue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Update Setting</b>
        </DialogTitle>
        <DialogContent className="overflow-y-hidden">Are you certain that you wish to display this quiz in the experience flow?</DialogContent>

        <DialogActions>
          <Button onClick={handleYesQuizSettingDialogue}>Yes</Button>
          <Button onClick={handleCloseQuizSettingDialogue}>No</Button>
        </DialogActions>
      </Dialog>
      <ToastContainer className="p-3 position-fixed top-12 right-0">
        <Toast style={{ backgroundColor: "#f7f2f9" }} onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Body>{toastMessage.current}</Toast.Body>
        </Toast>
      </ToastContainer>

      <Dialog open={open} onClose={handleCloseWithCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <h5>Task Settings (For Admin Only)</h5>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="mt-[24px]">
              <div className="bg-[#FEF7FF] p-[15px] pl-[20px]" style={{ border: "1px solid #79747E", borderRadius: "12px" }}>
                <div className="flex  mr-[20px]">
                  <div className="w-[50%] p-[15px] pl-[20px]">
                    <div className="disabled-label" style={{ fontSize: "16px" }}>
                      Try our AI Marking
                    </div>
                    <div className="classroom_details_label !overflow-visible">Save this quiz for Teepee experience</div>
                  </div>
                  <div className="w-[50%] pt-[10px] ">
                    <div className="classroom_name_div ">
                      <div className="classroom_details_label flex float-right">
                        <ReactSwitch className="float-right" checked={subjectSettings} onChange={(value) => handleChanged(value)} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>Close</Button>
        </DialogActions>
      </Dialog>

      <Sidebar item_id="quiz" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px]">
        <div className="home sm:ml[25px] md:ml-[60px]">
          <div>
            <label className="disabled-label">Create Task</label>
          </div>
          <div className="pl-10">
            <label className="disabled-label">Add Details</label>
          </div>
          <div className="pl-10">
            <label className="dashboard-label">Share</label>
            <div className="teepee-divider"></div>
          </div>
        </div>
        <div className="form-div">
          <div className="w-[600px]">
            <div>
              <div style={{ display: "flex" }}>
                <label className="create_quiz_label">Share this link with students</label>

                {userType === "2" && data.quiz_type !== 1 && (
                  <MdSettings className="mt-[4px] ml-[20px]" style={{ width: "20px", height: "20px" }} onClick={() => setOpen(true)} />
                )}
              </div>

              <input
                type="text"
                name="text"
                id="loginInput"
                className="mt-[20px] rounded-[12px] border-[1px] border-[black] w-full h-[64px] text-[#474747] leading-[30px] text-[20px] font-[500] px-4"
                value={window.location.origin + "/quiz?id=" + id}
                disabled
              />
              <div>
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "12px",
                    marginRight: "10px",
                    fontFamily: "Roboto",
                    float: "left",
                    color: "#49454F",
                  }}
                >
                  Students will be required to sign in
                </p>

                <ReactSwitch checked={requiredSso} onChange={handleChange} />
              </div>
              <div className="create-quiz-button mt-3">
                <div>
                  <button
                    onClick={goBack}
                    // onClick={handleSubmit(submitForm)}
                    // disabled={isSubmitting}
                    className="teepee-back-button sm:w-[43%] md-w-[46%]"
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img alt="" src={BackArrow} className="pr-4"></img>
                      <div>Go Back</div>
                    </div>
                  </button>

                  <button
                    onClick={() => {
                      toastMessage.current = "Copied!";

                      setShow(true);
                      navigator.clipboard.writeText(window.location.origin + "/quiz?id=" + id);
                      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
                      amplitude.getInstance().logEvent("CopyLink", "user has clicked on copy link button.");
                    }}
                    className="teepee-button  sm:w-[43%] md-w-[46%]"
                  >
                    Copy Link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
