import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar";
import EdiText from "react-editext";
import axios from "axios";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import "./css/toggle.css";
function RSetting() {
  const [subjectData, setSubjectData] = React.useState(null);
  const [subject_setting, setSubject_setting] = React.useState(null);
  const [quizTime, setQuizTime] = useState(0);
  const [maturityTime, setMaturityTime] = useState(0);
  const [show, setShow] = React.useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [totalPercentage, setTotalPercentage] = React.useState(0);
  useEffect(() => {
    console.log("Subject set", subject_setting);
    getSubjectDetails(id);
    GetSubjectSetting(id);
  }, [id]);

  function getSubjectDetails(subject_id) {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/get_subjects_data_complete",
        {
          method: "POST",
          subject_id: subject_id,
          predefined_question_answer_status: 2,
        }
      )

      .then((response) => {
        console.log("DATA IN THE JSON: ", response.data["subject"]);
        var subjects_list = response.data["subject"];

        console.log("EXAM STYLE: ", subjects_list);

        setSubjectData(subjects_list);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function GetSubjectSetting() {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/get_subjects_settings",
        {
          method: "POST",
          subject_id: id,
          //   r1: 10,
          //   r2: 2,
          //   r3: 3,
          //   r2_days: 4,
          //   r3_days: 5,
          // total_quiz_questions: 10,
        }
      )

      .then((response) => {
        var subjects_list = response.data["subject"];

        console.log("EXAM STYLE: ", subjects_list);
        setQuizTime(subjects_list[0].toggle_day_hour);
        setMaturityTime(subjects_list[0].toggle_button);
        setSubject_setting(subjects_list);
        setTotalPercentage(
          parseInt(subjects_list[0].r1_percentage) +
            parseInt(subjects_list[0].r2_percentage) +
            parseInt(subjects_list[0].r3_percentage)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const saveSettings = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;

    axios
      .post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/update_subjects_settings`,
        {
          method: "POST",
          subject_id: id,
          r1: subject_setting[0].r1_percentage,
          r2: subject_setting[0].r2_percentage,
          r3: subject_setting[0].r3_percentage,
          r2_days: subject_setting[0].r2_maturity_days,
          r3_days: subject_setting[0].r3_maturity_days,
          total_quiz_questions: subject_setting[0].total_quiz_questions,
          toggle_for_days: maturityTime,
          time: subject_setting[0].quiz_time,
          toggle_button_day: quizTime,
        }
      )
      .then((response) => {
        // handle success
        setShow(true);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  };
  const handleInputChange = (e, key, index) => {
    setSubject_setting((prevSettings) => {
      const newSubjectSettings = [...prevSettings];
      newSubjectSettings[index] = {
        ...newSubjectSettings[index],
        [key]: e.target.value,
      };

      const newTotalPercentage = newSubjectSettings.reduce(
        (sum, subject) =>
          sum +
          Number(subject.r1_percentage || 0) +
          Number(subject.r2_percentage || 0) +
          Number(subject.r3_percentage || 0),
        0
      );

      setTotalPercentage(newTotalPercentage);
      return newSubjectSettings;
    });
  };

  const checkIfAnyFieldIsEmpty = (currentSettings) => {
    if (!Array.isArray(currentSettings)) return;
    const isEmpty = currentSettings.some((subject) => {
      return (
        !subject.total_quiz_questions ||
        !subject.r1_percentage ||
        !subject.r2_percentage ||
        !subject.r3_percentage ||
        !subject.r2_maturity_days ||
        !subject.r3_maturity_days
      );
    });
    setIsButtonDisabled(isEmpty || totalPercentage !== 100);
  };
  const handleTimeChange = (e) => {
    // Get the new selected value from the <select> element
    const newValue = parseInt(e.target.value); // Parse the value to an integer

    // Update the state with the new selected value
    setQuizTime(newValue);
  };
  const handleMaturityChange = (e) => {
    // Get the new selected value from the <select> element
    const newValue = parseInt(e.target.value); // Parse the value to an integer

    // Update the state with the new selected value
    setMaturityTime(newValue);
  };
  useEffect(() => {
    checkIfAnyFieldIsEmpty(subject_setting);
  }, [subject_setting, totalPercentage, isButtonDisabled]);

  return (
    <>
      <ToastContainer className="p-5 position-fixed top-12  right-10 ">
        <Toast
          style={{ backgroundColor: "#f7f2f9" }}
          onClose={() => setShow(false)}
          show={show}
          delay={5000}
          autohide
        >
          <Toast.Header>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <mask
                id="mask0_187_302"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_187_302)">
                <path
                  d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                  fill="#0AB30A"
                />
              </g>
            </svg>
            <strong className="me-auto" style={{ fontFamily: "Roboto" }}>
              Updated!
            </strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>
            <p style={{ fontFamily: "Roboto" }}>
              {" "}
              Awesome! Settings have been updated 🎉
            </p>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Sidebar item_id="view_subject" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px] ">
        <div className="home sm:ml[25px] md:ml-[60px]">
          <div>
            <Link to="/view_subject" className="dashboard-label text-centered">
              Back
            </Link>
            <div className="teepee-divider"></div>
          </div>
          <div className="w-[100%]">
            <h3 className="text-end pr-[40%] pt-[5%]">
              Subject:{" "}
              {subjectData?.map((subject, subjectIndex) => (
                <span>{subject.subject_name}</span>
              ))}
            </h3>
          </div>
        </div>
        <div className=" flex-row justify-left items-center sm:pl-[10px] sm:pr-[10px] md:pl-[60px] md:pr-[40px] mt-[20px]">
          {subject_setting?.map((subject, index) => (
            <div>
              <p
                style={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  color: "#49454F",
                  paddingBottom: "5px",
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    fontFamily: "Roboto",
                    color: "#49454F",
                    display: "flex",
                    paddingLeft: "12px",
                  }}
                >
                  Total Questions (per quiz)*:
                  <input
                    type="text"
                    value={subject.total_quiz_questions}
                    style={{
                      border: "2px solid #403151",
                      width: "100px",
                      borderRadius: "3px",
                      height: "40px",
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "10px",
                      marginBottom: "10px",
                    }}
                    required
                    onChange={(e) =>
                      handleInputChange(e, "total_quiz_questions", index)
                    }
                  />
                </p>
                <div className="teepee-divider"></div>
              </p>
              <p
                style={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  color: "#49454F",
                  marginBottom: "10px",
                }}
              >
                <h5
                  style={{
                    fontFamily: "Roboto",
                    color: "#49454F",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  Selection Criteria
                </h5>
                <p
                  style={{
                    fontSize: "18px",
                    fontFamily: "Roboto",
                    color: "#49454F",
                    display: "flex",
                    paddingLeft: "12px",
                  }}
                >
                  R1 Ratio(%age)*:
                  <input
                    type="text"
                    value={subject.r1_percentage}
                    style={{
                      border: "2px solid #403151",
                      width: "72px",
                      borderRadius: "3px",
                      height: "40px",
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "10px",
                      marginBottom: "10px",
                    }}
                    onChange={(e) =>
                      handleInputChange(e, "r1_percentage", index)
                    }
                  />
                </p>
                <p
                  style={{
                    fontSize: "18px",
                    fontFamily: "Roboto",
                    color: "#49454F",
                    display: "flex",
                    paddingLeft: "12px",
                  }}
                >
                  R2 Ratio(%age)*:
                  <input
                    type="text"
                    value={subject.r2_percentage}
                    style={{
                      border: "2px solid #403151",
                      width: "72px",
                      borderRadius: "3px",
                      height: "40px",
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "10px",
                      marginBottom: "10px",
                    }}
                    onChange={(e) =>
                      handleInputChange(e, "r2_percentage", index)
                    }
                  />
                </p>
                <p
                  style={{
                    fontSize: "18px",
                    fontFamily: "Roboto",
                    color: "#49454F",
                    display: "flex",
                    paddingLeft: "12px",
                  }}
                >
                  R3 Ratio(%age)*:
                  <input
                    type="text"
                    value={subject.r3_percentage}
                    style={{
                      border: "2px solid #403151",
                      width: "72px",
                      borderRadius: "3px",
                      height: "40px",
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "10px",
                      marginBottom: "10px",
                    }}
                    onChange={(e) =>
                      handleInputChange(e, "r3_percentage", index)
                    }
                  />
                </p>
                {totalPercentage !== 100 && (
                  <p style={{ color: "red" }}>
                    The sum of R1, R2, and R3 percentages should be equal to
                    100.
                  </p>
                )}
                <div className="teepee-divider"></div>
              </p>

              <div>
                <p
                  style={{
                    fontSize: "14px",
                    fontFamily: "Roboto",
                    color: "#49454F",
                    paddingTop: "10px",
                  }}
                >
                  <h5
                    style={{
                      fontFamily: "Roboto",
                      color: "#49454F",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Period of Questions (Maturity time)
                  </h5>
                  <p
                    style={{
                      fontSize: "18px",
                      fontFamily: "Roboto",
                      color: "#49454F",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    R2 Ratio (days)*:
                    <input
                      type="text"
                      value={subject.r2_maturity_days}
                      style={{
                        border: "2px solid #403151",
                        width: "72px",
                        borderRadius: "3px",
                        height: "40px",
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                      onChange={(e) =>
                        handleInputChange(e, "r2_maturity_days", index)
                      }
                    />
                    <select
                      value={maturityTime}
                      style={{
                        border: "2px solid #403151",
                        width: "100px",
                        borderRadius: "3px",
                        height: "40px",
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                      onChange={handleMaturityChange}
                    >
                      <option value={0}>Minutes</option>
                      <option value={1}>Days</option>
                    </select>
                  </p>

                  <p
                    style={{
                      fontSize: "18px",
                      fontFamily: "Roboto",
                      color: "#49454F",
                      display: "flex",
                      marginTop: "16px",
                    }}
                  >
                    R3 Ratio(days)*:
                    <input
                      type="text"
                      value={subject.r3_maturity_days}
                      style={{
                        border: "2px solid #403151",
                        width: "72px",
                        borderRadius: "3px",
                        height: "40px",
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "14px",
                        marginBottom: "10px",
                      }}
                      onChange={(e) =>
                        handleInputChange(e, "r3_maturity_days", index)
                      }
                    />
                  </p>

                  <h5
                    className="mt-[16px]"
                    style={{
                      fontFamily: "Roboto",
                      color: "#49454F",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    <div className="teepee-divider "></div>
                    <br></br>
                    Quiz Time Settings
                  </h5>
                  <p
                    style={{
                      fontSize: "18px",
                      fontFamily: "Roboto",
                      color: "#49454F",
                      display: "flex",
                      paddingLeft: "12px",
                    }}
                  >
                    Quiz Time*:
                    <input
                      type="text"
                      value={subject.quiz_time}
                      style={{
                        border: "2px solid #403151",
                        width: "72px",
                        borderRadius: "3px",
                        height: "40px",
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "10px",
                        marginBottom: "10px",
                      }}
                      onChange={(e) => handleInputChange(e, "quiz_time", index)}
                    />
                    <select
                      value={quizTime}
                      style={{
                        border: "2px solid #403151",
                        width: "100px",
                        borderRadius: "3px",
                        height: "40px",
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                      onChange={handleTimeChange}
                    >
                      <option value={0}>Minutes</option>
                      <option value={1}>Hours</option>
                    </select>
                  </p>
                </p>
              </div>
              <div className="float-right mr-[0px] mb-[10px]">
                <button
                  className={
                    isButtonDisabled
                      ? "btn disable-button"
                      : " btn bg-[#403151]"
                  }
                  style={{
                    backgroundColor: "#403151",
                    color: "white",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontFamily: "Roboto",
                  }}
                  onClick={saveSettings}
                  disabled={isButtonDisabled}
                >
                  Save
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
export default RSetting;
