import axios from "axios";
import React from "react";

function QuizScoreTable({
  selectedStudent,
  areaNames,
  tableSelection,
  marks,
  tableData,
  averagePercentage,
  averageScore,
  handleIndividualQuiz,
  questionMarks,
  minimockQuestionSequence,
  quizType,
  totalAreaMarks,
}) {
  console.log("SELECTED STUDENT: ", selectedStudent);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  var id = params.get("id");
  const handleColor = (percentages, parentIndex) => {
    for (let index = 0; index < percentages.length; index++) {
      if (parentIndex === index) {
        if (percentages[index] >= 80) {
          return "#55CE98";
        } else if (percentages[index] >= 70) {
          return "#BBEABB";
        } else if (percentages[index] >= 50) {
          return "#FEF1CE";
        } else {
          return "#FAAD98";
        }
      }
    }
  };
  const handleAvgColor = (parentIndex) => {
    for (let index = 0; index < averagePercentage.length; index++) {
      if (parentIndex === index) {
        if (averagePercentage[index] >= 80) {
          return "#55CE98";
        } else if (averagePercentage[index] >= 70) {
          return "#BBEABB";
        } else if (averagePercentage[index] >= 50) {
          return "#FEF1CE";
        } else {
          return "#FAAD98";
        }
      }
    }
  };

  return (
    <div id="table" className="mt-[100px] overflow-x-auto">
      <table
        style={{
          width: "100%",
          border: "1px solid black",
          borderCollapse: "collapse",
        }}
      >
        <thead class="text-xs text-black dark:bg-white dark:text-white ">
          <tr className="tracking_table">
            <th scope="col" class="px-6 py-3  tracking_table">
              Student Name
            </th>
            {tableSelection === 1 && quizType !== 2
              ? marks.length > 0 &&
                marks.map((value, index) => (
                  <th scope="col" class="px-4 py-3 tracking_table">
                    {`Q${index + 1} (${questionMarks[index]})`}
                  </th>
                ))
              : areaNames.length > 0 &&
                areaNames.map((value, index) => (
                  <th scope="col" class="px-4 py-3 tracking_table">
                    {`${value} (${totalAreaMarks[index]})`}
                  </th>
                ))}
            {tableSelection === 1 && quizType === 2
              ? minimockQuestionSequence.map((value, index) => (
                  <th scope="col" class="px-4 py-3 tracking_table">
                    {`Q${value} (${questionMarks[index]})`}
                  </th>
                ))
              : ""}
          </tr>
        </thead>
        <tbody>
          {tableData !== undefined &&
            tableData.map((student, index) => (
              <tr className="tracking_table">
                <td scope="col" class="px-4 py-3  tracking_table">
                  {quizType === 2 || selectedStudent !== undefined ? (
                    <div>{student.student_names}</div>
                  ) : (
                    <button onClick={() => handleIndividualQuiz(student)}>
                      {student.student_names}
                    </button>
                  )}
                </td>
                {student.marks.map((marks, index) => (
                  <td
                    scope="col"
                    class="px-4 py-3  tracking_table"
                    style={{
                      backgroundColor: handleColor(
                        student.student_question_percentage,
                        index
                      ),
                    }}
                  >
                    {marks}
                  </td>
                ))}
              </tr>
            ))}
          <tr
            className="tracking_table"
            style={{
              display: localStorage.getItem("student") !== null && "none",
            }}
          >
            <td scope="col" class="px-4 py-3  tracking_table">
              Class Avg. Score
            </td>
            {averageScore.map((score, index) => (
              <td
                scope="col"
                class="px-4 py-3  tracking_table"
                style={{ backgroundColor: handleAvgColor(index) }}
              >
                {score.toFixed(2)}
              </td>
            ))}
          </tr>
          <tr
            className="tracking_table"
            style={{
              display: localStorage.getItem("student") !== null && "none",
            }}
          >
            <td scope="col" class="px-4 py-3  tracking_table">
              Class Avg. Score %age
            </td>
            {averagePercentage.map((percentage, index) => (
              <td
                scope="col"
                class="px-4 py-3  tracking_table"
                style={{ backgroundColor: handleAvgColor(index) }}
              >
                {`${percentage.toFixed(0)}%`}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default QuizScoreTable;
