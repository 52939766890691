import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Edit from "../../../images/edit-student.png";
import DeleteStudent from "../../../images/delete-student.png";
import axios from "axios";
import moment from "moment";

function SubscriptionDetailsTable({ subscriptionDetails, getSubscriptionDetailsForAdmin }) {
  const handleChangeOrderStatus = (orderData, e) => {
    const currentDate = moment();
    const end_date = moment(orderData.subscription_end_date, "ddd, DD MMM YYYY HH:mm:ss [GMT]");
    const currentUTC = currentDate.utc();
    const subscriptionEndUTC = end_date.utc();
    const startDate2 = new Date(orderData.order_start_date);
    const endDate2 = new Date(orderData.order_end_date);

    // Calculate the year and month difference
    const yearsDifference = endDate2.getFullYear() - startDate2.getFullYear();
    const monthsDifference = endDate2.getMonth() - startDate2.getMonth();

    // Total months difference
    const totalMonths = yearsDifference * 12 + monthsDifference;

    console.log("Current UTC:", currentUTC.format());
    console.log("Subscription End UTC:", subscriptionEndUTC.format());

    if (subscriptionEndUTC.isBefore(currentUTC)) {
      console.log("Subscription has ended.");
      return;
    } else {
      console.log("Subscription is still active.");
    }

    const currentDate2 = moment();
    const futureDate = currentDate2.add(
      process.env.REACT_APP_SUBSCRIPTION_TIME,
      parseInt(totalMonths) === 12 ? process.env.REACT_APP_SUBSCRIPTION_UNIT : process.env.REACT_APP_SUBSCRIPTION_UNIT_MONTH
    ); // Adding 1 hour
    const expiryDate = futureDate.format("Y-MM-DD HH:mm:ss");
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .request({
        method: "POST",
        data: JSON.stringify({
          voucher_bought: orderData.voucher_bought,
          trial_period_tracking_id: orderData.trial_period_tracking_id,
          purchase_verification_status: parseInt(e.target.value),
          purchase_order_id: orderData.purchase_order_id,
          start_date: moment().format("Y-MM-DD HH:mm:ss"),
          end_date: expiryDate,
        }),

        url: process.env.REACT_APP_REST_API_BASE_URL + "/update_purchase_order_status",

        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log(res.data);
        getSubscriptionDetailsForAdmin();
      })
      .catch((res) => {
        console.log(res);
      });
  };
  const calculateBillPaid = (billPaid, discountPercentage) => {
    // First, add 20% to billPaid
    const billWithExtra = billPaid + billPaid * (20 / 100);

    // Then, apply the discount if there is a discountPercentage
    if (discountPercentage) {
      return billWithExtra - billWithExtra * (discountPercentage / 100);
    }

    // If no discount, return the billWithExtra
    return billWithExtra;
  };

  return (
    <div>
      <table className="w-full text-sm text-left text-white dark:text-white tracking_table w-[100%] o" id="tbl">
        <thead class="text-xs text-black dark:bg-white dark:text-white ">
          <tr className="tracking_table">
            <th scope="col" class="px-6 py-3 tracking_table spth bg-[#F7F2F9]">
              ID
            </th>
            <th scope="col" class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%]">
              Email
            </th>
            <th scope="col" class="px-6 py-3  whitespace-nowrap">
              Subscription Details
            </th>
            <th scope="col" class="px-6 py-3  whitespace-nowrap">
              Order Details
            </th>
            <th scope="col" class="px-6 py-3  whitespace-nowrap">
              Payment Method
            </th>
            <th scope="col" class="px-6 py-3  whitespace-nowrap">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {subscriptionDetails &&
            subscriptionDetails.map((subscription, index) => (
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th
                  scope="row"
                  class="px-6  font-medium text-gray-900 whitespace-nowrap dark:text-white tracking_table_td"
                  style={{ backgroundColor: "rgba(103, 80, 164, 0.08)" }}
                >
                  {index + 1}
                </th>
                <td class="px-6   tracking_table tracking_table_td" style={{ backgroundColor: "rgba(103, 80, 164, 0.08)" }}>
                  {subscription.email_user}
                </td>
                <td class="px-6 tracking_table_td">
                  <label className="mb-[2px]">School Name:</label>
                  {subscription.school} <br></br>
                  <label className="mb-[2px]">Email:</label>
                  {subscription.email ? subscription.email : "N/A"}
                  <br></br>
                  <label className="mb-[2px]">Phone Number:</label>
                  {subscription.phone ? subscription.phone : "N/A"}
                  <br></br>
                  <label className="mb-[2px]">Comment:</label>
                  {subscription.comment ? subscription.comment : "N/A"}
                </td>

                <td class="px-6   tracking_table_td">
                  <div>{`Order number:${subscription.purchase_order_id} Order placed on:${subscription.order_end_date}, ${
                    subscription.purchase_key ? "Purchase order key : " + subscription.purchase_key : ""
                  } `}</div>
                  {/* <div>{`Number of vouchers:${subscription.voucher_bought}, total bill:${calculateBillPaid(
                    subscription.bill_paid,
                    subscription.discount_percentage
                  ).toFixed(2)} ${subscription.promocode_id === 0 ? "" : `, Promocode : ${subscription.promocode}`} `}</div> */}
                </td>
                <td class="px-6   tracking_table_td">{subscription.payment_method}</td>
                <td class="px-6   tracking_table_td">
                  <Dropdown className="w-full h-full mb-[12%]">
                    <Dropdown.Toggle id="profile-dropdown" className="w-full">
                      <div
                        className={`${subscription.purchase_verification_status === 1 ? "bg-[#62C370]" : "bg-[#DC362E]"} w-fit	 rounded text-white`}
                      >
                        <div className="flex justify-between">
                          <div className="ml-2 flex items-center p-2">{subscription.purchase_verification_status === 1 ? "Paid" : "Pending"}</div>
                          <button className="bg-white mr-[2px] mt-[3px] mb-[3px] rounded-r flex items-center p-2">
                            <img style={{ maxWidth: "fit-content" }} src={Edit} />
                          </button>
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="!bg-[#F3EDF8]">
                      <div className="p-3 w-[220px]">
                        <div className="mb-[10%]">
                          <label className="flex">
                            <input
                              type="radio"
                              value={1}
                              checked={subscription.purchase_verification_status === 1}
                              onChange={(e) => handleChangeOrderStatus(subscription, e)}
                            />
                            <div className="ml-[10%] font-medium	">Activate (paid)</div>
                          </label>
                          <br />
                        </div>
                        <div>
                          <label className="flex">
                            <input
                              type="radio"
                              value={0}
                              checked={subscription.purchase_verification_status === 0}
                              onChange={(e) => handleChangeOrderStatus(subscription, e)}
                            />
                            <div className="ml-[10%] font-medium	">Deactivate (pending)</div>
                          </label>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default SubscriptionDetailsTable;
