import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  header: {
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 10,
  },
  detailRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  detailText: {
    fontSize: 12,
    fontFamily: "Helvetica",
  },
  questionBlock: {
    marginBottom: 10,
  },
  questionTitle: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Helvetica",
  },
  answer: {
    marginTop: 8,
    marginBottom: 4,
  },
  subHeading: {
    fontWeight: "bold",
    fontSize: 14,
    fontFamily: "Helvetica",
    marginBottom: 2,
  },
  normalText: {
    fontSize: 10,
    fontFamily: "Helvetica",
    marginBottom: 2,
  },
  tableContainer: {
    border: "1px solid #333",
    marginTop: 8,
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1px solid #333",
    padding: 5,
  },
  tableHeader: {
    fontWeight: "bold",
    backgroundColor: "#f0f0f0",
  },
  tableCell: {
    fontSize: 10,
    fontFamily: "Helvetica",
    flex: 1,
    padding: 5,
  },
});

const QuizReviewDocument = ({ quizData, student_name, marks_achieved, quiz_total_marks, isAnswerRubric, is_code }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.title}>Task Detail Review</Text>
        <View style={styles.detailRow}>
          <Text style={styles.detailText}>Student Name: {student_name}</Text>
          <Text style={styles.detailText}>Marks Achieved: {marks_achieved + "/" + quiz_total_marks}</Text>
        </View>
      </View>

      {quizData.map((quiz, index) => (
        <View key={index} style={styles.questionBlock}>
          <Text style={styles.questionTitle}>
            Question {index + 1}: {JSON.parse(quiz.quiz_question).question.replace(/<\/?[^>]+(>|$)/g, "")} {quiz.score}/{quiz.question_marks}
          </Text>

          <View style={styles.answer}>
            <Text style={styles.subHeading}>Student Answer:</Text>
            <View style={{ backgroundColor: "#EAD1DC", border: "1px solid black", padding: 10 }}>
              <Text style={styles.normalText}>{quiz.student_response}</Text>
            </View>
          </View>

          <View style={styles.answer}>
            <Text style={styles.subHeading}>Teepee:</Text>
            <View style={{ backgroundColor: "#EAD1DC", border: "1px solid black", padding: 10 }}>
              {quiz?.feedback && !quiz.feedback.startsWith("{") && !quiz.feedback.endsWith("}") ? (
                <Text style={styles.normalText}>{quiz.feedback}</Text>
              ) : (
                // Rendering table-like feedback when is_code is not "0"
                <View style={styles.tableContainer}>
                  <View style={[styles.tableRow, styles.tableHeader]}>
                    <Text style={styles.tableCell}>Criterion</Text>
                    <Text style={styles.tableCell}>Description</Text>
                    <Text style={styles.tableCell}>Mark</Text>
                    <Text style={styles.tableCell}>Explanation</Text>
                  </View>

                  {(() => {
                    // Parse feedback if it's in string format
                    const feedback = typeof quiz?.feedback === "string" ? JSON.parse(quiz.feedback) : quiz.feedback;

                    // Check if feedback and markingBreakdown exist and map through the array
                    return feedback?.taskEvaluations?.map((item, i) => (
                      <View key={i} style={styles.tableRow}>
                        <Text style={styles.tableCell}>{item.taskNumber}</Text>
                        <Text style={styles.tableCell}>{item.taskDescription}</Text>
                        <Text style={styles.tableCell}>{item.markAwarded}/1</Text>
                        <Text style={styles.tableCell}>{item.feedback}</Text>
                      </View>
                    ));
                  })()}
                </View>
              )}
            </View>
          </View>

          {isAnswerRubric === 0 && (
            <View style={styles.answer}>
              <Text style={styles.subHeading}>Actual Answer:</Text>
              <Text style={styles.normalText}>{JSON.parse(quiz.quiz_question).answer}</Text>
            </View>
          )}
        </View>
      ))}
    </Page>
  </Document>
);

export default QuizReviewDocument;
