import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Header from "../../images/header-trial-popup.svg";
import Tick from "../../images/green-tick.png";
import SubscriptionDetailsCard from "./SubscriptionDetailsCard";
import SubscribeImage1 from "../../images/subscribe-1.png";
import SubscribeImage2 from "../../images/subscribe-2.png";
import SubscribeImage3 from "../../images/subscribe-3.png";
import SubscribeImage4 from "../../images/subscribe-4.png";
import SubscribeImage5 from "../../images/subscribe-5.png";
import SubscribeImage6 from "../../images/subscribe-6.png";
// import "rsuite/dist/rsuite.min.css";
// import { Slider } from "rsuite";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import EditStudent from "./widgets/EditStudent";
import { useNavigate } from "react-router-dom";
import appContext from "../../context/appContext";

function BuyVoucherPopup(props) {
  console.log("PROPS: ", props.subject);
  const navigate = useNavigate();

  const marks = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 30,
      label: "30",
    },
    {
      value: 60,
      label: "60",
    },
    {
      value: 100,
      label: "100",
    },
    {
      value: 130,
      label: "More",
    },
  ];
  function valuetext(value) {
    return `${value}`;
  }

  function onSliderChange(e, type) {
    if (e.target.value === "") {
      props.setNoOfStudents(0);
      props.setVouchersRate(0);
    }
    if (e.target.value !== 0) {
      props.setNoOfStudents(e.target.value);
    }
    if (e.target.value >= 1 && e.target.value <= 30) {
      props.setOffIndicator(-1);
      if (type === 1) {
        props.setSliderRange(1);
      }
      props.setVouchersRate(e.target.value * 5);
      props.isStep.current = false;
    } else if (e.target.value > 30 && e.target.value <= 60) {
      props.setOffIndicator(e.target.value * 5);
      if (type === 1) {
        props.setSliderRange(2);
      }
      props.setVouchersRate(e.target.value * 4.75);
      props.isStep.current = false;
    } else if (e.target.value > 60 && e.target.value <= 100) {
      if (type === 1) {
        props.setSliderRange(3);
      }
      props.setOffIndicator(e.target.value * 5);
      props.setVouchersRate(e.target.value * 4.5);
      props.isStep.current = false;
    } else if (e.target.value > 100) {
      if (type === 1) {
        props.setSliderRange(4);
      }
      props.isStep.current = true;
      props.setOffIndicator(e.target.value * 5);
      props.setVouchersRate(e.target.value * 4.25);
      props.setNoOfStudents(e.target.value);
    }
  }
  const onChange = (e) => {
    const value = e.target.value;

    // Check if the value is numeric
    const isNumeric = /^\d+$/.test(value) || value === "";

    if (isNumeric) {
      var numericValue = value;

      // Check if the numeric value is less than or equal to 1000
      if (numericValue <= 1000) {
        var obj = { target: { value: numericValue } };
        onSliderChange(obj, 2);
        // props.setNoOfStudents(numericValue); // Assuming this function updates the state for the number of students
        // props.setOffIndicator(numericValue * 5); // Assuming this function updates some state based on the number of students
        // props.setVouchersRate(numericValue * 4.25); // Assuming this function updates some state based on the number of students
      } else {
        alert("Please enter a valid student number (less than or equal to 1000).");
      }
    } else if (value !== "") {
      alert("Please enter only numeric values.");
    }
  };

  const handleOnClickBuy = () => {
    props.handleClose();
    if (props.sliderRangeText === 4 && props.noOfStudent <= 100) {
      alert("Please enter a valid student number (Greater than 100 and less than or equal to 1000).");
      return;
    }
    var data = {
      subjectName: props.subjectName,
      noOfStudent: props.noOfStudent,
      vouchersRate: props.vouchersRate,
      subject: props.subject,
    };

    console.log("SUBJECT_DATA: ", props.subject);

    navigate("/checkout", {
      state: {
        data: data,
      },
    });
  };

  return (
    <div>
      <Dialog
        PaperProps={{
          sx: {
            width: "1024px",
            maxWidth: "1024px",
            borderRadius: "12px",
            height: "1024px",
            overflow: "hidden",
            overflowY: "scroll",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          },
        }}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="flex p-0 relative ">
          <div className="text-center w-full create_new_classroom_dialogue_title relative text-white md:mb-0 sm:mb-2">
            <div
              className=" w-full p-[5%]" // This maintains the aspect ratio (16:9 for example)
              style={{
                backgroundImage: `url(${Header})`,

                backgroundRepeat: "none",
              }}
            >
              <div className=" md:text-3xl sm:text-[16px] font-bold">{props.subjectName}</div>
              <div className=" md:text-md sm:text-[16px] font-bold">Get full access to this subject anytime for just £5/student yearly!</div>
            </div>
          </div>
        </DialogTitle>

        <div>
          <DialogContent
            style={{
              overflow: "hidden",
              overflowY: "scroll",
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
            className="sm:ml-[4px] sm:mr-[4px] md:ml-[32px] md:mr-[32px] !pt-[1%]"
          >
            <div className="flex justify-center  ">
              <div className=" w-auto pt-[13px] pb-[13px] pr-8 pl-8  !text-sm !font-semibold border-[#6750A4] border-[1px] rounded text-[#6750A4] subscription-card-box-shadow">
                Standard Yearly Billing
              </div>
            </div>
            <div className="flex justify-center relative ">
              <div className="mt-[3%] md:w-[40%] sm:w-[100%] p-4 subscription-card-box-shadow rounded-xl	">
                <div className="md:flex justify-between">
                  {props.sliderRangeText !== 4 ? (
                    <div className="font-semibold text-[24px]">{`${props.noOfStudent} Student`}</div>
                  ) : (
                    <EditStudent onChange={onChange} noOfStudent={props.noOfStudent} />
                  )}
                  <div>
                    <div className=" text-[12px] text-[#6750A4]">
                      {`${
                        props.sliderRangeText === 1
                          ? "£5 per / Student"
                          : props.sliderRangeText === 2
                          ? "£4.75 per / Student"
                          : props.sliderRangeText === 3
                          ? "£4.50 per / Student"
                          : "£4.25 per / Student"
                      }`}
                    </div>
                    <div className="font-bold text-[#6750A4] text-[12px]">
                      {`${
                        props.sliderRangeText === 1
                          ? "1 - 30 students"
                          : props.sliderRangeText === 2
                          ? "31-60 students"
                          : props.sliderRangeText === 3
                          ? "61-100 students"
                          : "100+ students"
                      }`}
                    </div>
                  </div>
                </div>
                <div className="mt-[2%] md:flex justify-between ">
                  <div className="flex">
                    <div className="md:text-[44px] sm:text-[36px] font-semibold text-[#6750A4] md:mr-[4px] ">{`£${props.vouchersRate}`}</div>
                    <div
                      className="flex items-center text-[#838186]"
                      style={{
                        display: props.OffIdicator === -1 && "none",
                        textDecoration: "line-through",
                      }}
                    >
                      {"£" + props.OffIdicator}
                    </div>
                  </div>
                  <div className="md:ml-2 flex  items-end">
                    <div className="pt-1 pb-1 pr-3 pl-3 md:text-sm  !font-semibold border-[#6750A4] border-[1px] rounded text-[#6750A4] bg-[#F3F1F8]">
                      Billed Yearly
                    </div>
                  </div>
                </div>
                <div className="mt-[2%] container">
                  <Box>
                    <Slider
                      onChange={(e) => {
                        onSliderChange(e, 1);
                      }}
                      aria-label="Restricted values"
                      defaultValue={1}
                      max={130}
                      //   valueLabelFormat={valueLabelFormat}
                      getAriaValueText={valuetext}
                      step={props.isStep.current ? false : true}
                      valueLabelDisplay="auto"
                      marks={marks}
                    />
                  </Box>
                  {/* <Slider
                    max={100}
                    min={10}
                    step={10}
                    defaultValue={50}
                    graduated
                    progress
                  /> */}
                </div>
                <div className="mt-[5%]">
                  <div className="flex">
                    <div className="mr-[2%]">
                      <img src={Tick} />
                    </div>
                    <div className="flex items-center">
                      <div className="mr-[3px] text-[12px] font-bold text-[#6750A4]">50 </div>
                      <div className="text-[12px] text-[#49454F]">quizzes per subject</div>
                    </div>
                  </div>
                  <SubscriptionDetailsCard text="Analytics" />
                  <SubscriptionDetailsCard text="Classrooms" />
                  <SubscriptionDetailsCard text="personalised Feedback" />
                  <SubscriptionDetailsCard text="Feedback Control" />
                  <SubscriptionDetailsCard text="Quiz Customization" />
                </div>
                <div className="mt-[10%]">
                  <button className="teepee-button w-full h-[40px] p-0 !text-[#FFFFFF]" onClick={() => handleOnClickBuy()}>
                    {" "}
                    Buy
                  </button>
                </div>
              </div>
              <div className="absolute top-0 left-[-50px]  sm:hidden md:block">
                <img src={SubscribeImage1} />
              </div>
              <div className="absolute top-[130px] left-0  sm:hidden md:block">
                <img src={SubscribeImage2} />
              </div>
              <div className="absolute top-[300px] left-[-20px]  sm:hidden md:block">
                <img src={SubscribeImage3} />
              </div>
              <div className="absolute top-0 right-[-50px]  sm:hidden md:block">
                <img src={SubscribeImage4} />
              </div>
              <div className="absolute top-[130px] right-0  sm:hidden md:block">
                <img src={SubscribeImage5} />
              </div>
              <div className="absolute top-[320px] right-[-20px] sm:hidden md:block">
                <img src={SubscribeImage6} />
              </div>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

export default BuyVoucherPopup;
