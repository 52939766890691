import React, { useState } from "react";
import TeepeeIcon from "../images/avatar4.png";
import AboutUsGirlImage from "../images/about_us_image.png";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Footer from "./Footer";
import amplitude from "amplitude-js";
function AboutUs() {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const isSubmitting = React.useRef(false);
  const [open, setOpen] = React.useState(false);
  const [callState, setCallState] = useState(false);

  const sendEarlyAccessRequest = async (e) => {
    var input_field = document.getElementById("early_access_email_input");
    if (input_field.value.length <= 0) {
      alert("Enter valid Email for early access");
      return;
    }

    if (callState) {
      return;
    }

    setCallState(true);
    e.preventDefault();
    isSubmitting.current = true;
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/early_access_request",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email: input_field.value,
        }),
      })
      .then((res) => {
        setOpen(true);
        amplitude.getInstance().setUserId(input_field.value);
        amplitude
          .getInstance()
          .logEvent(
            "GetEarlyAccess",
            "user has submitted early access request"
          );
        input_field.value = "";
        setCallState(false);
        isSubmitting.current = false;
      })
      .catch((err) => {
        setCallState(false);
        isSubmitting.current = false;
      });
  };

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          titleStyle={{ textAlign: "center" }}
          textAlign="center"
        >
          <b>Thank you!</b>
        </DialogTitle>
        <DialogContent titleStyle={{ textAlign: "center" }} textAlign="center">
          <DialogContentText
            id="alert-dialog-description"
            titleStyle={{ textAlign: "center" }}
            textAlign="center"
          >
            Someone will get in touch shortly
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ justifyItems: "center", justifyContent: "center" }}
        >
          <button
            fullWidth
            type="submit"
            style={{ width: "180px", height: "45px" }}
            className="early_access__dialog_close_button  hover:text-white font-bold py-2 px-4 "
            onClick={handleClose}
          >
            Close
          </button>
          {/* <Button onClick={handleClose}>Close</Button> */}
        </DialogActions>
      </Dialog>
      <div className="pl-[130px] sm:pl-[30px] lg:pl-[130px] pr-[30px] sm:pr-[30px] lg:pr-[130px]">
        <div className="teepee_icon_container ml-[0px] sm:ml-[0px] md:ml-auto lg:ml-auto">
          <img src={TeepeeIcon} className="teepee_icon_about_us" alt="" />
        </div>
        <div className="login_button_about_us right-[130px] md:right-[130px] sm:right-[30px] sm:w-auto md:w-[167px] lg:w-[167px] sm:h-[auto] h-[42px]">
          <button
            type="submit"
            className="sign-in-button bg-[#A07BEC]  rounded-md hover:bg-black text-[white] hover:text-white font-bold py-2 px-4 w-full h-full"
            onClick={(e)=>{ e.preventDefault();navigate("/login");}}
          >
            Login
          </button>
        </div>
        <div className="mt-[60px]">
          <p>Based on a true story (with a tiny touch of hyperbole!) ..</p>
          <p>Names have been changed to protect the innocent!</p>
          <p>Too long? <a href="#">computer! Please summarise!</a></p>
          <p>
            Once upon a time in the charming town of Beale Abbot, there lived a
            dedicated teacher named Miss Hawtrey. She was well-known for her
            innovative ways of teaching and for always seeking out the best
            possible tools to help her students learn and grow.
          </p>
          <p>
            One sunny afternoon, Miss Hawtrey was at her favourite coffee shop,
            sipping on a cup of java and drafting a set of assessments for her
            students. As she juggled multiple browser tabs and struggled to
            frame the right questions, she found herself in a conversation with
            a friendly group of tech enthusiasts, who happened to overhear her
            mumbling about ChatGPT.
          </p>
        </div>

        <img src={AboutUsGirlImage} className="about_us_girl_image" alt="" />

        <div>
          <p>
            Intrigued, the group asked her to elaborate on her experience with
            ChatGPT. Miss Hawtrey shared how she utilised the AI model to
            lighten her workload and save time while creating assessments.
            However, she also expressed her frustration with the process of
            crafting long prompts, cutting, and pasting questions and having to
            tell ChatGPT how to evaluate student responses.
          </p>
          <p>
            Seeing an opportunity to make a difference in the world of
            education, the tech group, which consisted of Omar the Optimist,
            Wendy the Webmaster, and Alice the Algorithmist, proposed an idea.
            They would band together and create a web-based tool that would
            automate the entire process, making it seamless and efficient for
            educators like Miss Hawtrey.
          </p>
          <p>
            And so, Teepee.ai was born! Fueled by caffeine and a shared passion
            for improving the lives of teachers and students, the team worked
            tirelessly to develop a user-friendly interface that could harness
            the power of ChatGPT, simplifying the creation of formative and
            summative assessments.
          </p>
          <p>
            But the team didn't stop there. They knew that providing instant
            feedback and tracking student performance was essential to the
            learning process. They integrated a feature that allowed students to
            answer the generated questions directly within Teepee.ai. The
            platform would then evaluate the responses and give immediate
            feedback, ensuring a more interactive and effective learning
            experience.
          </p>
          <p>
            Furthermore, Teepee.ai provided a comprehensive dashboard for
            teachers, allowing them to track each student's performance and
            identify areas for improvement. This valuable insight enabled
            educators like Miss Hawtrey to tailor their teaching methods to the
            individual needs of their students.
          </p>
          <p>
            The day came when Teepee.ai was ready for its first test run. The
            team gathered around Miss Hawtrey's laptop, their eyes sparkling
            with anticipation. As Miss Hawtrey entered her requirements and
            clicked "Generate," the screen lit up with a beautiful array of
            well-crafted questions and answers. No more cutting and pasting, no
            more struggling with prompts. The process was now as smooth as a
            fresh jar of peanut butter.
          </p>
          <p>
            Word of Teepee.ai spread like wildfire through the town of Silicon
            Grove and beyond. Teachers from all corners of the world rejoiced,
            as the burden of creating assessments was lifted from their
            shoulders. Students, too, benefited from the engaging and relevant
            questions Teepee.ai produced, along with the instant feedback and
            personalised learning experience.
          </p>
          <p>
            The story of Teepee.ai and the chance meeting that sparked its
            creation soon became a legend among educators and tech enthusiasts
            alike. It was a testament to the power of collaboration and the
            incredible things that can happen when a group of passionate
            individuals come together to solve a problem.
          </p>
          <p>
            And as for Miss Hawtrey, Tim, Wendy, and Alice, they continued to
            improve Teepee.ai, always seeking new ways to support teachers and
            students on their educational journeys. They became local heroes,
            inspiring others with their dedication and ingenuity, proving that
            sometimes, all it takes is a cup of coffee, a chance encounter, and
            a little bit of AI magic to change the world.
          </p>
          <p>
            Teepee.ai was created and is run by the team at HigherSummit
            (<a href="http://www.highersummit.com">www.highersummit.com</a>), a provider of educational software.
          </p>
          <p>
            HigherSummit are: (in no particular order) <b>Omar, King</b> (yes His
            Highness!), <b>Rida, Kate, Hanna, Nina, Bisma, Muneeb, Ali, Hasnain,
            Nimra, Raffia, Fatima, Usama, Hammad, Prof. Sandhu</b> and <b>Ahmer</b>
          </p>
        </div>
        {/* <div className="mt-[50px] mb-[50px] w-[100%] sm:w-[100%] md:w-[35%] lg:w-[35%]">
          <p>
          To request early access as a teacher, please enter your official school email below👇.
          </p>
        <form>
                <div className="mt-[34px]">
                  <input
                    type="text"
                    name="text"
                    style={{ height: "65px" }}
                    className=" teepee-get-early-access-input  border-[#8B8B90] border-[1px] rounded-md w-full py-7 px-4   leading-tight  focus:shadow-blue-900"
                    id="early_access_email_input"
                    placeholder="mail@example.com"
                  />
                </div>
                <div className="mt-[26px] ">
                <button
                    type="submit"
                    disabled={isSubmitting.current}
                    className="teepee-get-early-access-button-text hover:text-white w-[280px] h-[45px]  py-2 px-4  bg-[#101010] rounded-md"
                    onClick={sendEarlyAccessRequest}
                  >
                    {isSubmitting.current && (
                  <div
                    className="spinner-border spinner-border-m float-left early-access-spinner"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                    Get early access
                  </button>
                </div>
              </form>
              </div> */}
      </div>
    </React.Fragment>
  );
}

export default AboutUs;
