import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import EditPredifinedQuestions from "./EditPredefinedQuestions";
import Typography from "@mui/material/Typography";
import SubAccordions from "./SubAccordian";
import EdiText from "react-editext";
import Button from "@mui/material/Button";
import axios from "axios";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import QuestionContextAccordian from "./QuestionContextAccordian";
import IncDecCounter from "./IncDecCounter";
import SubjectMarksLevelExamStyleAssessmentPrompt from "./SubjectMarksLevelExamStyleAssessmentPrompt";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

// const AccordionSummary = styled((props) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "white",
//   flexDirection: "row-reverse",
//   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//     transform: "rotate(90deg)",
//   },
//   "& .MuiAccordionSummary-content": {
//     marginLeft: theme.spacing(1),
//   },
// }));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const onUpdateMockExamName = (value, mockExam) => {
  // chapter.chapter_name=value;
  var token = "Bearer " + localStorage.getItem("access_token");
  axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  axios.defaults.headers.post["Content-Type"] =
    "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.post["authorization"] = token;
  axios
    .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_mock_exam_name", {
      method: "POST",
      mock_exam_name: value,
      mock_exam_id: mockExam.mock_exam_id,
    })

    .then((response) => {})
    .catch((error) => {
      console.log(error);
      alert("unable to update subject");
    });
};

export default function SubjectMarksLevelPrompts(props) {
 
  const addNewSubjectMarksLevelPrompt = () => {
    // alert(subject.subject_name);

    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL +
          "/create_new_subject_marks_level_prompt",
        {
          method: "POST",
          subject_id: props.subject_id,
          prompt_level:0,

        }
      )

      .then((response) => {
        props.addTodo(props.subject_id);
      })
      .catch((error) => {
        console.log(error);
        alert("unable to add new topic");
      });
  };

  return (
    <div>
      {props.subject_marks_level_prompt_list.length>0 && (props.subject_marks_level_prompt_list?.map(
        (subject_marks_level_prompt, subIopicIndex) => (
          
            <SubjectMarksLevelExamStyleAssessmentPrompt
              subject_id = {props.subject_id}
              addTodo={props.addTodo}
              subject_marks_level_prompt={subject_marks_level_prompt}
            />
         
          
        )
      ))}
      <Button
        className="delete-button"
        style={{ color: "#1976d2", padding: "6px 8px" }}
        onClick={() => addNewSubjectMarksLevelPrompt()}
      >
        Add New Examstyle Marks Level Prompt
      </Button>
    </div>
  );
}
