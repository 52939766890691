import React from "react";
import Tick from "../../../images/icon_tick.png";

function GetStartedHeadingCard(props) {
  return (
    <div>
      <div className="flex">
        <img
          className="md:mr-[3%] sm:mr-[1%] md:mt-0 sm:mt-6"
          style={{
            width: "40px",
            height: "24px",
            objectFit: "cover",
          }}
          src={Tick}
        />
        <div>
          <div className="text-base font-bold text-[#1D192B]">
            {props.heading}
          </div>
          <div className="text-sm font-medium text-[#49454F]">
            {props.subHeading}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetStartedHeadingCard;
