import usePostApiToken from "../../ApiTemplate/PostApiWithToken";
import apiConfig from "../../../Config/apiConfig";

const useGetQuizDataForCopy = () => {
  const postApiCall = usePostApiToken();

  // Make getTopics function async to wait for the API response
  const getQuizDataForCopy = async (body, apiData) => {
    try {
      // Await the result of the API call
      const data = await postApiCall(apiConfig.endpoints.getQuizDataForCopy, body);

      // Perform any operations you need with the fetched data
      apiData(data);

      // Optional: Debugging/logging
    } catch (error) {
      console.error("Error fetching data:", error);
      // Optionally, handle the error by passing it to apiData or other logic
    }
  };

  return getQuizDataForCopy;
};

export default useGetQuizDataForCopy;
