import React, { useEffect, useRef, useState } from "react";
import amplitude from "amplitude-js";
import { useNavigate } from "react-router";
import { Tooltip } from "@mui/material";
import Copy from "../../../images/content_copy.png";
import Edit from "../../../images/edit_content.png";
import Delete from "../../../images/delete_content.png";
import CopyQuizDialogue from "../../widgets/CopyQuizDialogue";
import axios from "axios";
import ConfirmationDialogue from "../confirmationdialogue/ConfirmationDialogue";
import QuizCopyConfirmationDialogue from "./QuizCopyConfirmationDialogue";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

function QuizzesTable(props) {
  const quizzes = props.quizzes;
  const classroomData = props.classroomData;
  const [editedValue, setEditedValue] = useState("");
  const [showHoverButton, setShowHoverButton] = useState(false);
  const [hoverDivIndex, setHoverDivIndex] = useState(-1);
  const [quizNameDialogue, setQuizNameDialogue] = useState(false);
  const [editedQuizId, setEditedQuizId] = useState();
  const [isEditing, setIsEditing] = useState(-1);
  const [deleteQuizList, setDeleteQuizList] = useState([]);
  const [openDeleteConfirmationDialogue, setOpenDeleteConfirmationDialogue] = useState(false);
  const [isActiveQuiz, setIsActiveQuiz] = useState(false);
  const [quizCopyDialogue, setQuizCopyDialogue] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  var class_id = params.get("id");
  const [quizStatus, setQuizStatus] = useState(0);
  const [show, setShow] = useState(false);
  const [toasterValue, setToasterValue] = useState();
  const oldEditedQuizValue = useRef("");
  const editedQuizValue = useRef("");
  const hoverDivIndexNew = useRef();
  const isEditingNew = useRef();
  const wrapperRef = useRef(null);
  const editedQuizIdNew = useRef("");

  const [inputText, setInputText] = useState("");

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (oldEditedQuizValue.current.length === 0) {
            alert("please enter the quiz name");
            return;
          }
          if (
            editedQuizValue.current !== "" &&
            oldEditedQuizValue.current !== editedQuizValue.current &&
            hoverDivIndexNew.current === isEditingNew.current
          ) {
            axios
              .post(process.env.REACT_APP_REST_API_BASE_URL + "/check_quiz_name", {
                method: "POST",
                quiz_name: editedQuizValue.current,
                class_id: class_id,
              })

              .then((response) => {
                console.log(response);
                if (response.data.msg === 1) {
                  alert("Task name already exists. Please choose a different one.");
                  return;
                }

                var token = "Bearer " + localStorage.getItem("access_token");
                axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
                axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
                axios.defaults.headers.post["authorization"] = token;
                axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

                axios
                  .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_quiz_name", {
                    method: "POST",
                    quiz_id: editedQuizIdNew.current,
                    quiz_name: editedQuizValue.current,
                  })

                  .then((response) => {
                    console.log(response);
                    // props.editedQuizName(
                    //   editedQuizId.current,
                    //   editedQuizValue.current
                    // );
                    setShow(true);
                    setToasterValue(2);
                    console.log(response);
                    props.getClassData();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch((error) => {
                console.log(error);
              });
          }
          setIsEditing(-1);
          setShowHoverButton(false);
          setHoverDivIndex(-1);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(wrapperRef);

  const onChangeNumber = (e) => {
    setInputText(e.target.value);
  };

  const navigate = useNavigate();

  const handleCloseDialogue = (isCross) => {
    setQuizNameDialogue(false);
    if (isCross) {
      setInputText("");
    }
  };

  const handleOpenQuizCopyDialogue = () => {
    setQuizCopyDialogue(true);
  };
  const handleCloseQuizCopyDialogue = () => {
    setInputText("");
    setQuizCopyDialogue(false);
  };

  const AccountStatus = {
    TRIAL_ACTIVE: 1,
    TRIAL_EXPIRED: 2,
    SUBSCRIPTION_ACTIVE: 3,
    SUBSCRIPTION_EXPIRED: 4,
    SPECIAL_CONDITION: 5,
  };
  const handleAccontStatus = (status, object) => {
    if (AccountStatus.TRIAL_ACTIVE === status) {
      return 1;
    } else if (AccountStatus.SUBSCRIPTION_ACTIVE === status) {
      return 3;
    } else if (AccountStatus.SPECIAL_CONDITION === status) {
      if (object.subscription_days_left) {
        return 3;
      } else {
        return 1;
      }
    }
  };

  const handleConfirmWithOptions = (quiz_status) => {
    setQuizStatus(quiz_status);

    let quizList = [];
    quizList[0] = editedQuizId;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_quiz_data_for_copy", {
        method: "POST",
        quiz_id_list: quizList,
        quiz_name: inputText,
        quiz_status: quiz_status,
        class_id: class_id,
        subject_id: classroomData.subject.subject_id,
        account_status: props.bannerObject ? handleAccontStatus(props.bannerObject.account_status, props.bannerObject) : null,
      })

      .then((response) => {
        setShow(true);
        setToasterValue(1);
        localStorage.setItem("teacher_table_state", quiz_status);
        console.log(response);
        handleCloseDialogue();
        handleOpenQuizCopyDialogue();
        handleCloseQuizCopyDialogue();
        props.getClassData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpneDialogue = (quiz_id) => {
    setEditedQuizId(quiz_id);
    setQuizNameDialogue(true);
  };
  const handleViewQuizClick = (secret_key) => {
    amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
    amplitude.getInstance().logEvent("ICViewQuiz", "user has clicked on view quiz button");
    navigate(`/add-details?id=${secret_key}`, {
      state: {
        classroomData: classroomData,
        selectedQuizType: props.selectedQuizType,
        url: props.url,
        is_code: props?.is_code,
      },
    });
  };
  const handleViewResultClick = (quiz) => {
    amplitude.getInstance().logEvent("ICViewResult");
    navigate(`/view-result?id=${quiz.secret_key}`, {
      state: {
        secret_key: quiz.secret_key,
        quiz_title: quiz.quiz_title,
        created_timestamp: quiz.created_timestamp,
        quiz_total_marks: quiz.quiz_total_marks,
        quiz_duration: quiz.quiz_duration,
        classroomData: classroomData,
        quiz_grace_time: quiz.quiz_grace_time,
        is_code: classroomData.subject.is_code,
      },
    });
  };

  const handleMouseEnter = (index, value, quiz_id) => {
    if (isEditing === -1) {
      setShowHoverButton(true);
      setHoverDivIndex(index);
      hoverDivIndexNew.current = index;
      editedQuizIdNew.current = quiz_id;
    }
  };

  const handleMouseLeave = (quiz_title, quiz_id, index) => {
    if (isEditing === -1) {
      setIsEditing(-1);
      setShowHoverButton(false);
      setHoverDivIndex(-1);
    }
  };

  const editQuizName = (index, value) => {
    setIsEditing(index);
    setEditedValue(value);
    isEditingNew.current = index;
    oldEditedQuizValue.current = value;
    editedQuizValue.current = "";
  };

  const onChange = (event) => {
    setEditedValue(event.target.value);
    editedQuizValue.current = event.target.value;
  };

  const handleDeleteIndividualQuiz = (quiz_id) => {
    let quizList = [];
    quizList[0] = quiz_id;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/check_student_data_in_quiz", {
        method: "POST",
        quiz_id_list: quizList,
      })
      .then((response) => {
        console.log(response);
        if (response.data.msg === 0) {
          setDeleteQuizList(quizList);
          setOpenDeleteConfirmationDialogue(true);
          setIsActiveQuiz(false);
        } else {
          setIsActiveQuiz(true);
          setDeleteQuizList(quizList);
          setOpenDeleteConfirmationDialogue(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseWithYes = () => {
    console.log("DELETING ARRAY : ", deleteQuizList);
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/remove_quiz", {
        method: "POST",
        quiz_id_list: deleteQuizList,
        quiz_name: null,
        quiz_status: null,
      })
      .then((response) => {
        console.log(response);
        setOpenDeleteConfirmationDialogue(false);
        props.getClassData();
        setShow(true);
        setToasterValue(3);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseWithCancelDelete = () => {
    setOpenDeleteConfirmationDialogue(false);
  };

  const handleClickContinue = () => {
    if (inputText.length === 0) {
      alert("Please enter task name");
      return;
    }
    let quizList = [];
    quizList[0] = editedQuizId;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/check_quiz_name", {
        method: "POST",
        quiz_name: inputText,
        class_id: class_id,
      })

      .then((response) => {
        console.log(response);
        if (response.data.msg === 1) {
          alert("Task name already exists. Please choose a different one.");
          return;
        }
        handleCloseDialogue();
        handleOpenQuizCopyDialogue();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTotalNumberOfQuizzes = () => {
    if (localStorage.getItem("email_address") === process.env.REACT_APP_REQUESTED_USER && classroomData.subject.subject_id === 10) {
      return 20;
    } else {
      return process.env.REACT_APP_TRIAL_QUIZ;
    }
  };
  return (
    <>
      <ToastContainer className="p-3 position-fixed top-12 right-0">
        <Toast style={{ backgroundColor: "#f7f2f9" }} onClose={() => setShow(false)} show={show} delay={5000} autohide>
          <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <strong className="me-auto">{toasterValue === 1 ? "Task Copied" : toasterValue === 2 ? "Task Renamed!" : "Task Deleted!"}</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>
            {toasterValue === 1 ? `Task copied successfully!` : toasterValue === 2 ? "Task name updated successfully!" : "Task deleted successfully!"}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <QuizCopyConfirmationDialogue
        title="Task Copied!"
        text="Now that you've made a copy, how would you like to proceed?"
        open={quizCopyDialogue}
        handleCloseWithCancel={handleCloseQuizCopyDialogue}
        handleCloseWithConfirm={handleConfirmWithOptions}
      />
      <ConfirmationDialogue
        title={isActiveQuiz ? "Delete Active Task" : "Delete Task"}
        text={
          isActiveQuiz
            ? "This task contains student attempts. Are you sure you want to permanently delete?"
            : "Once deleted, this task will no longer be accessible. Are you sure you want to proceed?"
        }
        open={openDeleteConfirmationDialogue}
        handleCloseWithConfirm={handleCloseWithYes}
        handleCloseWithCancel={handleCloseWithCancelDelete}
      />
      <CopyQuizDialogue
        handleOpenQuizCopyDialogue={handleOpenQuizCopyDialogue}
        editedQuizId={editedQuizId}
        inputText={inputText}
        onChangeNumber={onChangeNumber}
        handleCloseDialogue={handleCloseDialogue}
        quizNameDialogue={quizNameDialogue}
        handleClickContinue={handleClickContinue}
      />
      <table class="w-full text-sm text-left text-white dark:text-white">
        <thead class="text-xs text-white  bg-[#6750A4] dark:bg-white dark:text-white">
          <tr>
            <th scope="col" class="px-6 py-3">
              ID
            </th>
            <th scope="col" class="px-6 py-3">
              Task Name
            </th>
            {/* <th scope="col" class="px-6 py-3">
              Quiz Type
            </th> */}
            <th
              scope="col"
              class="px-6 py-3"
              style={{
                borderRight: props.selectedQuizType !== 1 && "1px solid white",
              }}
            >
              Creation Date
            </th>
            {props.selectedQuizType === 1 ? (
              <>
                <th scope="col" class="px-6 py-3">
                  Attempts
                </th>
                <th scope="col" class="px-6 py-3" style={{ borderRight: "1px solid white" }}>
                  Result
                </th>
              </>
            ) : null}
            <th scope="col" class="px-6 py-3"></th>
          </tr>
        </thead>

        <tbody>
          {quizzes.map((quiz, index) => {
            return (
              <tr
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                onMouseEnter={() => handleMouseEnter(index, quiz.quiz_title, quiz.quiz_id)}
                onMouseLeave={() => handleMouseLeave(quiz.quiz_title, quiz.quiz_id, index)}
              >
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {index + 1}
                </th>
                <td class="px-6 py-4 max-w-xs ">
                  {isEditing === index ? (
                    <input
                      autoFocus
                      ref={wrapperRef}
                      maxLength={45}
                      style={{
                        border: "1px solid #6750A4",
                        borderRadius: "5px",
                        width: "90%",
                      }}
                      value={editedValue}
                      onChange={onChange}
                    ></input>
                  ) : (
                    <div className="relative group">
                      <button value={quiz.secret_key} className="table_text" onClick={() => handleViewQuizClick(quiz.secret_key)}>
                        <span className="truncate max-w-xs">{quiz.quiz_title}</span>
                      </button>

                      {/* Tooltip */}
                      <div className="absolute hidden group-hover:block bg-white-800 border-1 text-dark shadow-md text-xs rounded px-2 py-1 top-full left-0 whitespace-nowrap z-10">
                        {quiz.quiz_title}
                      </div>
                    </div>
                  )}
                </td>
                {/* <td class="px-6 py-4">{quiz.quiz_type === 1 ? "Formative" : quiz.quiz_type === 2 ? "Mini-mock" : "Exam-Style"}</td> */}
                <td class="px-6 py-4">{quiz.created_timestamp}</td>
                {props.selectedQuizType === 1 ? (
                  <>
                    <td class="px-6 py-4">{quiz.quiz_attempt_count}</td>
                    <td class="px-6 py-4">
                      <button value={quiz.secret_key} className="table_text" onClick={() => handleViewResultClick(quiz)}>
                        View Result
                      </button>
                    </td>
                  </>
                ) : (
                  ""
                )}
                <td
                  class="px-6 py-4"
                  onMouseEnter={() => handleMouseEnter(index, quiz.quiz_title, quiz.quiz_id)}
                  onMouseLeave={() => handleMouseLeave(quiz.quiz_title, quiz.quiz_id, index)}
                >
                  <div
                    className="flex justify-center"
                    style={{
                      visibility: !(showHoverButton && index === hoverDivIndex) && "hidden",
                    }}
                  >
                    <button
                      className="w-[24px] h-[24px] rounded-xl bg-[#E6E0E9] flex justify-center items-center mr-2"
                      onClick={() => handleDeleteIndividualQuiz(quiz.quiz_id)}
                    >
                      <Tooltip placement="top" title={"Delete"}>
                        <img style={{ width: "12px", height: "12px" }} src={Delete}></img>
                      </Tooltip>
                    </button>
                    <button
                      className="w-[24px] h-[24px] rounded-xl bg-[#E6E0E9] flex justify-center items-center mr-2"
                      onClick={() => editQuizName(index, quiz.quiz_title)}
                    >
                      <Tooltip placement="top" title={"Rename"}>
                        <img style={{ width: "12px", height: "12px" }} src={Edit}></img>
                      </Tooltip>
                    </button>{" "}
                    <button
                      className={`w-[24px] h-[24px] rounded-xl bg-[#E6E0E9] flex justify-center items-center mr-2 ${
                        props.bannerObject &&
                        ((!props.bannerObject.subscription_days_left && props.bannerObject.quiz_count >= handleTotalNumberOfQuizzes()) ||
                          props.bannerObject.quiz_count_subscribed >= process.env.REACT_APP_SUBSCRIPTION_QUIZ ||
                          props.bannerObject.account_status === 2 ||
                          props.bannerObject.account_status === 4 ||
                          (props.bannerObject.account_status == 5 &&
                            props.bannerObject.days_left <= 0 &&
                            props.bannerObject.subscription_days_left < 0)) &&
                        "hidden"
                      }`}
                      onClick={() => handleOpneDialogue(quiz.quiz_id)}
                    >
                      <Tooltip placement="top" title={"Make a copy"}>
                        <img style={{ width: "12px", height: "12px" }} src={Copy}></img>
                      </Tooltip>
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {quizzes.length === 0 && (
        <div className="text-center text-sm my-[5%]">
          {props.selectedQuizType == 1 ? "No tasks have been assigned yet." : "No tasks have been drafted yet."}
        </div>
      )}
    </>
  );
}

export default QuizzesTable;
