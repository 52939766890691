import { useContext } from "react";
import TeepeeIcon from "../images/avatar4.png";
import Background from "../images/Rectangle.png";
import appContext from "../context/appContext";
import Footer from "./Footer";

function LoginPageLeftSide(props) {
  const state = useContext(appContext);

  return (
    <div
      className="centered w-full h-full bg-white md:h-screen p-10 md:p-36 sm:p-5 md:text-left items-center mt-0 md:mt-10 sm:pt-20"
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className="jess_icon left-[35px] md:left-[35px] sm:left-[30px]">
        <img src={TeepeeIcon} alt="" />
      </div>

      <div className="main-content-div w-[500px] sm:w-[360px] md:w-[520px]">
        <div className="teepee-heading  " style={{ textAlign: "center" }}>
          <p>{state.loginState === 1 ? "Hey , its Teepee AI" : "Why Teepee.ai?"}</p>
        </div>
        <div className="teepee-sub-heading mt-[34px]" style={{ textAlign: state.loginState === 1 && "center" }}>
          <p>
            {state.loginState === 1 ? (
              "Join your class to start learning"
            ) : (
              <div class="">
                <p className="mb-4">Teepee.ai empowers students to excel in exams with:</p>
                <ul className="list-disc">
                  <li className="mb-4 text-[16px]">
                    <strong>Automated & Personalised Spaced Retrieval Practice:</strong> Helps students retain and recall information when it matters
                    most.
                  </li>
                  <li className="text-[16px]">
                    <strong>Exam-Style Question Practice with AI Feedback:</strong> Provides instant, detailed feedback to help students understand
                    mistakes and improve performance.
                  </li>
                </ul>
              </div>
            )}

            <br />
          </p>
        </div>
        <div
          className="teepee-disclaimer"
          style={{
            fontSize: "12px",
            display: state.loginState === 1 ? "none" : "block",
          }}
        >
          <p>
            ⚠️ Please note: Teepee.ai is in early Beta. We’re refining our AI’s task generation and marking, so occasional inaccuracies may occur.
          </p>
        </div>

        {/* <div className="teepee-disclaimer" style={{ fontSize: "14px" }}>
          <p>
            ⚠️ Please note: Teepee.ai is in early Beta. We're refining our AI's
            quiz generation and marking, and occasional inaccuracies may occur.
          </p>
        </div> */}
        <div className="jess_footer left-[35px] md:left-[35px] sm:left-[30px] md:block sm:hidden !w-[50%]">
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}

export default LoginPageLeftSide;
