import React, { useEffect, useState } from "react";
import TeepeeIcon from "../../images/avatar4.png";
const StudentEmail = (props) => {
  useEffect(() => {
    isValidEmail();
  }, []);
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  // In your button component

  return (
    <>
      {" "}
      <div className="jess_icon left-[35px] md:left-[10%] sm:left-[25%]">
        <img src={TeepeeIcon} alt="" />
      </div>
      <div className="w-[100%] md:mt-[10%] sm:mt-[25%]">
        <div className="h-[100%] flex items-center justify-center flex-col ">
          <div className="welcome-teepee-sub-text w-[40%] sm:w-[90%] md:w-[40%] sm:text-center md:text-left mb-[16px]">
            Enter Your Email
            <p
              style={{
                fontSize: "20px",
                fontWeight: "400",
                marginTop: "10px",
                width: "100%",
              }}
            >
              We will use this email to send you the AI evalution of your
              responses.
            </p>
            <div
              className="input-container"
              style={{ width: "100%", margin: "0 auto" }}
            >
              <input
                maxLength={45}
                name="student_name"
                id="student_name"
                type="email"
                onChange={(e) => {
                  props.setEmail(e.target.value);
                }}
                placeholder="Type your email here!"
                className="w-[100%] mt-[10px] p-2.5 bg-[#E7E0EC] shadow-sm outline-none rounded-[5px] border-[1px] border-[black] h-[50px] text-[#49454F] leading-[30px] text-[20px] font-[500] px-4"
                required
              ></input>
            </div>
            <div
              className="button-container"
              style={{
                width: "100%",
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                style={{
                  backgroundColor: !isValidEmail(props.email) ? "#1C1B1F" : "",
                  opacity: !isValidEmail(props.email) ? "0.3" : "1",
                  color: !isValidEmail(props.email) ? "grey" : "",
                  width: "100%", // Match the input width
                }}
                disabled={!isValidEmail(props.email)}
                onClick={props.quizTakerOutsideClassroom}
                className="teepee-button h-[50px] rounded-[5px] text-[20px] font-[500]"
              >
                Display My Quiz Evaluation
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StudentEmail;
