import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MoreVertIcon from "../../../images/info12.svg";

// Styled Tooltip
const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} placement="top" />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    border: "1px solid #dadde9",
    borderRadius: "8px",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(12),
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    maxWidth: 250,
    opacity: "0.8",
    padding: "12px 16px",
  },
}));

// Individual Metric Component
const Metric = ({ label, description, value, icon }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="bg-[#F1F2F7] rounded-lg p-3 shadow-sm w-full flex flex-col justify-between">
      <div className="flex">
        {/* Title Section */}
        <div className="flex items-start w-full" style={{ fontFamily: "Roboto" }}>
          <div className="mr-[2%]">{icon}</div>
          <div>
            <div className="text-[16px] text-[#6A6A6AFF] font-semibold">{label}</div>
            <div className="text-gray-600 text-[14px] mb-2">{description}</div>
          </div>
        </div>
        <div>
          <ClickAwayListener onClickAway={handleClose}>
            <HtmlTooltip
              title={description}
              PopperProps={{
                disablePortal: true,
              }}
              enterTouchDelay={0}
              leaveTouchDelay={10}
              open={open}
              onOpen={handleOpen}
              onClose={handleClose}
            >
              <img className="float-right " src={MoreVertIcon} />
            </HtmlTooltip>
          </ClickAwayListener>
        </div>
      </div>

      {/* Description */}

      {/* Percentage */}
      <div className="text-[#4C4A4AFF] text-[22px] font-bold text-center">{Math.round(value)}%</div>
    </div>
  );
};

// Main Component that contains all Retrieval Metrics
const RetrievalMetrics = ({ data }) => (
  <div className="flex sm:flex-col md:flex-row gap-4 justify-start">
    <Metric
      label="Retrieval 1 Accuracy"
      description="Overall percentage accuracy for first attempt"
      value={data?.class_analytics_data?.R1_accuracy || 0}
      icon={"🚀"}
    />
    <Metric
      label="Retrieval 2 Accuracy"
      description="Overall percentage accuracy for 2nd attempt"
      value={data?.class_analytics_data?.R2_accuracy || 0}
      icon={"🔄"}
    />
    <Metric
      label="Retrieval 3 Accuracy"
      description="Overall percentage accuracy for 3rd attempt"
      value={data?.class_analytics_data?.R3_accuracy || 0}
      icon={"🏁"}
    />
  </div>
);

export default RetrievalMetrics;
